/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Comment, CommentId } from '@app/api/resources/Comment';
import { List, ListId } from '@app/api/resources/List';

import * as actionTypes from '@app/actionTypes';

const initialState = {
  notebookPost: {},
  list: {},
  ratings: {},
};

export type CommentsState = {
  notebookPost: {
    [key: CommentId]: Comment;
  };
  list: {
    [key: ListId]: List;
  };
};

const commentsReducer = (
  state: CommentsState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_COMMENTS: {
        const { commentableType, commentableId, comments } = action.payload;
        draft[commentableType][commentableId] = comments;
        break;
      }
      case actionTypes.ADD_COMMENT: {
        const { commentableType, commentableId, comment } = action.payload;
        draft[commentableType][commentableId].push(comment);
        break;
      }
      case actionTypes.REMOVE_COMMENT: {
        const { commentableType, commentableId, commentId } = action.payload;
        const allComments = draft[commentableType][commentableId];
        draft[commentableType][commentableId] = allComments.filter(
          (comment: Comment) => comment.id !== commentId,
        );
        break;
      }
    }
  });

export default commentsReducer;
