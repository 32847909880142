import { DependencyList, useEffect, useRef } from 'react';

const useDidUpdateEffect = (fn: () => void, inputs?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
};

export default useDidUpdateEffect;
