import { Dispatch } from 'redux';
import * as Sentry from '@sentry/nextjs';

import HttpService from '@app/api/http-service';
import { Film } from '@app/api/resources/Film';
import { ResponseWithError } from '@app/api/resources/Responses';

import { ObjectOfStrings } from '@app/types/utility-types';

import { setExperiment } from '@app/actions/ExperimentsActions';
import { setCreditCardPaymentGateway } from '@app/actions/PaymentGatewayActions';

export type Experiment = {
  id: string;
  variant: string;
};

export const participate = (
  httpContext: ObjectOfStrings,
  experimentId: string,
): Promise<
  ResponseWithError<
    {
      id: string;
      variant: string;
    },
    | 404 // If the user isn't participating in the experiment
    | 422 // If we are unable to work out the participant identifier (e.g. if its a logged out user and you don't send the anonymous user id request header)
  >
> => HttpService(httpContext).put(`/experiments/${experimentId}/participate`);

const participateInExperiment = async (
  httpContext: ObjectOfStrings,
  experimentIdToParticipateIn: string,
  cachedExperiments: Experiment[],
  dispatch: Dispatch<any>,
) => {
  try {
    const experimentAlreadyInitialised = isExperimentAlreadyInitialised(
      experimentIdToParticipateIn,
      cachedExperiments,
    );

    if (!experimentAlreadyInitialised) {
      const { data: experiment } = await participate(
        httpContext,
        experimentIdToParticipateIn,
      );
      dispatch(setExperiment(experiment));

      return experiment;
    } else {
      const cachedExperiment = getExperimentById(
        experimentIdToParticipateIn,
        cachedExperiments,
      );
      return cachedExperiment;
    }
  } catch (error) {
    if (error?.status === 404) {
      // User is not participating in the experiment, don't report to sentry
      return null;
    }

    if ([422, 429].includes(error?.status)) {
      // Don't report to sentry
      return null;
    }

    Sentry.captureException(error, {
      tags: { error_type: 'experiment-participate-failure' },
    });
    // Fail without alerting user if not possible to participate in experiment
    return null;
  }
};

const getExperimentById = (experimentId: string, experiments: Experiment[]) =>
  experiments.find(exp => exp.id === experimentId);

const isUserInExperimentVariant = (experiment: Experiment, variant: string) =>
  experiment.variant === variant;

export const isExperimentAlreadyInitialised = (
  experimentId: string,
  cachedExperiments?: Experiment[],
) => {
  const cachedExperiment = getExperimentById(experimentId, cachedExperiments);

  if (cachedExperiment) {
    return cachedExperiment.variant !== null;
  }
  return false;
};

///////////////// Experiment specific functions below this line

export const initialiseDlocalExperiment = async (
  httpContext: ObjectOfStrings,
  dispatch: Dispatch<any>,
  geoLocation: string,
  experiments: Experiment[],
) => {
  try {
    const dlocalExperimentForUser = await participateInExperiment(
      httpContext,
      'dlocal_gateway',
      experiments,
      dispatch,
    );

    if (
      dlocalExperimentForUser &&
      isUserInExperimentVariant(dlocalExperimentForUser, 'variant_dlocal')
    ) {
      let askForDocumentNumber = false;
      if (['AR', 'BR', 'CL'].includes(geoLocation)) {
        askForDocumentNumber = true;
      }

      dispatch(
        setCreditCardPaymentGateway({
          paymentGatewayName: 'dlocal',
          showCreditCardFields: {
            billingZipCode: false,
            cardholderName: true,
            documentInput: askForDocumentNumber,
          },
        }),
      );
    }
  } catch (error) {
    // Leave payment_gateway as was set by app_config
  }
};

export const initialiseFilmStillsExperiment = async (
  httpContext: ObjectOfStrings,
  dispatch: Dispatch<any>,
  experiments: Experiment[],
  country: string,
) => {
  try {
    if (['BR', 'GB', 'DE', 'TR', 'NL'].includes(country)) {
      await participateInExperiment(
        httpContext,
        'film_stills',
        experiments,
        dispatch,
      );
    }
  } catch (error) {
    // Experiment failed to initialise
  }
};

export const getFilmStillUrlForExperiment = (
  experiments: Experiment[],
  film: Film,
) => {
  if (film?.experiment_stills) {
    const experiment = getExperimentById('film_stills', experiments);

    if (experiment && experiment.variant) {
      return film?.experiment_stills[experiment.variant] || film?.still_url;
    }
  }

  return film?.still_url;
};
