import Cookies from 'js-cookie';
import { destroyCookie } from 'nookies';
import * as Sentry from '@sentry/nextjs';

import { Method } from '@app/api/utility-types';

import { doServerRedirect } from '@app/services/routeHelpers';
import { isClient } from '@app/services/utils';

import { CustomContext } from '@app/types/common';

const INVALID_LOGIN_TOKEN_CODE = 8;

type RequestError = {
  code: number;
  message: string;
  user_message: string;
  fatal: boolean;
};

export const handleHttpError = async (
  url: string,
  method: Method,
  status: number,
  serverCtx: CustomContext,
  errorData?: RequestError,
  debug: boolean = false,
) => {
  if (debug) {
    console.log(
      // Esoteric method for styling the output red
      // see: https://stackoverflow.com/questions/9781218/how-to-change-node-jss-console-font-color
      '\x1b[31m%s\x1b[0m',
      `XHR Response code for ${url}: `,
      status,
      errorData,
    );
  }

  reportRequestErrorToSentry(url, method, status, errorData);

  if (errorData?.code === INVALID_LOGIN_TOKEN_CODE) {
    handleInvalidLoginToken(serverCtx);
  }
};

const reportRequestErrorToSentry = (
  url: string,
  method: Method,
  status: number,
  errorData?: RequestError,
) => {
  if (shouldSendErrorToSentry(url, method, errorData, status)) {
    if (errorData && errorData?.code) {
      Sentry.captureMessage(
        `Request error on: ${url}, with status: ${status}, and code: ${errorData?.code}.`,
        {
          tags: {
            ...errorData,
            errorWithData: true,
          },
        },
      );
    } else {
      Sentry.captureMessage(
        `Request error on: ${url}, with status: ${status}.`,
        {
          tags: {
            errorWithData: false,
          },
        },
      );
    }
  }
};

export const shouldSendErrorToSentry = (
  url: string,
  method: Method,
  errorData: RequestError,
  status: number,
) => {
  if (status === 429) {
    // No need to report rate limit errors.
    // These are logged in WAF.
    return false;
  }
  if (status === 404) {
    // No need to report not found errors.
    // These are tracked in kibana.
    return false;
  }
  if (errorData?.code === INVALID_LOGIN_TOKEN_CODE) {
    // No need to report when a user has an invalid login token.
    // These are tracked in kibana.
    return false;
  }
  if (errorData?.message) {
    for (const [path, errorMessagesForPath] of Object.entries(
      errorMessagesForEndpointsNotToReportToSentry,
    )) {
      if (new RegExp(path).test(`${method.toUpperCase()} ${url}`)) {
        if (
          typeof errorData?.message === 'string' &&
          errorMessagesForPath.includes(errorData?.message)
        ) {
          return false;
        }
      }
    }
  }

  return true;
};

const handleInvalidLoginToken = (serverCtx: CustomContext) => {
  const isUserLoggedIn = isClient()
    ? !!Cookies.get('lt')
    : !!serverCtx?.req?.cookies?.lt;

  if (isUserLoggedIn) {
    if (isClient()) {
      Cookies.remove('lt');
      Cookies.remove('l');
      Cookies.remove('_mubi_session');

      window.location.reload();
    } else if (serverCtx) {
      destroyCookie(serverCtx, 'lt', { path: '/' });
      destroyCookie(serverCtx, 'l', { path: '/' });
      destroyCookie(serverCtx, '_mubi_session', { path: '/' });

      const { res, locale, asPath } = serverCtx;
      doServerRedirect(res, `/${locale}/${asPath}`);
    }
  }
};

const errorMessagesForEndpointsNotToReportToSentry = {
  // GET /v4/films/${filmId}/viewing
  'GET .*\\/v4\\/films\\/.*\\/viewing': [
    'Film not authorized',
    'Missing Viewing for Film',
    'User is not a subscriber',
    'User subscription paused',
  ],
  // POST /v4/films/${filmId}/viewing?parental_lock_enabled=true
  'POST .*\\/v4\\/films\\/.*\\/viewing(?:\\?parental_lock_enabled=\\w+)?': [
    'User subscription paused',
  ],
  // GET /v4/mubi_go/programmings
  'GET .*\\/v4\\/mubi_go\\/programmings': ['Unsupported country'],
  // POST /v4/authentication/login
  'POST .*\\/v4\\/authentication\\/login': [
    'Authentication error',
    'Authentication incorrect',
  ],
  // POST /v4/exchange_auto_login_token
  'POST .*\\/v4\\/exchange_auto_login_token': ['Authentication error'],
  // PUT /v4/ratings/${filmId}
  'PUT .*\\/v4\\/ratings\\/\\d+': ['Unactivated user'],
  // GET /v4/tv_activations/verify_code?link_code=${link_code}
  'GET .*\\/v4\\/tv_activations\\/verify_code(?:\\?link_code=\\d+)': [
    'Model error',
  ],
  // PUT /v4/account/password
  'PUT .*\\/v4\\/account\\/password$': ['Reauthentication required'],
  // POST /v4/student_redemptions
  'POST .*\\/v4\\/student_redemptions$': ['Model error'],
  // DELETE /v4/current_user
  'DELETE .*\\/v4\\/current_user$': ['Model error'],
  // POST /v4/password_reset
  'POST .*\\/v4\\/password_reset$': ['Password reset unrecognized identifier'],
  // POST /v4/users/${userId}/avatars
  'POST .*\\/v4\\/users/\\d+\\/avatars': ['Unactivated user'],
  // PUT /v4/films/${filmId}/gift_film/redeem?gift_film_token=${token}
  'PUT .*\\/v4\\/films/\\d+/gift_film\\/redeem(?:\\?gift_film_token=\\S+)': [
    'Promo redemption error',
  ],
  // GET /v4/magazine/issue_entitlements/upcoming
  'GET .*\\/v4\\/magazine\\/issue_entitlements\\/upcoming$': [
    'Invalid login token',
  ],
};
