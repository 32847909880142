import Cookie from 'js-cookie';

import { ONE_YEAR_IN_DAYS } from '@app/services/date-utils';

const cookieLocalStorageName = 'mubi-cookie-local-storage';

export const isLocalStorageEnabled = () => {
  try {
    const key = '__storage__test';
    window.localStorage.setItem(key, null);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const isCookieLocalStorageSet = () =>
  Cookie.get(cookieLocalStorageName) !== undefined;

export const setLocalStorageCookie = allowOrReject => {
  Cookie.set(cookieLocalStorageName, allowOrReject, {
    expires: ONE_YEAR_IN_DAYS,
  });
};
