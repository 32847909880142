import { shallowEqual } from 'react-redux';

import { LocalePath } from '@app/api/services/language';

import { RootState } from '@app/reducers';

import useAppSelector from '@app/hooks/utils/useAppSelector';

export type I18nUrlData = {
  geoLocation: string;
  currentLanguage: LocalePath;
};

export const getI18nUrlDataFromState = (state: RootState): I18nUrlData => ({
  currentLanguage: state?.user?.currentLanguage,
  geoLocation: state?.user?.geoLocation,
});

const useI18nUrlData = (): I18nUrlData => {
  const { currentLanguage, geoLocation } = useAppSelector(
    state => ({
      currentLanguage: state?.user?.currentLanguage,
      geoLocation: state?.user?.geoLocation,
    }),
    shallowEqual,
  );

  return {
    currentLanguage,
    geoLocation,
  };
};

export default useI18nUrlData;
