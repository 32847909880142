import { AnyAction } from 'redux';
import produce from 'immer';
import { OnboardingErrorResponse } from '@app/onboarding/lib/normaliseErrorJson';

import { SplashAssets } from '@app/api/resources/AppConfig';
import { Promo, PromoPromoted } from '@app/api/resources/Promo';
import { CurrentPlan } from '@app/api/resources/User';
import { ObjectOfStrings } from '@app/api/utility-types';

import { VanityPathPageType } from '@app/api/services/vanity-paths';
import {
  OnboardingFlow,
  Purchasable,
} from '@app/onboarding/services/onboarding-types';

import * as actionTypes from '@app/actionTypes';

export type OnboardingConfig = {
  promo: any;
  offer_ends_on: string;
  offer_ends_on_text: string;
  country: string;
  closeToUSCity: boolean;
  content_warning_preference_enabled: boolean;
};

export type OnboardingContext = {
  flow: OnboardingFlow;
  contextData?: ContextData;
  container?: any;
  httpContext?: ObjectOfStrings;
  uniqueUserPromoCode?: string;
  specialPromo?: Partial<Promo>;
};

export type ContextData = {
  activationCode?: string;
  currentPlan?: string;
  client?: string;
  container?: any;
  email?: string;
  cancelPath?: string;
  forceNewPaymentMethod?: boolean;
  cancelPaymentCopy?: string;
  fee?: string;
  filmTitle?: string;
  filmId?: number;
  forcePlan?: string;
  onboardingContext?: string;
  promoCode?: string;
  purchasable?: Purchasable;
  redirectUrl?: string;
  renewalDate?: string;
  signUpRequestParams?: {
    via_free_film: boolean;
  };
  signUpTextKey?: string;
  submitCopy?: string;
  submitParams?: any;
  submitPath?: string;
  successPath?: string;
  titleKey?: string;
  hideEligibleForTrialMessage?: boolean;
  activeRecurringSubscriber?: boolean;
  initialEmail?: string;
  giftToken?: string;
  giftFilmToken?: string;
  name?: string;
  senderName?: string;
  force_plan?: CurrentPlan;
  ui?: {
    isEmbedded: boolean;
  };
  referralData?: ReferralData;
  student_splash?: {
    partnerId: number | string;
    trialDays: number;
  };
  verifyData?: {
    onVerifySuccess: () => void;
    onVerifyError: (error: OnboardingErrorResponse) => void;
  };
};

export type ReferralData = {
  referral_token?: string;
  referral_source?: string;
  referral_platform?: string;
  user_invite_id?: string;
  initialEmail?: string;
};

type RatingModalState = {
  show: boolean;
  filmId: number;
  snowplowElement: string;
};

type ManageFilmListModalState = {
  show: boolean;
  filmId: number;
  snowplowElement: string;
};

type SplashPageState = {
  videos: SplashAssets['video'];
};

export type AppState = {
  showFindUsersModal: boolean;
  showOnboardingModal: boolean;
  showRateFilmModal: boolean;
  hideTopNav: boolean;
  manageFilmListModal: ManageFilmListModalState;
  ratingModal: RatingModalState;
  onboardingContext: OnboardingContext;
  onboardingConfig: OnboardingConfig;
  detectedBrowser: string;
  detectedBrowserVersion: string;
  detectedOperatingSystem: string;
  isMobile: boolean;
  httpContext: ObjectOfStrings;
  userHasInteractedWithPage: boolean;
  snowplowInitialised: boolean;
  promoBannerCookies: any;
  promoBannerPromo: any;
  promoPromoted: PromoPromoted;
  viewingTrackingIntervalSeconds: number;
  trialsEnabled: boolean;
  filterBrowseFilmsByNowShowing: boolean;
  contentWarningPreferenceEnabled: boolean;
  pageState: {
    splash: SplashPageState;
    vanityPath: {
      pageType: VanityPathPageType;
    };
  };
  // debug flag. Not explicitly labelling as debug as redux is publically visible.
  showFlags: boolean;
  googleTagsInit: boolean;
  mubiGoEnabledCountry: boolean;
};

export const initialState: AppState = {
  showFindUsersModal: false,
  showOnboardingModal: false,
  showRateFilmModal: false,
  hideTopNav: false,
  manageFilmListModal: {
    show: false,
    filmId: null,
    snowplowElement: null,
  },
  ratingModal: {
    show: false,
    filmId: null,
    snowplowElement: null,
  },
  onboardingContext: {} as OnboardingContext,
  onboardingConfig: {} as OnboardingConfig,
  detectedBrowser: null,
  detectedBrowserVersion: null,
  detectedOperatingSystem: null,
  isMobile: null,
  httpContext: {},
  userHasInteractedWithPage: false,
  snowplowInitialised: false,
  promoBannerCookies: {},
  promoBannerPromo: null,
  promoPromoted: null,
  viewingTrackingIntervalSeconds: 30,
  trialsEnabled: true,
  contentWarningPreferenceEnabled: false,
  filterBrowseFilmsByNowShowing: false,
  pageState: {
    splash: {
      videos: null,
    },
    vanityPath: {
      pageType: null,
    },
  },
  showFlags: false,
  googleTagsInit: false,
  mubiGoEnabledCountry: true,
};

export const appState = (state = initialState, action: AnyAction) =>
  produce(state, (draft: AppState) => {
    switch (action.type) {
      case actionTypes.SET_SHOW_FIND_USERS_MODAL:
        draft.showFindUsersModal = action.payload.showFindUsersModal;
        break;
      case actionTypes.SET_SHOW_ONBOARDING_MODAL:
        draft.showOnboardingModal = action.payload.showOnboardingModal;
        break;
      case actionTypes.SET_ONBOARDING_CONTEXT:
        draft.onboardingContext = action.payload.onboardingContext;
        break;
      case actionTypes.SET_ONBOARDING_CONFIG:
        draft.onboardingConfig = action.payload.onboardingConfig;
        break;
      case actionTypes.SET_SHOW_MANAGE_FILM_LIST_MODAL:
        draft.manageFilmListModal.show = action.payload.showManageFilmListModal;
        draft.manageFilmListModal.filmId = action.payload.filmId;
        draft.manageFilmListModal.snowplowElement =
          action.payload.snowplowElement;
        break;
      case actionTypes.SET_SHOW_RATING_MODAL:
        draft.ratingModal.show = action.payload.showRatingModal;
        draft.ratingModal.filmId = action.payload.filmId;
        draft.ratingModal.snowplowElement = action.payload.snowplowElement;
        break;
      case actionTypes.SET_DETECTED_BROWSER:
        draft.detectedBrowser = action.payload.detectedBrowser;
        draft.detectedBrowserVersion = action.payload.detectedBrowserVersion;
        draft.detectedOperatingSystem = action.payload.detectedOperatingSystem;
        break;
      case actionTypes.SET_IS_MOBILE:
        draft.isMobile = action.payload.isMobile;
        break;
      case actionTypes.SET_HTTP_CONTEXT:
        draft.httpContext = action.payload.httpContext;
        break;
      case actionTypes.ADD_PARAM_TO_HTTP_CONTEXT:
        draft.httpContext = {
          ...draft.httpContext,
          ...action.payload.httpContextParam,
        };
        break;
      case actionTypes.SET_HIDE_TOP_NAV:
        draft.hideTopNav = true;
        break;
      case actionTypes.SET_USER_HAS_INTERACTED_WITH_PAGE:
        draft.userHasInteractedWithPage = true;
        break;
      case actionTypes.SET_SNOWPLOW_INITIALISED:
        draft.snowplowInitialised = true;
        break;
      case actionTypes.SET_PROMO_BANNER_COOKIES:
        draft.promoBannerCookies = action.payload.promoBannerCookies;
        break;
      case actionTypes.SET_PROMO_BANNER_PROMO:
        draft.promoBannerPromo = action.payload.promoBannerPromo;
        break;
      case actionTypes.SET_PROMO_PROMOTED:
        draft.promoPromoted = action.payload.promoPromoted;
        break;
      case actionTypes.SET_VIEWING_TRACKING_INTERVAL:
        draft.viewingTrackingIntervalSeconds =
          action.payload.viewingTrackingIntervalSeconds || 30;
        break;
      case actionTypes.SET_SPLASH_VIDEO:
        draft.pageState.splash.videos = action.payload.videos;
        break;
      case actionTypes.SET_VANITY_PATH_PAGE_TYPE:
        draft.pageState.vanityPath.pageType = action.payload.vanityPathPageType;
        break;
      case actionTypes.SET_FILTER_BROWSE_FILMS_BY_NOW_SHOWING:
        draft.filterBrowseFilmsByNowShowing =
          action.payload.shouldFilterBrowseByNowShowing;
        break;
      case actionTypes.SET_CONTENT_WARNING_PREFERENCE_ENABLED:
        draft.contentWarningPreferenceEnabled =
          action.payload.contentWarningPreferenceEnabled;
        break;
      case actionTypes.DISABLE_TRIALS:
        draft.trialsEnabled = false;
        break;
      case actionTypes.SHOW_FLAGS:
        draft.showFlags = action.payload.showFlags;
        break;
      case actionTypes.SET_GOOGLE_TAGS_INIT:
        draft.googleTagsInit = true;
        break;
      case actionTypes.SET_MUBI_GO_ENABLED:
        draft.mubiGoEnabledCountry = action.payload.mubiGoEnabledCountry;
        break;
    }
  });
