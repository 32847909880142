import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setUserHasInteractedWithPage } from '@app/actions/AppStateActions';

const scriptsToAdd = [
  {
    id: 'utmz',
    src: `${process.env.assetPrefix}/static/scripts/utmz.js`,
  },
  {
    id: 'helpscout',
    src: `${process.env.assetPrefix}/static/scripts/helpscout.js`,
  },
];

const addScriptTag = script => {
  if (document.getElementById(script.id)) {
    return;
  }

  const firstScript = document.getElementsByTagName('script')[0];
  const scriptTag = document.createElement('script');
  scriptTag.id = script.id;
  scriptTag.src = script.src;
  firstScript.parentNode.insertBefore(scriptTag, firstScript);
};

const loadScriptTags = () => {
  scriptsToAdd.forEach(scriptToAdd => addScriptTag(scriptToAdd));
};

const OnUserFirstInteractionContainer = () => {
  const dispatch = useDispatch();

  const [startLoadingScriptTags, setStartLoadingScriptTags] = useState(false);

  useEffect(() => {
    addUserInteractionEventListeners();
  }, []);

  useEffect(() => {
    if (startLoadingScriptTags) {
      loadScriptTags();
    }
  }, [startLoadingScriptTags]);

  const addUserInteractionEventListeners = () => {
    document.body.addEventListener('mousemove', userHasInteractedWithPage);
    document.body.addEventListener('scroll', userHasInteractedWithPage);
    document.body.addEventListener('click', userHasInteractedWithPage);
    document.body.addEventListener('touchstart', userHasInteractedWithPage);
    document.body.addEventListener('keydown', userHasInteractedWithPage);
  };

  const removeUserInteractionEventListeners = () => {
    document.body.removeEventListener('mousemove', userHasInteractedWithPage);
    document.body.removeEventListener('scroll', userHasInteractedWithPage);
    document.body.removeEventListener('click', userHasInteractedWithPage);
    document.body.removeEventListener('touchstart', userHasInteractedWithPage);
    document.body.removeEventListener('keydown', userHasInteractedWithPage);
  };

  const userHasInteractedWithPage = () => {
    removeUserInteractionEventListeners();
    setStartLoadingScriptTags(true);
    dispatch(setUserHasInteractedWithPage());
  };

  return null;
};

export default OnUserFirstInteractionContainer;
