import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import {
  getSnowplowPageType,
  getSnowplowRelatedIdForPageType,
  PageTypes,
  trackSnowplowPageView,
} from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';
import useDebounce from '@app/hooks/utils/useDebounce';
import useDidUpdateEffect from '@app/hooks/utils/useDidUpdateEffect';

const useSnowplowPageViewTracking = () => {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState<string>(null);
  const [referrerPage, setReferrerPage] = useState<string>(null);
  const debouncedCurrentUrl = useDebounce(currentUrl, 1000);

  const {
    httpContext,
    snowplowInitialised,
    countryCode,
    vanityPathPageType,
    ...relatedData
  } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      snowplowInitialised: state.appState.snowplowInitialised,
      countryCode: state.user.geoLocation,
      films: state.film.films,
      notebookPosts: state.notebookPost.notebookPosts,
      filmGroups: state.filmGroup.filmGroups,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const trackRouteChange = async () => {
    const pageType = await getSnowplowPageType(router, vanityPathPageType);
    const pageUrl = router.asPath.includes('android')
      ? router.asPath.split('?')[0]
      : router.asPath;

    if (pageType) {
      let routeData: {
        page_url: string;
        page_title: string;
        referrer_page: string;
        country_code: string;
        page_type: PageTypes;
        sub_page?: string;
      } = {
        ...pageType,
        page_url: pageUrl,
        page_title: document.title,
        referrer_page: referrerPage,
        country_code: countryCode,
      };

      const relatedId = getSnowplowRelatedIdForPageType(
        pageUrl,
        router.query,
        routeData.page_type,
        relatedData,
      );

      if (relatedId) {
        routeData = { ...routeData, ...relatedId };
      }

      trackSnowplowPageView(routeData, httpContext);
    }
  };

  useDidUpdateEffect(() => {
    trackRouteChange();
  }, [debouncedCurrentUrl]);

  const handleRouteChange = newUrl => {
    setCurrentUrl(prevCurrentUrl => {
      // If the URL is the same as the previous one, don't update the state
      if (prevCurrentUrl === newUrl) {
        return prevCurrentUrl;
      }

      // If the URL without query params is different, update the referrer. This is to handle:
      // - when a user navigates from a page to watchlist:
      //   - the referrer should be the page the user was on before navigating to watchlist
      // but in this case the URL might contains params like '/en/watchlist?page=1&filterByNowShowing=false'
      // so if /en/watchlist is different from /en/watchlist?page=1&filterByNowShowing=false without query params, we update the referrer, otherwise we keep the previous one
      if (prevCurrentUrl.split('?')[0] !== newUrl.split('?')[0]) {
        setReferrerPage(prevCurrentUrl.split('?')[0]);
      }

      return newUrl;
    });
  };

  useEffect(() => {
    if (snowplowInitialised) {
      setCurrentUrl(router.asPath);
      setReferrerPage(document.referrer);
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [snowplowInitialised]);
};

export default useSnowplowPageViewTracking;
