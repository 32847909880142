import { MouseEvent } from 'react';

const helpScoutKeys = {
  tr: process.env.helpScoutKeyTR,
  en: process.env.helpScoutKeyEN,
  de: process.env.helpScoutKeyDE,
  pt: process.env.helpScoutKeyPT,
  es: process.env.helpScoutKeyES,
  fr: process.env.helpScoutKeyFR,
  it: process.env.helpScoutKeyIT,
  nl: process.env.helpScoutKeyNL,
};

export const showHelpScoutPopUp = (
  e: MouseEvent<HTMLElement>,
  currentLanguage: string,
) => {
  e.preventDefault();
  const helpScoutKey = helpScoutKeys[currentLanguage]
    ? helpScoutKeys[currentLanguage]
    : process.env.helpScoutKeyEN;

  window.Beacon('init', helpScoutKey);
  window.Beacon('open');
  window.Beacon('on', 'close', () => window.Beacon('destroy'));
};

type FaqPage =
  | 'home'
  | 'widevine-revocation'
  | 'mubi-go'
  | 'notebook-magazine'
  | 'parental-control'
  | 'notebook-stockists';

type FaqPages = {
  [faqPage in FaqPage]: {
    byLanguage?: {
      [lang: string]: string;
    };
    byCountry?: {
      [country: string]: string;
    };
  };
};

const faqPages: FaqPages = {
  home: {
    byLanguage: {
      en: 'https://help.mubi.com',
      tr: 'https://tr.help.mubi.com',
      de: 'https://de.help.mubi.com',
    },
  },
  'widevine-revocation': {
    byLanguage: {
      en: 'https://help.mubi.com/article/1135-updating-browsers-and-widevine-cdm',
      tr: 'https://tr.help.mubi.com/article/1136-tarayici-ve-widevine-cdm-guncellemesi',
      it: 'https://it.help.mubi.com/article/1139-aggiornamento-dei-browser-e-widevine-cdm',
      fr: 'https://fr.help.mubi.com/article/1138-mise-a-jour-des-navigateurs-et-de-widevine-cdm',
      pt: 'https://br.help.mubi.com/article/1140-atualizacao-de-navegadores-e-do-widevine-cdm',
      nl: 'https://nl.help.mubi.com/article/1141-browsers-en-widevine-cdm-bijwerken',
      es: 'https://es.help.mubi.com/article/1142-actualizacion-de-navegadores-y-widevine-cdm',
    },
  },
  'mubi-go': {
    byCountry: {
      en: 'https://help.mubi.com/category/154-mubi-go',
      de: 'https://de.help.mubi.com/category/1030-mubi-go',
    },
  },
  'notebook-magazine': {
    byLanguage: {
      en: 'https://help.mubi.com/category/277-notebook-magazine',
      tr: 'https://tr.help.mubi.com/category/305-notebook',
    },
  },
  'notebook-stockists': {
    byLanguage: {
      en: 'https://help.mubi.com/article/289-where-can-i-find-the-list-of-stockists-participating-stores-for-notebook',
      tr: 'https://tr.help.mubi.com/article/317-notebook-u-temin-edebilecegim-magazalarin-listesini-nerede-bulabilirim',
    },
  },
  'parental-control': {
    byLanguage: {
      en: 'https://help.mubi.com/article/266-how-can-i-check-if-a-film-may-contain-sensitive-content',
    },
  },
};

export const getFaqUrl = (
  faqPage: FaqPage,
  {
    lang,
    country,
  }: {
    lang?: string;
    country?: string;
  },
): string => {
  if (country) {
    if (faqPages?.[faqPage]?.byCountry?.[country]) {
      return faqPages[faqPage].byCountry[country];
    }
    if (faqPages?.[faqPage]?.byCountry?.en) {
      return faqPages[faqPage].byCountry.en;
    }
  }

  if (lang) {
    if (faqPages?.[faqPage]?.byLanguage?.[lang]) {
      return faqPages[faqPage].byLanguage[lang];
    }
    if (faqPages?.[faqPage]?.byLanguage?.en) {
      return faqPages[faqPage].byLanguage.en;
    }
  }
  return null;
};
