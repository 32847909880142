import { useEffect, useState } from 'react';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useCookiePrefs = (): {
  hasConsentedToMarketingCookie: boolean;
  hasConsentedToAnalyticsCookie: boolean;
  hasCookiePrefHookBeenInitialised: boolean;
} => {
  const [
    hasCookiePrefHookBeenInitialised,
    setHasCookiePrefHookBeenInitialised,
  ] = useState(false);

  const localCookieConsent = useAppSelector(
    state => state.user?.localIllowCookieConsent,
  );

  useEffect(() => {
    setHasCookiePrefHookBeenInitialised(true);
  }, [localCookieConsent]);

  return {
    hasConsentedToMarketingCookie: localCookieConsent?.marketing,
    hasConsentedToAnalyticsCookie: localCookieConsent?.analytics,
    hasCookiePrefHookBeenInitialised,
  };
};

export default useCookiePrefs;
