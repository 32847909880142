/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { FilmHighlight } from '@app/api/resources/FilmHighlight';

import * as actionTypes from '@app/actionTypes';

// eslint-disable-next-line import/no-unused-modules
export const initialState = {
  filmHighlights: null,
};

export type FilmHighlightState = {
  filmHighlights: FilmHighlight[];
};

const filmHighlight = (
  state: FilmHighlightState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_FILM_HIGHLIGHTS: {
        const { filmHighlights } = action.payload;
        draft.filmHighlights = filmHighlights;
        break;
      }
    }
  });

export default filmHighlight;
