import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { CookieConsent, persistUpdateUser } from '@app/api/resources/User';

import { IllowCookieConsent } from '@app/api/services/illow';

import {
  setLocalIllowCookieConsent,
  updateLoggedInUser,
} from '@app/actions/UserActions';

import useIsIllowInitialised from '@app/hooks/helpers/illow/useIsIllowInitialised';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const useAlignStoredUserAccountCookieConsentWithLocalIllowCookieConsent =
  () => {
    const illowIsInitialised = useIsIllowInitialised();
    const dispatch = useDispatch();

    const { storedUserCookieConsentPref, isAuthenticated, httpContext } =
      useAppSelector(
        state => ({
          storedUserCookieConsentPref: state.user?.user?.cookie_consent ?? null,
          isAuthenticated: state.user.isAuthenticated,
          httpContext: state.appState.httpContext,
        }),
        shallowEqual,
      );

    const updateCookiePrefsStoredForUser = async (consent: CookieConsent) => {
      try {
        dispatch(setLocalIllowCookieConsent(consent));
        const { data: user } = await persistUpdateUser(httpContext, {
          cookie_consent: consent,
        });
        dispatch(
          updateLoggedInUser({
            cookie_consent: user.cookie_consent,
          }),
        );
      } catch (error) {}
    };

    useEffect(() => {
      if (
        illowIsInitialised &&
        isAuthenticated &&
        storedUserCookieConsentPref
      ) {
        const illowConsent = window.illow.getConsent() as IllowCookieConsent;

        if (
          illowConsent &&
          (storedUserCookieConsentPref.analytics !==
            illowConsent?.statistics?.value ||
            storedUserCookieConsentPref.marketing !==
              illowConsent?.marketing?.value)
        ) {
          // We need to align the user's stored cookie consent prefs with what's set locally in the browser
          updateCookiePrefsStoredForUser({
            analytics: illowConsent.statistics.value,
            marketing: illowConsent.marketing.value,
          });
        }
      }
    }, [
      storedUserCookieConsentPref?.analytics,
      storedUserCookieConsentPref?.marketing,
      illowIsInitialised,
      isAuthenticated,
    ]);
  };

export default useAlignStoredUserAccountCookieConsentWithLocalIllowCookieConsent;
