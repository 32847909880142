/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { CriticReview, CriticReviewId } from '@app/api/resources/CriticReviews';
import { FilmId } from '@app/api/resources/Film';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  criticReviewsById: {},
  criticReviewHighlightIdsByFilmId: {},
  criticReviewTotalsByFilmId: {},
};

export type CriticReviewsState = {
  criticReviewsById: {
    [key: CriticReviewId]: CriticReview;
  };
  criticReviewHighlightIdsByFilmId: {
    [key: FilmId]: number[];
  };
  criticReviewTotalsByFilmId: {
    [key: CriticReviewId]: number;
  };
};

export const criticReviews = (
  state: CriticReviewsState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_CRITIC_REVIEWS_HIGHLIGHTS_FOR_FILM: {
        const { filmId, criticReviewsById, totalNumCriticReviewsForFilm } =
          action.payload;
        const criticReviewIds = Object.keys(criticReviewsById).map(
          criticReviewId => parseInt(criticReviewId, 10),
        );
        draft.criticReviewHighlightIdsByFilmId[filmId] = criticReviewIds;
        draft.criticReviewTotalsByFilmId[filmId] = totalNumCriticReviewsForFilm;
        draft.criticReviewsById = {
          ...state.criticReviewsById,
          ...criticReviewsById,
        };
        break;
      }
    }
  });
