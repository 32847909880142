import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import { getPathFromLocale } from '@app/api/services/language';
import { getFullRouteUrl } from '@app/services/routeHelpers';

import { setHttpContext } from '@app/actions/AppStateActions';
import { setCurrentLanguage } from '@app/actions/UserActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

export const ACCOUNT_SETTINGS_LANGUAGE_REDIRECT_COOKIE =
  'ACCOUNT_SETTINGS_LANGUAGE_REDIRECT_COOKIE';

const InitialiseSwapLocaleForUsersLanguageSetting = () => {
  const { httpContext, userLocale, geoLocation } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      userLocale: state.user?.user?.locale,
      geoLocation: state.user.geoLocation,
    }),
    shallowEqual,
  );

  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userLocale && router.locale) {
      const localePathForUsersLanguageSetting = getPathFromLocale(userLocale);
      const isARedirectOnAccountSettingsPage =
        Cookies.get(ACCOUNT_SETTINGS_LANGUAGE_REDIRECT_COOKIE) === 'true';

      if (
        localePathForUsersLanguageSetting &&
        localePathForUsersLanguageSetting !== router.locale &&
        !isARedirectOnAccountSettingsPage
      ) {
        const { query, asPath } = router;
        const asPathWithoutQueryParams = asPath.split('?')[0];
        const redirectUrlForUsersLanguageSetting = getFullRouteUrl({
          url: asPathWithoutQueryParams,
          i18nUrlData: {
            currentLanguage: localePathForUsersLanguageSetting,
            geoLocation,
          },
          queryParams: { ...query } as Record<string, string>,
        });
        dispatch(
          setHttpContext({
            ...httpContext,
            ['accept-language']: localePathForUsersLanguageSetting,
          }),
        );
        dispatch(setCurrentLanguage(localePathForUsersLanguageSetting));
        router.replace(redirectUrlForUsersLanguageSetting, undefined, {
          locale: localePathForUsersLanguageSetting,
        });
      }
      Cookies.remove(ACCOUNT_SETTINGS_LANGUAGE_REDIRECT_COOKIE);
    }
  }, [userLocale, router.locale]);

  return null;
};

export default InitialiseSwapLocaleForUsersLanguageSetting;
