module.exports = {
  locales: ['default', 'de', 'es', 'fr', 'it', 'nl', 'pt', 'tr', 'en'],
  defaultLocale: 'default',
  interpolation: { prefix: '%{', suffix: '}' },
  loader: false,
  pages: {
    '*': ['common', 'find_users', 'onboarding', 'film_actions', 'promo'], //TODO  remove promo after 29/2/24
    '/[vanityPath]': ['promo', 'film_details', 'splash', 'release', 'go'],
    '/': ['splash', 'promo'],
    '/about': ['about', 'showing'],
    '/account/deactivate': ['settings'],
    '/account/preferences': ['settings'],
    '/account': ['settings'],
    '/account/delivery_address': ['settings'],
    '/account/delivery_address/edit': ['settings', 'onboarding'],
    '/account/preferences/video': ['settings'],
    '/account/preferences/parental-control': ['settings'],
    '/awards-and-festivals': ['browse', 'film_actions', 'cast_show'],
    '/awards-and-festivals/[industryEventSlug]': [
      'film_actions',
      'awards_and_festivals',
    ],
    '/app-store-redirect': ['devices'],
    '/brand-campaign': ['film_details', 'splash', 'promo', 'go'],
    '/browse': ['browse', 'film_actions', 'cast_show'],
    '/cast/[castSlug]': ['cast_show', 'film_actions', 'showing'],
    '/cast/[castSlug]/films/[creditSlug]': ['cast_show', 'film_actions'],
    '/cast/[castSlug]/films/available': ['cast_show', 'film_actions'],
    '/cast': ['browse', 'film_actions', 'cast_show'],
    '/communications': ['settings'],
    '/complete_registration': ['settings'],
    '/contact': ['contact', 'about'],
    '/cookie_preferences': ['terms', 'privacy', 'settings'],
    '/cookie_preferences_tr': ['terms', 'privacy', 'settings'],
    '/data_transfer_consent': ['terms', 'privacy'],
    '/devices': ['devices'],
    '/email_preference_terms': ['terms', 'privacy'],
    '/film-of-the-day': ['showing', 'specials', 'film_actions'],
    '/films/[filmSlug]': [
      'film_details',
      'film_actions',
      'film_player',
      'go',
      'showing',
      'promo',
      'memberships',
      'cast_show',
    ],
    '/films/[filmSlug]/add-to-watchlist': ['film_details'],
    '/films/[filmSlug]/critics-reviews': ['film_details'],
    '/films/[filmSlug]/awards': ['film_details'],
    '/films/[filmSlug]/cast': ['film_details', 'cast_show'],
    '/films/[filmSlug]/gift/delivered': ['film_details'],
    '/films/[filmSlug]/gift': ['film_details'],
    '/films/[filmSlug]/player': ['film_player', 'film_actions'],
    '/films/[filmSlug]/ratings': ['film_details', 'film_actions'],
    '/films/[filmSlug]/trailer': [
      'film_details',
      'film_actions',
      'film_player',
      'showing',
      'promo',
    ],
    '/films': ['browse', 'film_actions', 'cast_show'],
    '/find_users': ['find_users'],
    '/forgot_password': ['settings'],
    '/gifts': ['promo', 'gifts', 'splash', 'film_details', 'go'],
    '/gifts/[giftToken]/confirmation': ['gifts'],
    '/gifts/[giftToken]/edit': ['gifts'],
    '/gifts/[giftToken]': ['gifts'],
    '/gifts/[giftToken]/pay': ['gifts'],
    '/gifts/[giftToken]/pick': ['gifts'],
    '/gifts/[giftToken]/send': ['gifts'],
    '/go': ['go', 'film_actions', 'film_player'],
    '/join': ['film_details', 'splash', 'memberships', 'film_player'],
    '/join/[filmId]': ['film_details', 'splash', 'memberships', 'film_player'],
    '/lasustancia': ['common', 'release'],
    '/lists/[listSlug]': ['list', 'film_actions', 'comments'],
    '/lists/[listSlug]/comments': ['list', 'film_actions', 'comments'],
    '/lists/[listSlug]/followers': ['list', 'film_actions', 'comments'],
    '/lists/[listSlug]/edit': ['list', 'common'],
    '/lists/new': ['list', 'common'],
    '/lists': ['browse', 'film_actions', 'cast_show'],
    '/logged_out': ['splash'],
    '/memberships': ['memberships', 'about', 'go'],
    '/mubi_go_terms_of_service': ['terms', 'privacy'],
    '/notebook/contributors': ['common'],
    '/notebook/faq': ['common'],
    '/notebook/magazine': ['promo', 'notebook_magazine'],
    '/notebook/pitching-guidelines': ['common'],
    '/notebook/posts/author/[author_id]': ['common'],
    '/notebook/posts/tag/[tag_slug]': ['common'],
    '/notebook/posts/[post_slug]': [
      'common',
      'comments',
      'promo',
      'notebook_magazine',
    ],
    '/notebook/posts': ['common'],
    '/notebook/search': ['search'],
    '/notebook/terms': ['terms', 'privacy'],
    '/notebook': ['common', 'promo', 'notebook_magazine'],
    '/payment_method/new': ['settings'],
    '/press': ['press', 'about'],
    '/preview/carousel_takeover/[carouselTakeoverId]': ['common', 'showing'],
    '/preview/film_group/[filmGroupSlug]': ['common'],
    '/preview/film_of_the_day/[filmSlug]': [
      'common',
      'showing',
      'film_details',
    ],
    '/preview/marquee/[filmSlug]': ['film_details'],
    '/preview/mubi_go_programming_takeover/[goTakeoverId]': [
      'common',
      'showing',
      'go',
    ],
    '/preview/onboarding_offer/[onboardingOfferId]': [
      'common',
      'showing',
      'film_details',
    ],
    '/preview/splash_asset_set/[splashAssetId]': ['common', 'splash'],
    '/privacy_policy': ['terms', 'privacy'],
    '/promo': ['promo'],
    '/promos/[promoVanityPath]': ['common', 'promo'],
    '/referrals': ['referral'],
    '/referrals/terms': ['terms', 'privacy'],
    '/r/[releaseVanityPath]': ['release'],
    '/reset_password/[userId]': ['settings'],
    '/retrospective/[tokenOrCountryCode]': ['retrospective24'],
    '/retrospective/share': ['retrospective24'],
    '/screenings-test-page': ['common'],
    '/search/[searchType]': ['common', 'film_actions', 'cast_show', 'search'],
    '/search': ['common'],
    '/showing': [
      'common',
      'showing',
      'film_actions',
      'promo',
      'cast_show',
      'gifts',
      'film_details',
      'film_player',
      'go',
    ],
    '/collections': ['browse'],
    '/collections/[collectionSlug]': [
      'common',
      'showing',
      'specials',
      'film_actions',
      'promo',
      'film_player',
    ],
    '/collections/[collectionSlug]/trailer': ['film_player', 'specials'],
    '/student': ['student', 'splash', 'common'],
    '/students/[studentVanityPath]': ['film_details', 'splash'],
    '/subscription/notebook-cancel': [
      'common',
      'notebook_magazine',
      'subscription',
    ],
    '/subscription': ['common', 'subscription', 'settings'],
    '/subscription/notebook-cancelled': ['common', 'subscription'],
    '/subscription/notebook-cancelled-sub': ['common', 'subscription'],
    '/subscription/before_you_go': ['subscription'],
    '/subscription/renewal_reminder': ['subscription', 'settings', 'common'],
    '/subscription/invoices': ['subscription', 'settings'],
    '/subscription/offer_discount': ['subscription'],
    '/subscription/offer_extended_trial': ['subscription'],
    '/subscription/offer_grandfather': ['subscription'],
    '/subscription/pause_options': ['subscription'],
    '/subscription/cancel': ['subscription'],
    '/subscription/cancelled': ['subscription'],
    '/subscription/paused': ['subscription'],
    '/terms_of_service': ['terms', 'privacy'],
    '/thesubstance': ['common', 'release'],
    '/tv-activation': ['common', 'tv_activation'],
    '/t/[referralPlatform]/[referralSource]/[userCode]': ['promo', 'referral'],
    '/users/[...userIdAndSubPage]': [
      'common',
      'user_profile',
      'film_actions',
      'list',
      'cast_show',
      'film_details',
    ],
    '/viewing-history': ['common', 'film_actions'],
    '/vision': ['vision', 'about'],
    '/watchlist': ['common', 'watchlist', 'film_actions'],
    '/ways-to-watch': ['devices'],
  },
};
