/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Notification } from '@app/api/resources/Notifications';

import * as actionTypes from '@app/actionTypes';

export type NotificationState = {
  notifications: Notification[];
};

export const initialState: NotificationState = {
  notifications: null,
};

export const notification = (
  state: NotificationState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_NOTIFICATIONS:
        draft.notifications = action.payload.notifications;
        break;
    }
  });
