import Cookies from 'js-cookie';

import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { CastMember } from '@app/api/resources/CastMember';
import { Film } from '@app/api/resources/Film';
import { Collection } from '@app/api/resources/FilmGroup';
import { List } from '@app/api/resources/List';
import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';
import { ResponseWithError } from '@app/api/resources/Responses';

import { ObjectOfStrings } from '@app/types/utility-types';

export const getSearchSuggestions = async ({
  httpContext,
}): ResponseWithError<Film[]> =>
  HttpService(httpContext).get('/films/suggestions');

export const doSearch = async ({
  httpContext,
  query = '',
  pageNum = 1,
  perPage = 9,
  filterByNowShowing = false,
}): ResponseWithError<SearchResponse> =>
  HttpService(httpContext).get(
    `/search?query=${query}&page=${pageNum}&per_page=${perPage}${
      filterByNowShowing ? '&playable=true' : ''
    }&include_series=true`,
  );

export const isValidSearchType = (searchType: string) =>
  ['films', 'cast', 'notebook', 'lists'].includes(searchType);

type DoSearchForTypeProps = {
  httpContext: ObjectOfStrings;
  type: SearchApiType;
  query?: string;
  pageNum?: number;
  perPage?: number;
  filterByNowShowing?: boolean;
  abortController?: AbortController;
};

export const doSearchForType = async ({
  httpContext,
  type,
  query = '',
  pageNum = 1,
  perPage = 9,
  filterByNowShowing = false,
  abortController,
}: DoSearchForTypeProps): ResponseWithError<SearchPageResultsFromApi> =>
  HttpService(httpContext).get(
    `/search/${type}?query=${query}&page=${pageNum}&per_page=${perPage}${
      filterByNowShowing ? '&playable=true' : ''
    }${type === 'films' ? '&all_films_on_zero_hits=true' : ''}`,
    { abortController },
  );

export type SearchResults = [
  {
    filmResults: FilmSearchResult[];
    castMemberResults: CastMemberSearchResult[];
    collectionResults: Collection[];
  },
  string[],
];

export const initialSearchResults: SearchResults = [
  {
    filmResults: [],
    castMemberResults: [],
    collectionResults: [],
  },
  [],
];

export const initialSearchMeta = {
  filmTotalCount: 0,
  castMemberTotalCount: 0,
  collectionTotalCount: 0,
};

export const getResultsFromResponseData = (
  data: SearchResponse,
): SearchResults => {
  const filmResults = data?.search?.films ?? [];
  const castMemberResults = data?.search?.cast_members ?? [];
  const collectionResults = data?.search?.film_groups ?? [];

  return [
    {
      filmResults,
      castMemberResults,
      collectionResults,
    },
    Object.keys(data?.search),
  ];
};

export const getResultTotalCountsFromResponse = (data: SearchResponse) => {
  const filmTotalCount = data?.meta?.films_total ?? 0;
  const castMemberTotalCount = data?.meta?.cast_members_total ?? 0;
  const collectionTotalCount = data?.meta?.film_groups_total ?? 0;

  return {
    filmTotalCount,
    castMemberTotalCount,
    collectionTotalCount,
  };
};

const FILM_RESULTS = 5;
const CAST_RESULTS = 2;
const COLLECTION_RESULTS = 3;
const MAX_RESULTS = 9;

export const getResultsToShowFromSearchResults = searchResults => {
  const resultsToShow = Object.assign({}, searchResults);

  const slots = {
    filmResults: Math.min(FILM_RESULTS, searchResults.filmResults?.length ?? 0),
    castMemberResults: Math.min(
      CAST_RESULTS,
      searchResults.castMemberResults?.length ?? 0,
    ),
    collectionResults: Math.min(
      COLLECTION_RESULTS,
      searchResults.collectionResults?.length ?? 0,
    ),
  };

  const slotsTotal = Object.keys(slots).reduce(
    (acc, slotType) => acc + slots[slotType],
    0,
  );

  let delta = MAX_RESULTS - slotsTotal;
  Object.keys(slots).forEach(slotType => {
    const numItemsForSlotType = slots[slotType];
    if (delta > 0) {
      const extraAvailable =
        searchResults[slotType]?.length ?? 0 - numItemsForSlotType;
      const extraTaken = Math.min(delta, extraAvailable);
      resultsToShow[slotType] =
        searchResults[slotType]?.slice(0, numItemsForSlotType + extraTaken) ||
        [];
      slots[slotType] = numItemsForSlotType + extraTaken;
      delta -= extraTaken;
    } else {
      resultsToShow[slotType] =
        searchResults[slotType]?.slice(0, numItemsForSlotType) || [];
    }
  });

  return resultsToShow;
};

export const NOW_SHOWING_FILTER_COOKIE = 'NOW_SHOWING_FILTER_COOKIE';
const ONE_YEAR = 365;
export const setFilterSearchByNowShowingCookie = (shouldFilter: boolean) => {
  if (shouldFilter) {
    Cookies.set(NOW_SHOWING_FILTER_COOKIE, 'true', {
      expires: ONE_YEAR,
    });
  } else {
    Cookies.remove(NOW_SHOWING_FILTER_COOKIE);
  }
};

export const getFilterSearchByNowShowingCookie = () =>
  Cookies.get(NOW_SHOWING_FILTER_COOKIE);

export type SearchType = 'films' | 'cast' | 'notebook' | 'lists';

export type SearchApiType =
  | 'films'
  | 'cast_members'
  | 'notebook_posts'
  | 'lists';

export type SearchMeta = {
  films_total?: number;
  cast_members_total?: number;
  notebook_posts_total?: number;
  lists_total?: number;
  film_groups_total?: number;
};

export type FilmSearchResult = {
  id: number;
  title: string;
  stills: {
    large: string;
    medium: string;
    small: string;
    square: string;
  };
  still_url: string;
  canonical_url: string;
  watch_url: string;
  year: number;
  popularity: number;
  title_locale: string;
  still_average_colour: string;
  playable: boolean;
  directors: {
    id: number;
    name: string;
    canonical_url: string;
  }[];
};

export type CastMemberSearchResult = {
  id: number;
  name: string;
  canonical_url: string;
  image_urls: {
    large: string;
    medium: string;
    small: string;
    square: string;
  };
  primary_type: string;
  quote: string;
  credits: {
    type: string;
    credit: string;
    priority: number;
    film_count: number;
  }[];
  image_url: string;
};

export type SearchResponse = {
  meta: SearchMeta;
  search: {
    films?: FilmSearchResult[];
    cast_members?: CastMemberSearchResult[];
    film_groups?: Collection[];
  };
};

export type FilmResults = {
  films: Film[];
  meta?: Meta;
  zero_results?: boolean;
};

export type CastResults = {
  cast_members: CastMember[];
  meta?: Meta;
};

export type NotebookResults = {
  notebook_posts: NotebookPost[];
  meta?: Meta;
};

export type ListResults = {
  lists: List[];
  meta?: Meta;
};

export type SearchPageResults = Film[] | CastMember[] | NotebookPost[] | List[];

export type SearchPageResultsFromApi =
  | FilmResults
  | CastResults
  | NotebookResults
  | ListResults;

export type SearchPageModuleType = 'zero_results';
