import styled from '@emotion/styled';

// eslint-disable-next-line react/jsx-props-no-spreading
const UnstyledButton = props => <Button type="button" {...props} />;

const Button = styled.button`
  /* flex stops it being any taller than child */
  display: flex;
  appearance: none;
  color: inherit;
  background: transparent;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  cursor: pointer;
`;

export default UnstyledButton;
