import { Store } from 'redux';
import { NextPageContext } from 'next';
import isBot from 'isbot';
import nookies, { destroyCookie, setCookie } from 'nookies';
import { ParsedUrlQuery } from 'querystring';
import { v4 as uuidv4, V4Options } from 'uuid';
import * as Sentry from '@sentry/nextjs';

import { AppConfig, getAppConfig } from '@app/api/resources/AppConfig';
import { getPromo } from '@app/api/resources/Promo';
import {
  CurrentUser,
  exchangeAutoLoginToken,
  MessageType,
  UserBanner,
  UserStateBanner,
} from '@app/api/resources/User';

import { initialiseFilmStillsExperiment } from '@app/api/services/experiments';
import { NOW_SHOWING_FILTER_COOKIE } from '@app/api/services/search';
import { authenticateUser } from '@app/services/authentication';
import { isValidCountryCode } from '@app/services/countries';
import {
  ONE_HOUR_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
} from '@app/services/date-utils';
import {
  checkPathnamesAreEqual,
  doServerRedirect,
  getCountryCodeFromPath,
} from '@app/services/routeHelpers';
import { getSnowplowDuid } from '@app/services/snowplow';
import { isEmpty } from '@app/services/utils';

import { CustomContext } from '@app/types/common';
import { ObjectOfAny, ObjectOfStrings } from '@app/types/utility-types';

import {
  disableTrials,
  setContentWarningPreferenceEnabled,
  setDetectedBrowser,
  setFilterBrowseByNowShowing,
  setHideTopNav,
  setHttpContext,
  setIsMobile,
  setMubiGoEnabledCountry,
  setOnboardingConfig,
  setPromoBannerCookies,
  setPromoBannerPromo,
  setSplashVideo,
  setViewingTrackingIntervalSeconds,
  showFlags,
} from '@app/actions/AppStateActions';
import {
  setUserFeedbackBanner,
  setUserStateBanners,
} from '@app/actions/BannerActions';
import {
  setCountryFullTimeZone,
  setCurrentLanguage,
  setDismissedBanners,
  setGeoLocation,
  setUseUsEnglish,
} from '@app/actions/UserActions';
import { RootState } from '@app/reducers';

import {
  initialiseAcceptedLanguages,
  initialiseMagazineSubscriptionStatus,
  initialisePromotedPromo,
  initialiseSubscriptionPlans,
  initialiseSubscriptionPlansForUser,
  shouldRedirectI18nAlgorithm,
} from '@app/services/initialisation';

export const initialiseCountryOverride = (ctx: CustomContext) => {
  const { asPath, isServer } = ctx;
  const countryFromPath = getCountryCodeFromPath(asPath, isServer);

  const overrideCountry =
    (ctx.req.query.override_country as string)?.toUpperCase() ?? null;

  if (
    !process.env.CI &&
    isBot(ctx.req?.headers?.['user-agent']) &&
    countryFromPath
  ) {
    return countryFromPath;
  } else if (overrideCountry && isValidCountryCode(overrideCountry)) {
    setCookie(ctx, 'override_country', overrideCountry, { path: '/' });
    return overrideCountry;
  }
  if (overrideCountry === '') {
    destroyCookie(ctx, 'override_country', { path: '/' });
    return overrideCountry;
  }

  return overrideCountry || ctx.req.cookies.override_country;
};

export const initialiseDebugging = (
  ctx: CustomContext,
  store: Store<RootState>,
) => {
  let showDebug = ctx.req.query.debug === 'true';
  const hideDebug = ctx.req.query.debug === '';

  if (showDebug) {
    setCookie(ctx, 'debug', 'true', {
      path: '/',
      maxAge: ONE_HOUR_IN_SECONDS,
    });
    store.dispatch(showFlags(true));
  } else if (hideDebug) {
    destroyCookie(ctx, 'debug', { path: '/' });
  } else if (ctx.req.cookies.debug) {
    store.dispatch(showFlags(true));
    showDebug = true;
  }

  return showDebug;
};

export const checkForAutoLoginToken = async (
  httpContext: ObjectOfStrings,
  queryString: ParsedUrlQuery,
  ctx: CustomContext,
) => {
  const cookies = nookies.get(ctx);
  const cookieLoginToken = cookies?.lt;

  // Don't use autoLoginToken if user already logged in.
  if (cookieLoginToken) {
    return null;
  }

  const autoLoginToken = queryString.lt as string;
  if (autoLoginToken && autoLoginToken.length > 0) {
    try {
      const response = await exchangeAutoLoginToken(
        httpContext,
        autoLoginToken,
      );
      return response?.data?.token;
    } catch (error) {
      // Fail silently
    }
  }
  return null;
};

export const getRequestHeaders = (
  ctx: CustomContext,
  overrideCountry: string,
) => {
  const req = ctx?.req || ({} as CustomContext['req']);
  const headers = req?.headers || {};
  const requestHeaders = {} as ObjectOfStrings;

  if (headers['x-real-ip']) {
    requestHeaders['X-Real-IP'] = headers['x-real-ip'];
  }
  if (headers['x-forwarded-for']) {
    requestHeaders['X-Forwarded-For'] = headers['x-forwarded-for'];
  }
  if (headers['x-forwarded-proto']) {
    requestHeaders['X-Forwarded-Proto'] = headers['x-forwarded-proto'];
  }
  if (ctx.locale) {
    requestHeaders['accept-language'] = ctx.locale;
  }
  if (overrideCountry) {
    requestHeaders['Client-Country'] = overrideCountry;
  }

  let snowplowDuid = getSnowplowDuid(req);
  if (!snowplowDuid) {
    // A snowplow anonymous id is required, so we create one, and return to client in a cookie.
    // Back on the client, we then update the snowplowDuid to match, in the component:
    // InitialiseSnowplowContainer.
    snowplowDuid = uuidv4(req as V4Options);
    nookies.set(ctx, 'snowplowDuid', snowplowDuid, {
      path: '/',
      secure: true,
    });
  }

  requestHeaders.ANONYMOUS_USER_ID = snowplowDuid;

  return requestHeaders;
};

export const initialiseAppConfig = async (
  requestHeaders: ObjectOfStrings,
  store: Store<RootState>,
  detectedBrowser: string,
  detectedBrowserVersion: string,
  detectedOperatingSystem: string,
  isMobile: boolean = false,
  serverCtx: CustomContext = null,
): Promise<AppConfig> => {
  const appConfigResponse = await getAppConfig(requestHeaders, serverCtx);
  const { data } = appConfigResponse;

  store.dispatch(
    setOnboardingConfig({
      country: data?.country,
      promo: data?.onboarding_offer?.special_promo,
      offer_ends_on: data?.onboarding_offer?.ends_on,
      offer_ends_on_text: data?.onboarding_offer?.offer_ends,
      content_warning_preference_enabled:
        data?.content_warning_preference_enabled,
      closeToUSCity: data?.promote_premium ?? false,
    }),
  );

  if (!data?.trials_enabled) {
    store.dispatch(disableTrials());
  }

  const geoLocation = data?.country || 'GB';
  store.dispatch(setGeoLocation(geoLocation));

  store.dispatch(setMubiGoEnabledCountry(data?.mubi_go_enabled_country));

  const contentWarningPreferenceEnabled =
    data?.content_warning_preference_enabled;
  store.dispatch(
    setContentWarningPreferenceEnabled(contentWarningPreferenceEnabled),
  );

  if (detectedBrowser) {
    store.dispatch(
      setDetectedBrowser(
        detectedBrowser,
        detectedBrowserVersion,
        detectedOperatingSystem,
      ),
    );
  }

  store.dispatch(setIsMobile(isMobile));

  store.dispatch(
    setViewingTrackingIntervalSeconds(data.viewing_tracking_interval),
  );

  store.dispatch(setCountryFullTimeZone(data.country_full_time_zone));

  store.dispatch(
    setUseUsEnglish(serverCtx.locale === 'en' && data.country === 'US'),
  );

  store.dispatch(setSplashVideo(data?.onboarding?.video));

  return data;
};

export const initialiseHttpContext = async (
  requestHeaders: ObjectOfStrings,
  ctx: CustomContext,
  country: string,
): Promise<ObjectOfStrings> => {
  const { req, store, query } = ctx;

  const httpContext = {
    ...requestHeaders,
  };

  if (country) {
    httpContext['Client-Country'] = country;
  }

  const loginToken = await checkForAutoLoginToken(httpContext, query, ctx);

  if (loginToken) {
    httpContext.Authorization = `Bearer ${loginToken}`;
    setCookie(ctx, 'lt', loginToken, {
      path: '/',
      maxAge: ONE_YEAR_IN_SECONDS,
    });
  } else if (req?.cookies?.lt) {
    httpContext.Authorization = `Bearer ${req.cookies.lt}`;
  }

  store.dispatch(setHttpContext(httpContext));

  return httpContext;
};

export const initialiseBannersFromCookies = (
  store: Store<RootState>,
  cookies = {} as ObjectOfStrings,
) => {
  // Example value from flash_store cookie from ma:
  // { "notice": "This+is+a+message.", "alert": "This+is+a+warning." }
  // There can be one or more message, though usually just one.
  // If more than one, there will only ever be one of each type: notice, alert, or warn.
  const bannersCookieVal = cookies?.flash_store;

  if (!isEmpty(bannersCookieVal)) {
    const bannersFromCookies: UserBanner<MessageType>[] = [];
    const bannersCookieJson: { [K in MessageType]: string } =
      JSON.parse(bannersCookieVal);
    const userFeedbackMessage =
      bannersCookieJson.feedback || bannersCookieJson.feedback_error;

    if (!!userFeedbackMessage) {
      store.dispatch(
        setUserFeedbackBanner(
          userFeedbackMessage,
          !!bannersCookieJson.feedback_error,
        ),
      );
    }

    Object.keys(bannersCookieJson)
      .filter(bannerKey => !bannerKey.includes('feedback'))
      .forEach((key: MessageType) => {
        bannersFromCookies.push({
          type: key,
          message: bannersCookieJson[key].replace(/\+/g, ' '),
        });
      });

    store.dispatch(
      setUserStateBanners(bannersFromCookies as UserStateBanner[]),
    );
  }
};

export const initialiseHideTopNav = (
  store: Store<RootState>,
  query: ParsedUrlQuery,
) => {
  if (query?.menu === 'hidden') {
    store.dispatch(setHideTopNav());
  }
};

export const initialisePromoBannerCookies = (
  store: Store<RootState>,
  cookies = {} as ObjectOfAny,
) => {
  store.dispatch(
    setPromoBannerCookies({
      promoBannerFirstDismissDateTime: cookies?.promoBannerFirstDismissDateTime,
      promoBannerSecondDismissDateTime:
        cookies?.promoBannerSecondDismissDateTime,
    }),
  );
};

export const initialiseDismissedBanners = (
  store: Store<RootState>,
  cookies: ObjectOfAny,
) => {
  const dismissedBanners = {};

  Object.keys(cookies).forEach(cookieKey => {
    if (cookieKey.endsWith('DismissDateTime')) {
      dismissedBanners[cookieKey] = cookies[cookieKey];
    }
  });

  store.dispatch(setDismissedBanners(dismissedBanners));
};

export const initialisePromoBannerPromo = async (
  httpContext: ObjectOfStrings,
  promoVanityPath: string,
  store: Store<RootState>,
) => {
  try {
    const promoResponse = await getPromo(httpContext, promoVanityPath);
    store.dispatch(setPromoBannerPromo(promoResponse.data));
  } catch (error) {
    // do nothing
  }
};

const setNoRedirectCookie = (ctx: NextPageContext | CustomContext) => {
  if ('cookies' in ctx.req && !ctx.req?.cookies?.no_redirect) {
    setCookie(ctx, 'no_redirect', 'true', {
      path: '/',
      maxAge: ONE_YEAR_IN_SECONDS,
    });
  }
};

export const checkNoRedirectParamIsPresent = (
  ctx: NextPageContext | CustomContext,
) => {
  const { query, req } = ctx;

  let noRedirectParamPresent = false;
  if (query?.no_redirect === 'true') {
    noRedirectParamPresent = true;
  }
  if ('cookies' in req && req?.cookies?.no_redirect === 'true') {
    noRedirectParamPresent = true;
  }

  if (noRedirectParamPresent) {
    setNoRedirectCookie(ctx);
    return true;
  }
  return false;
};

export const checkLoginTokenIsPresent = (
  ctx: NextPageContext | CustomContext,
) => {
  const { req } = ctx;

  let loginTokenIsPresent = false;

  if ('cookies' in req && req?.cookies?.lt) {
    loginTokenIsPresent = true;
  }

  return loginTokenIsPresent;
};

export const appInitialization = async (
  ctx: CustomContext,
  initProps: {
    userAgent: string;
    user: any;
    err: Error & {
      statusCode?: number;
    };
    isRedirecting: boolean;
  },
) => {
  const { req, pathname, res } = ctx;

  try {
    initProps.userAgent = ctx?.req?.headers?.['user-agent'] || '';

    const overrideCountry = initialiseCountryOverride(ctx);
    const showDebug = initialiseDebugging(ctx, ctx.store);

    if (showDebug) {
      console.log('>>> req?.cookies?.lt at top is', req?.cookies?.lt);
    }

    const requestHeaders = getRequestHeaders(ctx, overrideCountry);

    if (showDebug) {
      console.log('>>> requestHeaders', requestHeaders);
    }

    let appConfigData: AppConfig;

    try {
      appConfigData = await initialiseAppConfig(
        requestHeaders,
        ctx.store,
        ctx.req?.params?.browser,
        ctx.req?.params?.browserVersion,
        ctx.req?.params?.operatingSystem,
        ctx.req?.params?.isMobile,
        ctx,
      );

      const requiresI18nPrefixRedirect = await shouldRedirectI18nAlgorithm(
        appConfigData.country,
        ctx,
        initProps.user as CurrentUser,
      );

      if (requiresI18nPrefixRedirect) {
        // So return with default pageProps
        return {
          isRedirecting: true,
        };
      }
    } catch (error) {
      if (showDebug) {
        console.log('>>> error initialising app config', error);
      }

      if (error.message === 'INVALID_LOGIN_TOKEN') {
        return {
          isRedirecting: true,
        };
      } else {
        throw error;
      }
    }

    if (showDebug) {
      console.log(
        '>>> req?.cookies?.lt before initialising httpContext is',
        req?.cookies?.lt,
      );
    }

    const httpContext = await initialiseHttpContext(
      requestHeaders,
      ctx,
      appConfigData?.country,
    );

    if (showDebug) {
      console.log('>>> httpContext is', httpContext);
    }

    initialisePromoBannerCookies(ctx.store, ctx.req.cookies);
    initialiseDismissedBanners(ctx.store, ctx.req.cookies);

    initialiseHideTopNav(ctx.store, ctx.query);
    initialiseAcceptedLanguages(ctx.store, ctx.req.headers['accept-language']);
    ctx.store.dispatch(setCurrentLanguage(ctx.locale));
    ctx.store.dispatch(
      setFilterBrowseByNowShowing(
        !!ctx.req.cookies?.[NOW_SHOWING_FILTER_COOKIE] || false,
      ),
    );

    const requests = [
      authenticateUser(httpContext, ctx.store, ctx),
      initialiseSubscriptionPlans(httpContext, ctx.store),
    ];

    // TODO: dont bother fetching the promo if we're outside of the start/end dates
    if (process.env.currentPromoBanner?.promoVanityPath) {
      requests.push(
        initialisePromoBannerPromo(
          httpContext,
          process.env.currentPromoBanner.promoVanityPath,
          ctx.store,
        ),
      );
    }

    const [user] = await Promise.all(requests);
    initProps.user = user;

    initialiseBannersFromCookies(ctx.store, ctx.req.cookies);

    await Promise.all([
      initialiseSubscriptionPlansForUser(
        httpContext,
        ctx.store,
        user as CurrentUser,
      ),
      initialiseMagazineSubscriptionStatus(httpContext, ctx.store),
      initialiseFilmStillsExperiment(
        httpContext,
        ctx.store.dispatch,
        ctx.store.getState().experiments.experiments,
        appConfigData?.country,
      ),
      initialisePromotedPromo(httpContext, ctx.store),
    ]);
  } catch (error) {
    if (checkPathnamesAreEqual(pathname, '/account')) {
      Sentry.captureException(
        new Error('Had login token set on /account path'),
      );
      destroyCookie(ctx, 'lt', { path: '/account' });
      const accountRedirectUrl = `/${ctx.locale}/account`;
      doServerRedirect(res, accountRedirectUrl);
    }
    return { errorStatusCode: 500 };
  }

  return false;
};
