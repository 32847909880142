import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { IllowWidgetReadyData } from '@app/api/services/illow';

import useSnowplowModalViewTracking from '@app/hooks/snowplow/useSnowplowModalViewTracking';

const TrackIllowCookieBannerViews = () => {
  const router = useRouter();

  const trackSnowplowModalView = useSnowplowModalViewTracking();

  const handleTrackViewOnWidgetReady = (event: {
    detail: IllowWidgetReadyData;
  }) => {
    const isIllowBannerShowing = event?.detail?.isOpen;
    if (isIllowBannerShowing) {
      trackSnowplowModalView('illow_cookie_banner');
    }
  };

  const handleTrackViewOnWidgetOpened = () => {
    trackSnowplowModalView('illow_cookie_banner');
  };

  useEffect(() => {
    // Track view when the banner isOpen on Illow initialisation.
    // The banner defaults to being open on init until the user selects to
    // accept or deny our cookie policy.
    window.addEventListener('illow:widget_ready', handleTrackViewOnWidgetReady);

    // Track banner view when showWidget is called,
    // which we do in the account settings area.
    window.addEventListener(
      'illow:widget_opened',
      handleTrackViewOnWidgetOpened,
    );

    return () => {
      window.removeEventListener(
        'illow:widget_ready',
        handleTrackViewOnWidgetReady,
      );
      window.removeEventListener(
        'illow:widget_opened',
        handleTrackViewOnWidgetOpened,
      );
    };
  }, [router?.pathname]);

  return null;
};

export default TrackIllowCookieBannerViews;
