import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IllowCookieConsent } from '@app/api/services/illow';

import { setLocalIllowCookieConsent } from '@app/actions/UserActions';

import useIsIllowInitialised from '@app/hooks/helpers/illow/useIsIllowInitialised';

const InitialiseLocalBrowserIllowCookieConsentState = () => {
  const illowIsInitialised = useIsIllowInitialised();
  const dispatch = useDispatch();

  useEffect(() => {
    if (illowIsInitialised) {
      const illowConsent = window.illow.getConsent() as IllowCookieConsent;

      dispatch(
        setLocalIllowCookieConsent({
          functional: true,
          analytics: illowConsent.statistics.value,
          marketing: illowConsent.marketing.value,
        }),
      );
    }
  }, [illowIsInitialised]);

  return null;
};

export default InitialiseLocalBrowserIllowCookieConsentState;
