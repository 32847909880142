import { urlContainsQueryString } from '@app/services/utils';

import { Breakpoints, mq as themeMqs } from '@app/themes/mubi-theme';

export const getImageUrl = (srcImageUrl: string, width?: number) => {
  let imageUrl = srcImageUrl;
  if (srcImageUrl?.startsWith('/')) {
    imageUrl = `${process.env.responsiveImageDomain}${srcImageUrl}`;
  }
  if (width) {
    if (urlContainsQueryString(imageUrl)) {
      return `${imageUrl}&size=${width}x`;
    }
    return `${imageUrl}?size=${width}x`;
  }
  return imageUrl;
};

export type WidthString =
  | 'full'
  | 'two-thirds'
  | 'half'
  | 'third'
  | 'quarter'
  | 'fifth';

const getWidthMultiplierFromWidthString = (widthString?: WidthString) => {
  switch (widthString) {
    case 'full':
      return 1;
    case 'two-thirds':
      return 0.666;
    case 'half':
      return 0.5;
    case 'third':
      return 0.333;
    case 'quarter':
      return 0.25;
    case 'fifth':
      return 0.2;
    default:
      return 1;
  }
};

export const getPixelWidthForBreakpoint = (breakpoint: Breakpoints) => {
  switch (breakpoint) {
    case 'mobile':
      return 500;
    case 'tablet':
      return 600;
    case 'desktop':
      return 800;
    case 'wide':
    case 'wideLarge':
      return 1200;
    default:
      return 800;
  }
};

export const getImageWidthForDensityAndBreakpointAndWidth = (
  density = 1,
  breakpoint: Breakpoints = 'mobile',
  width: WidthString = 'full',
) => {
  const widthMultiplier = getWidthMultiplierFromWidthString(width);
  const pixelWidth = getPixelWidthForBreakpoint(breakpoint);
  const imageWidth = Math.floor(density * pixelWidth * widthMultiplier);

  // Images are processed by paperclip so they're no larger than 1280px, so no point upscaling to larger.
  // Remove once larger images are available.
  if (imageWidth > 1280) {
    return 1280;
  }
  return imageWidth;
};

export const getResponsiveImageUrls = ({
  imageUrl,
  imageWidth,
}: {
  imageUrl: string;
  imageWidth: WidthString;
}) => [
  ...Object.keys(themeMqs)
    .map((themeMq: Breakpoints) => ({
      key: `min${themeMq}-${imageWidth}`,
      media: `(min-width: ${themeMqs[themeMq]})`,
      srcset: `${getImageUrl(
        imageUrl,
        getImageWidthForDensityAndBreakpointAndWidth(1, themeMq, imageWidth),
      )}, ${getImageUrl(
        imageUrl,
        getImageWidthForDensityAndBreakpointAndWidth(1.6, themeMq, imageWidth),
      )} 2x`,
    }))
    .reverse(),
];
