import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import useStateAndRef from '@app/hooks/utils/useStateAndRef';

type PreviousPath = {
  previousAsPath: string;
  previousPathname: string;
};

const PreviousPagePathContext = createContext<PreviousPath>(null);

export const PreviousPagePathProvider = ({ children }) => {
  const router = useRouter();

  const [previousPath, setPreviousPath] = useState<PreviousPath>({
    previousAsPath: '',
    previousPathname: '',
  });
  const [, currentPathRef, setCurrentPath] = useStateAndRef(null);

  useEffect(() => {
    setCurrentPath({
      previousAsPath: router.asPath,
      previousPathname: router.pathname,
    });
  }, [router.pathname]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (currentPathRef.current) {
        const { previousAsPath, previousPathname } = currentPathRef.current;
        setPreviousPath({
          previousAsPath,
          previousPathname,
        });
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <PreviousPagePathContext.Provider value={previousPath}>
      {children}
    </PreviousPagePathContext.Provider>
  );
};

export default PreviousPagePathContext;
