import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { shallowEqual, useDispatch } from 'react-redux';

import { LifecyclePhase } from '@app/api/resources/User';

import { initRouteChangeListener } from '@app/services/routeHelpers';
import { isClient } from '@app/services/utils';

import { setGoogleTagsInit } from '@app/actions/AppStateActions';

import useCookiePrefs from '@app/hooks/helpers/useCookiePrefs';
import useAppSelector from '@app/hooks/utils/useAppSelector';

type DataLayer = {
  country: string;
  user_id: string;
  email: string;
  lifecycle_phase: LifecyclePhase;
  subscription_id: number | string;
};

const InitialiseTagManagerContainer = () => {
  const dispatch = useDispatch();
  const {
    geoLocation,
    user,
    isAuthenticated,
    activeSubscriber,
    googleTagsInit,
  } = useAppSelector(
    state => ({
      googleTagsInit: state.appState.googleTagsInit,
      geoLocation: state.user.geoLocation,
      isAuthenticated: state.user.isAuthenticated,
      activeSubscriber: state.user.activeSubscriber,
      user: state.user.user,
    }),
    shallowEqual,
  );

  const { hasConsentedToMarketingCookie, hasCookiePrefHookBeenInitialised } =
    useCookiePrefs();

  useEffect(() => {
    if (
      process.env.MUBI_ENV === 'production' &&
      !googleTagsInit &&
      hasCookiePrefHookBeenInitialised
    ) {
      dispatch(setGoogleTagsInit());

      initialiseGlobalDataLayer();

      TagManager.initialize({
        gtmId: process.env.gtmContainer,
        dataLayer: getInitialDataLayer(),
      });

      if (hasConsentedToMarketingCookie) {
        initRouteChangeListener();
      }
    }
  }, [hasCookiePrefHookBeenInitialised]);

  const getInitialDataLayer = () => {
    const initialDataLayer = {} as DataLayer;

    if (geoLocation === 'GB' || geoLocation === 'US') {
      initialDataLayer.country = geoLocation;
    } else {
      initialDataLayer.country = 'Non-curated';
    }

    if (isAuthenticated) {
      initialDataLayer.user_id = String(user.id);
      initialDataLayer.email = user.email;
      initialDataLayer.lifecycle_phase = user.lifecycle_phase;
    } else {
      initialDataLayer.lifecycle_phase = 'Anonymous Visitor';
    }

    if (activeSubscriber) {
      initialDataLayer.subscription_id = user.subscription.id;
    }

    return initialDataLayer;
  };

  const initialiseGlobalDataLayer = () => {
    if (isClient() && !Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }
  };

  return null;
};

export default InitialiseTagManagerContainer;
