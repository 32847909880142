import { AnchorHTMLAttributes } from 'react';
import Link, { type LinkProps } from 'next/link';

import {
  getFullRouteUrl,
  removeLanguageAndCountryPrefixFromPath,
  stripDomainFromUrl,
} from '@app/services/routeHelpers';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

type CustomLinkProps = LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>;

const CustomLink = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  legacyBehavior,
  onMouseEnter,
  onTouchStart,
  onClick,
  children,
  className,
  ...props
}: CustomLinkProps) => {
  const i18nUrlData = useI18nUrlData();

  const hrefWithCorrectPrefix = getFullRouteUrl({
    url: removeLanguageAndCountryPrefixFromPath(stripDomainFromUrl(href)),
    i18nUrlData,
    includeDomain: false,
  });

  return (
    <Link
      href={hrefWithCorrectPrefix}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
      legacyBehavior={legacyBehavior}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onClick={onClick}
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
