import { AnyAction } from 'redux';
import produce from 'immer';

import { CollectionDeduped } from '@app/api/resources/Collections';
import { FilmHighlight } from '@app/api/resources/FilmHighlight';
import { LayoutItem, LayoutItemIndex } from '@app/api/resources/TodayLayout';

import * as actionTypes from '@app/actionTypes';

export type TodaySectionsState = {
  todayLayoutItems: LayoutItem[];
  todaySections: {
    [key: LayoutItemIndex]: CollectionDeduped | FilmHighlight;
  };
};

const initialState: TodaySectionsState = {
  todayLayoutItems: null,
  todaySections: {},
};

const todaySections = (
  state: TodaySectionsState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_TODAY_LAYOUT_ITEMS:
        // First clear todaySections, as new today layout may not align with items currently present
        draft.todaySections = {};

        draft.todayLayoutItems = action.payload.todayLayoutItems;
        break;
      case actionTypes.ADD_TODAY_SECTION:
        draft.todaySections[action.payload.todaySectionIndex] =
          action.payload.todaySection;
        break;
      case actionTypes.REMOVE_TODAY_SECTION:
        delete draft.todaySections?.[action.payload.todaySectionIndex];
        delete draft.todayLayoutItems?.[action.payload.todaySectionIndex];
        break;
    }
  });

export default todaySections;
