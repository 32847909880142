import { useEffect } from 'react';

import { UserId } from '@app/api/resources/User';

import runSpeedTest, { getSpeedTestCookie } from '@app/speedtest/src/services';

import { ObjectOfAny } from '@app/types/utility-types';

type ErrorWithOptionalProps = {
  status?: number;
  data?: any;
  message?: string;
};

export type OnErrorCallback = (
  error: ErrorWithOptionalProps | null,
  extra?: ObjectOfAny,
) => void;

type SpeedTestRunnerProps = {
  userId: UserId;
  onError?: OnErrorCallback;
};

const SpeedTestRunner = ({
  userId,
  onError = () => {},
}: SpeedTestRunnerProps) => {
  useEffect(() => {
    let runSpeedTestTimeoutId = null;
    const speedTestRunInLastHour = getSpeedTestCookie();

    if (!speedTestRunInLastHour) {
      runSpeedTestTimeoutId = setTimeout(async () => {
        try {
          await runSpeedTest(userId, onError);
        } catch (error) {
          onError(error);
        }
      }, 2000);
    }

    return () => clearTimeout(runSpeedTestTimeoutId);
  }, []);

  return null;
};

export default SpeedTestRunner;
