/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Fanship } from '@app/api/resources/Fanship';
import { ListId } from '@app/api/resources/List';
import { RatingId } from '@app/api/resources/Ratings';

import * as actionTypes from '@app/actionTypes';

// eslint-disable-next-line import/no-unused-modules
export const initialState = {
  favoritesByListId: {},
  favoritesByRatingId: {},
};

export type FavoriteState = {
  favoritesByListId: {
    [key: ListId]: Fanship;
  };
  favoritesByRatingId: {
    [key: RatingId]: Fanship;
  };
};

const favoriteTypes = Object.keys(initialState);

export const favorite = (
  state: FavoriteState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.ADD_FAVORITES_OF_TYPE: {
        const { favoriteType, favorites } = action.payload;

        if (favoriteTypes.includes(favoriteType)) {
          draft[favoriteType] = Object.assign(
            {},
            state[favoriteType],
            favorites,
          );
        }
        break;
      }
      case actionTypes.FAVORITE_ITEM_OF_TYPE: {
        const { favoriteType, itemId, favoriteItem } = action.payload;

        if (favoriteTypes.includes(favoriteType)) {
          draft[favoriteType][itemId] = favoriteItem;
        }
        break;
      }
      case actionTypes.UNFAVORITE_ITEM_OF_TYPE: {
        const { favoriteType, itemId } = action.payload;

        if (favoriteTypes.includes(favoriteType)) {
          draft[favoriteType][itemId] = false;
        }

        break;
      }
    }
  });
