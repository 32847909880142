/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { PaymentGatewayName } from '@app/api/resources/PaymentMethod';

import * as actionTypes from '@app/actionTypes';

export type CreditCardPaymentGateway = {
  paymentGatewayName: PaymentGatewayName;
  stripePublicKey?: string;
  dlocalPublicKey?: string;
  showCreditCardFields: {
    billingZipCode: boolean;
    cardholderName: boolean;
    documentInput: boolean;
  };
};

export type PaypalPaymentGateway = {
  enabled: boolean;
  paypalBraintreeToken?: string;
};

export type PaymentGatewayState = {
  creditCard: CreditCardPaymentGateway;
  paypal: PaypalPaymentGateway;
};

const initialState: PaymentGatewayState = {
  creditCard: {
    paymentGatewayName: null,
    stripePublicKey: null,
    dlocalPublicKey: null,
    showCreditCardFields: {
      billingZipCode: null,
      cardholderName: null,
      documentInput: null,
    },
  },
  paypal: {
    enabled: null,
    paypalBraintreeToken: null,
  },
};

export const paymentGateway = (
  state: PaymentGatewayState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_CREDIT_CARD_PAYMENT_GATEWAY: {
        draft.creditCard = action.payload.creditCardPaymentGatewayDetails;
        break;
      }
      case actionTypes.SET_PAYPAL_CONFIG: {
        draft.paypal = action.payload.paypalPaymentGatewayDetails;
        break;
      }
    }
  });
