/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Film } from '@app/api/resources/Film';

import * as actionTypes from '@app/actionTypes';

export type FilmState = {
  films: {
    [key: string]: Film;
  };
  slugToIdLookup: { [key: string]: number };
};

export const initialState = {
  films: {},
  slugToIdLookup: {},
};

export const film = (state: FilmState = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_FILM:
        draft.films[action.payload.film.id] = action.payload.film;
        draft.slugToIdLookup[action.payload.film.slug] = action.payload.film.id;
        break;
      case actionTypes.SET_FILMS:
        action.payload.films.forEach(aFilm => {
          draft.films[aFilm.id] = aFilm;
          draft.slugToIdLookup[aFilm.slug] = aFilm.id;
        });
        break;
    }
  });
