import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUserInitialisedOnPageLoad } from '@app/actions/UserActions';

const InitialiseUserOnPageLoad = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setUserInitialisedOnPageLoad());
    }, 500);
  }, []);

  return null;
};

export default InitialiseUserOnPageLoad;
