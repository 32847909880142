import { Store } from 'redux';
import { destroyCookie } from 'nookies';

import { ErrorResponse } from '@app/api/resources/Responses';
import { getCurrentUser } from '@app/api/resources/User';

import { CustomContext } from '@app/types/common';
import { ObjectOfStrings } from '@app/types/utility-types';

import { setHttpContext } from '@app/actions/AppStateActions';
import { logUserIn, logUserOut } from '@app/actions/UserActions';
import { RootState } from '@app/reducers';

export const authenticateUser = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
  serverCtx: CustomContext,
) => {
  try {
    const loginTokenCookiePresent = httpContext.Authorization;
    if (loginTokenCookiePresent) {
      const response = await getCurrentUser(httpContext, serverCtx);
      if (response?.status === 200) {
        const user = response.data;
        store.dispatch(logUserIn(user));
        return user;
      }
      store.dispatch(logUserOut());
    }
  } catch (error) {
    const errorStatus = error?.status;
    if (errorStatus === 422) {
      store.dispatch(logUserOut());
    } else {
      throw error;
    }
  }
  return false;
};

export const clearUserLoginCookies = async (ctx: CustomContext = null) => {
  destroyCookie(ctx, 'lt', { path: '/' });
  destroyCookie(ctx, 'l', { path: '/' });
  destroyCookie(ctx, '_mubi_session', { path: '/' });
};

export const doLogUserOut = async (
  ctx: CustomContext,
  httpContext: ObjectOfStrings,
) => {
  const { store } = ctx;

  clearUserLoginCookies(ctx);

  store.dispatch(logUserOut());
  const newHttpContext = {
    ...httpContext,
  };

  delete newHttpContext.Authorization;
  store.dispatch(setHttpContext(newHttpContext));
};

export const userRequiresReAuthentication = (error: ErrorResponse) => {
  // 26 is reauthentication required error code
  if (error?.code === 26) {
    return true;
  }
  return false;
};
