import { AnyAction } from 'redux';
import produce from 'immer';

import { Experiment } from '@app/api/services/experiments';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  experiments: [],
};

export type ExperimentsState = {
  experiments: Experiment[];
};

export const experiments = (
  state: ExperimentsState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_EXPERIMENT: {
        const { experiment } = action.payload;

        // Remove experiment if it already exists
        draft.experiments = draft.experiments.filter(
          exp => exp.id !== experiment.id,
        );

        // Set experiment
        draft.experiments.push(experiment);

        break;
      }
      case actionTypes.RESET_EXPERIMENTS: {
        draft.experiments = [];
        break;
      }
    }
  });

export default experiments;
