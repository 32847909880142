/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { PlaybackLanguages } from '@app/api/resources/PlaybackLanguages';

import * as actionTypes from '@app/actionTypes';

export type PlaybackLanguagesState = {
  playbackLanguagesByFilmId: { [key: number]: PlaybackLanguages };
};

export const initialState: PlaybackLanguagesState = {
  playbackLanguagesByFilmId: {},
};

export const playbackLanguages = (state = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_PLAYBACK_LANGUAGE_BY_FILM_ID:
        draft.playbackLanguagesByFilmId[action.payload.filmId] =
          action.payload.playbackLanguages;
        break;
    }
  });
