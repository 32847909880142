import useAlignStoredUserAccountCookieConsentWithLocalIllowCookieConsent from '@app/hooks/helpers/illow/useAlignStoredUserAccountCookieConsentWithLocalIllowCookieConsent';
import useUpdateCookieConsentOnIllowBannerInteraction from '@app/hooks/helpers/illow/useUpdateCookieConsentOnIllowBannerInteraction';

const MonitorIllowCookieConsentChanges = () => {
  useAlignStoredUserAccountCookieConsentWithLocalIllowCookieConsent();
  useUpdateCookieConsentOnIllowBannerInteraction();

  return null;
};

export default MonitorIllowCookieConsentChanges;
