import { useRef, useState } from 'react';

const useStateAndRef = initialState => {
  const [theState, _setTheState] = useState(initialState);
  const theRef = useRef(theState);

  const setTheState = data => {
    theRef.current = data;
    _setTheState(data);
  };

  return [theState, theRef, setTheState];
};

export default useStateAndRef;
