import { handleHttpError } from '@app/api/http-error-handler';
import { Method, ObjectOfStrings } from '@app/api/utility-types';

import {
  getSupportedAudioCodecHeader,
  getSupportedVideoCodecHeader,
} from '@app/api/services/video-codec-support';
import { isClient } from '@app/services/utils';

import { CustomContext } from '@app/types/common';

type RequestConfig = {
  headers?: ObjectOfStrings;
  isPublic?: boolean;
  data?: Record<string, any>;
  abortController?: AbortController;
};

type HttpServiceMethods = {
  get: (url: string, config?: RequestConfig) => any;
  deleteRequest: (url: string, config?: RequestConfig) => any;
  post: (url: string, config?: RequestConfig) => any;
  put: (url: string, config?: RequestConfig) => any;
};

type RequestError = {
  code: number;
  message: string;
  user_message: string;
  fatal: boolean;
};

const TIMEOUT_MS = 15000;

let BASE_URL = '';
let CLIENT_NAME = '';
let CLIENT_ACCEPT_VIDEO_CODECS = null;
let CLIENT_ACCEPT_AUDIO_CODECS = null;
let DEBUG = false;

export const initialiseHttpService = (initialState: {
  base_url?: string;
  client_name?: string;
  debug?: boolean;
}) => {
  if (initialState.base_url) {
    BASE_URL = initialState.base_url;
  }

  if (initialState.client_name) {
    CLIENT_NAME = initialState.client_name;
  }

  if (initialState.debug) {
    DEBUG = initialState.debug;
  }

  if (isClient()) {
    CLIENT_ACCEPT_VIDEO_CODECS = getSupportedVideoCodecHeader();
  }

  if (isClient()) {
    CLIENT_ACCEPT_AUDIO_CODECS = getSupportedAudioCodecHeader();
  }
};

const HttpService = (
  context: ObjectOfStrings,
  serverCtx: CustomContext = null,
): HttpServiceMethods => ({
  get: (url, config = {}) => {
    const [urlPart, queryParams] = url.split('?');
    const {
      headers = {} as ObjectOfStrings,
      isPublic = null,
      abortController,
    } = config;
    return doFetchWithErrorHandling(
      getFullUrl(
        `${encodeURI(urlPart)}${queryParams ? `?${queryParams}` : ''}`,
      ),
      getFetchOptions('get', headers, context, null, isPublic, abortController),
      serverCtx,
    );
  },
  deleteRequest: async (url, config = {}) => {
    const { data = {}, headers = {} } = config;
    return doFetchWithErrorHandling(
      getFullUrl(url),
      getFetchOptions('delete', headers, context, data),
      serverCtx,
    );
  },
  post: async (url, config = {}) => {
    const { data = {}, headers = {} } = config;
    return doFetchWithErrorHandling(
      getFullUrl(url),
      getFetchOptions('post', headers, context, data),
      serverCtx,
    );
  },
  put: async (url, config = {}) => {
    const { data = {}, headers = {} } = config;
    return doFetchWithErrorHandling(
      getFullUrl(url),
      getFetchOptions('put', headers, context, data),
      serverCtx,
    );
  },
});

const doFetchWithErrorHandling = async (
  url: string,
  resource: RequestInit,
  serverCtx: CustomContext,
) => {
  if (process.env.ENABLE_MOCK_SERVICE_WORKER && isClient()) {
    await waitUntilMockServiceWorkerIsReady();
  }

  if (DEBUG) {
    console.log('XHR', url, resource);
  }

  const ac = new AbortController();
  //  fetch() request timeouts at the time indicated by the browser
  //  To stop a request at the desired time we need an abort controller
  const setTimeoutId = setTimeout(() => {
    ac.abort();
  }, TIMEOUT_MS);

  try {
    const response = await fetch(url, resource);
    const data = await getResponseJsonSafely(response);

    if (response.ok) {
      return { data, status: response.status };
    } else {
      // A response is "not ok" if the server responds with a HTTP error: a 4XX or 5XX error code.
      handleHttpError(
        url,
        resource.method as Method,
        response.status,
        serverCtx,
        data as RequestError,
        DEBUG,
      );
      return Promise.reject({
        data: data as RequestError,
        status: response.status,
      });
    }
  } catch (responseError) {
    /**
     * Note: Fetch errors are NOT caused by HTTP errors (4XX or 5XX errors)
     *
     * Fetch errors caused when:
     * - network request times out
     * - the request is aborted using the abort Controller
     * - server is unavailable
     */
    throw responseError;
  } finally {
    clearTimeout(setTimeoutId);
  }
};

const getFetchOptions = (
  method: Method,
  headers: ObjectOfStrings,
  context: ObjectOfStrings,
  jsonPayload?: unknown,
  isPublic?: boolean,
  abortController?: AbortController,
): RequestInit => {
  const dataIsFormData = jsonPayload instanceof FormData;

  const options: RequestInit = {
    method,
    headers: generateHeaders(
      {
        ...headers,
        ...context,
      },
      isPublic,
      !!jsonPayload,
      dataIsFormData,
    ),
  };

  if (jsonPayload) {
    options.body = dataIsFormData ? jsonPayload : JSON.stringify(jsonPayload);
  }

  if (abortController) {
    options.signal = abortController.signal;
  }

  return options;
};

const generateHeaders = (
  headers: ObjectOfStrings,
  isPublic = false,
  hasJsonPayload = false,
  isFormData = false,
): ObjectOfStrings => {
  const userHeaders = { ...headers };
  if (isPublic && userHeaders.Authorization) {
    delete userHeaders.Authorization;
  }
  if (hasJsonPayload && !isFormData) {
    userHeaders['Content-Type'] = 'application/json';
  }

  return {
    ...getGlobalHeaders(),
    ...userHeaders,
  };
};

const getGlobalHeaders = (): ObjectOfStrings => {
  const globalHeaders = {
    CLIENT: CLIENT_NAME,
  };

  if (CLIENT_ACCEPT_VIDEO_CODECS) {
    globalHeaders['Client-Accept-Video-Codecs'] = CLIENT_ACCEPT_VIDEO_CODECS;
  }

  if (CLIENT_ACCEPT_AUDIO_CODECS) {
    globalHeaders['Client-Accept-Audio-Codecs'] = CLIENT_ACCEPT_AUDIO_CODECS;
  }

  return globalHeaders;
};

const getResponseJsonSafely = async (
  response: Response,
): Promise<ObjectOfStrings | RequestError | null> => {
  let data = null;
  try {
    data = await response.json();
  } catch (error) {}

  return data;
};

const getFullUrl = (url: string) => `${BASE_URL}${url}`;

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
const waitUntilMockServiceWorkerIsReady = async () => {
  let mswReady = window?.mswReady;

  while (!mswReady) {
    if (window.mswReady) {
      mswReady = true;
    } else {
      await delay(1000);
    }
  }
};

export default HttpService;
