import { ReactNode } from 'react';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

import {
  getImageUrl,
  getImageWidthForDensityAndBreakpointAndWidth,
  WidthString,
} from '@app/services/images';

import { Breakpoints } from '@app/themes/mubi-theme';

type ResponsiveBackgroundImageProps = {
  imageUrl: string;
  backgroundPosition?: string;
  imageHeight?: string;
  paddingBottom?: string;
  darkenImage?: boolean;
  darkenByOpacity?: string;
  gradientOverlayCss?: string;
  coverOrContain?: 'cover' | 'contain';
  backgroundColor?: string;
  imageWidth?: WidthString;
  imageWidthTablet?: WidthString;
  imageWidthMobile?: WidthString;
  className?: string;
  children?: ReactNode;
  altText?: string;
};

const ResponsiveBackgroundImage = ({
  imageUrl,
  backgroundPosition = 'center',
  imageHeight = '',
  paddingBottom = '',
  imageWidthMobile = null,
  imageWidthTablet = null,
  imageWidth = 'full',
  darkenImage = false,
  darkenByOpacity = '0.5',
  gradientOverlayCss = null,
  coverOrContain = 'cover',
  children = null,
  backgroundColor = '',
  className = '',
  altText = '',
}: ResponsiveBackgroundImageProps) => {
  const fullImageUrl = getImageUrl(imageUrl);

  if (!fullImageUrl && !backgroundColor) {
    return <>{children}</>;
  }

  return (
    <ImageContainer
      className={className}
      imageUrl={fullImageUrl}
      backgroundPosition={backgroundPosition}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      imageWidthMobile={imageWidthMobile}
      imageWidthTablet={imageWidthTablet}
      paddingBottom={paddingBottom}
      darkenImage={darkenImage}
      darkenByOpacity={darkenByOpacity}
      gradientOverlayCss={gradientOverlayCss}
      coverOrContain={coverOrContain}
      data-testid="image-container"
      data-cy="image-container"
      backgroundColor={backgroundColor}
      title={altText}
    >
      {children && children}
    </ImageContainer>
  );
};
export default ResponsiveBackgroundImage;

type ImageContainerProps = {
  imageUrl: string;
  backgroundPosition: string;
  imageHeight: string;
  paddingBottom: string;
  darkenImage: boolean;
  darkenByOpacity: string;
  gradientOverlayCss: string | null;
  coverOrContain: 'cover' | 'contain';
  backgroundColor: string;
  imageWidth: WidthString;
  imageWidthTablet: WidthString;
  imageWidthMobile: WidthString;
};

const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  height: ${props => props.imageHeight};
  background-repeat: no-repeat;
  background-position: ${props => props.backgroundPosition};
  background-size: ${props => props.coverOrContain};
  ${props =>
    props.paddingBottom !== '' ? `padding-bottom: ${props.paddingBottom};` : ''}

  ${props => generateBackgroundImageUrlsForDpi(props, 'max', '124', '1.24', 1)};

  ${props => generateBackgroundImageUrlsForDpi(props, 'min', '125', '1.25', 2)};
  background-color: ${props => props.backgroundColor};
`;

const generateBackgroundImageUrlsForDpi = (
  props: ImageContainerProps & {
    theme?: Theme;
  },
  minOrMax: 'min' | 'max',
  res: string,
  maxDevicePixelRatio: string,
  dpi: 1 | 2,
) => `
    background-image: ${generateBackgroundImage(props, dpi, 'mobile')};

    @media (min-width: ${
      props.theme.mqNew.mobile
    }) and (${minOrMax}-resolution: ${res}dpi),
    (min-width: ${
      props.theme.mqNew.mobile
    }) and (-webkit-${minOrMax}-device-pixel-ratio: ${maxDevicePixelRatio})  {
      background-image: ${generateBackgroundImage(props, dpi, 'tablet')};
    }

    @media (min-width: ${
      props.theme.mqNew.tablet
    }) and (${minOrMax}-resolution: ${res}dpi),
    (min-width: ${
      props.theme.mqNew.tablet
    }) and (-webkit-${minOrMax}-device-pixel-ratio: ${maxDevicePixelRatio}) {
      background-image: ${generateBackgroundImage(props, dpi, 'desktop')};
    }

    @media (min-width: ${
      props.theme.mqNew.desktop
    }) and (${minOrMax}-resolution: ${res}dpi),
    (min-width: ${
      props.theme.mqNew.desktop
    }) and (-webkit-${minOrMax}-device-pixel-ratio: ${maxDevicePixelRatio})  {
      background-image: ${generateBackgroundImage(props, dpi, 'wide')};
    }`;

const darkenImageValue = darkenByOpacity =>
  `linear-gradient( to bottom, rgba(0, 0, 0, ${darkenByOpacity}), rgba(0, 0, 0, ${darkenByOpacity})), `;

const getImageWidth = (props: ImageContainerProps, breakpoint: Breakpoints) => {
  if (breakpoint === 'mobile' && props.imageWidthMobile) {
    return props.imageWidthMobile;
  }
  if (breakpoint === 'tablet' && props.imageWidthTablet) {
    return props.imageWidthTablet;
  }
  return props.imageWidth;
};

const generateBackgroundImage = (
  props: ImageContainerProps,
  pixelDensity: 1 | 2 = 1,
  breakpoint: Breakpoints = 'mobile',
) => {
  let darkenedPrefix = '';

  if (props.darkenImage) {
    darkenedPrefix = props.gradientOverlayCss
      ? `${props.gradientOverlayCss}, `
      : darkenImageValue(props.darkenByOpacity);
  }

  const imageWidth = getImageWidth(props, breakpoint);

  const backgroundImage = getImageUrl(
    props.imageUrl,
    getImageWidthForDensityAndBreakpointAndWidth(
      pixelDensity,
      breakpoint as Breakpoints,
      imageWidth as WidthString,
    ),
  );
  return `${darkenedPrefix}url(${backgroundImage})`;
};
