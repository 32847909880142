import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getFaqUrl } from '@app/services/helpscout';

import { color as themeColors } from '@app/themes/mubi-theme';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';
import ResponsiveBackgroundImage from '@app/components/responsive-images/ResponsiveBackgroundImage';
import StandardHeadTags from '@app/components/StandardHeadTags';
import TranslateHtml from '@app/components/TranslateHtml';

const statusCodeToErrorMap = {
  404: '404_sorry',
  500: '500_sorry',
};

const statusCodeToTitleMap = {
  404: '404_title',
  500: '500_title',
};

const backgroundImages = {
  404: '/static/images/error/404-background.jpg',
  500: '/static/images/error/500-background.jpg',
};

type ErrorGenericProps = {
  errorMessage?: string;
  statusCode?: 404 | 500;
};

const ErrorGeneric = ({ errorMessage, statusCode }: ErrorGenericProps) => {
  const { t, lang } = useTranslation('common');
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);

  let errorMessageToShow: JSX.Element;
  let title: string;
  let heading = t('common:common.errors.500_heading');
  let backgroundImage = backgroundImages['500'];

  if (errorMessage) {
    errorMessageToShow = <>{errorMessage}</>;
    title = t('common:common.errors.title_generic');
  } else if (statusCode && statusCodeToErrorMap[statusCode]) {
    errorMessageToShow = (
      <TranslateHtml
        t={t}
        i18nKey={`common:common.errors.${statusCodeToErrorMap[statusCode]}`}
        i18nVars={{
          linebreak: <LineBreak />,
        }}
      />
    );
    backgroundImage = backgroundImages[statusCode];
    title = t(`common:common.errors.${statusCodeToTitleMap[statusCode]}`);
    heading = t(`common:common.errors.${statusCode}_heading`);
  } else {
    errorMessageToShow = t('common:common.errors.500_message');
    title = t('common:common.errors.title_generic');
  }

  return (
    <>
      <StandardHeadTags title={title} noIndex />

      <Container>
        <ResponsiveBackgroundImage
          imageUrl={backgroundImage}
          imageHeight="100vh"
          backgroundPosition={statusCode === 404 ? 'center' : 'left'}
        >
          <PageSection>
            <PageContent>
              <MubiLink href={isAuthenticated ? '/showing' : '/'}>
                <MubiLogoIcon
                  width="100%"
                  logoColor={themeColors.white}
                  dotsColor={themeColors.white}
                />
              </MubiLink>

              <OopsSection>
                <OopsHeader>{heading}</OopsHeader>
                <OopsPara>{errorMessageToShow}</OopsPara>
                <OopsLinkContainer>
                  <MubiButtonLink url="/showing" isSpaLink={false}>
                    {t('common:common.errors.cta')}
                  </MubiButtonLink>
                </OopsLinkContainer>
              </OopsSection>
              <SupportSection>
                <SupportPara>
                  <TranslateHtml
                    t={t}
                    i18nKey="common:common.errors.need_help"
                    i18nVars={{
                      support_page_link: (
                        <SupportLink href={getFaqUrl('home', { lang })}>
                          {t('common:common.errors.support_page')}
                        </SupportLink>
                      ),
                    }}
                  />
                </SupportPara>
              </SupportSection>
            </PageContent>
          </PageSection>
        </ResponsiveBackgroundImage>
      </Container>
    </>
  );
};

export default ErrorGeneric;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  color: ${themeColors.white};
  font-family: ${props => props.theme.font.body};
  position: relative;
`;

const PageContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    justify-content: space-between;
  }
`;

const MubiLink = styled(Link)`
  display: inline-block;
  margin-top: 30px;
  width: 80px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 90px;
  }
`;

const OopsSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto 0 0;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin: 0;
  }
`;

const OopsHeader = styled.h1`
  font-family: ${props => props.theme.font.brand};
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 50px;
    line-height: 48px;
    margin-bottom: 15px;
  }
`;

const OopsPara = styled.p`
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 20px;
    margin-bottom: 40px;
  }
`;

const OopsLinkContainer = styled.div``;

const SupportSection = styled.div`
  padding: 30px 0 100px;
  display: flex;
  justify-content: center;
  margin: 0 0 auto;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 30px 0;
    margin: 0;
  }
`;

const SupportPara = styled.p``;

const SupportLink = styled.a`
  color: ${themeColors.white};
  font-weight: bold;
  text-decoration: underline;

  &:visited {
    color: ${themeColors.white};
  }

  &:hover {
    color: ${themeColors.white};
  }
`;

const LineBreak = styled.br`
  display: none;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: block;
  }
`;
