/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { FilmGroup } from '@app/api/resources/FilmGroup';

import * as actionTypes from '@app/actionTypes';

// eslint-disable-next-line import/no-unused-modules
export const initialState = {
  filmGroupSpotlights: null,
};

export type FilmGroupSpotlightState = {
  filmGroupSpotlights: FilmGroup[];
};

const filmGroupSpotlights = (
  state: FilmGroupSpotlightState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_FILM_GROUP_SPOTLIGHTS: {
        const { filmGroupSpotlights } = action.payload;
        draft.filmGroupSpotlights = filmGroupSpotlights;
        break;
      }
    }
  });

export default filmGroupSpotlights;
