import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { Film } from '@app/api/resources/Film';
import { ObjectOfStrings, Translate } from '@app/api/utility-types';
import { addUrlParam, getPaginationQS } from '@app/api/utils';

import { CastFilterOptionFromApi } from '@app/api/services/browse-sort-filter-options';

import { StoredCastMember } from '@app/reducers/CastMembersReducer';

export type CastCredit = {
  type:
    | 'director'
    | 'showrunner'
    | 'screenplay'
    | 'novel'
    | 'author'
    | 'story'
    | 'play'
    | 'cast'
    | 'narrator'
    | 'self'
    | 'voice'
    | 'voice_dub'
    | 'cinematography'
    | 'music'
    | 'lyrics'
    | 'production_design'
    | 'producer'
    | 'executive_producer'
    | 'editing'
    | 'sound'
    | 'animation'
    | 'costume_design'
    | 'art_department'
    | 'special_effects'
    | 'visual_effects'
    | 'choreography';
  credit:
    | 'Animation'
    | 'Art Department'
    | 'Author'
    | 'Cast'
    | 'Choreography'
    | 'Cinematography'
    | 'Costume Design'
    | 'Director'
    | 'Editing'
    | 'Executive Producer'
    | 'Lyrics'
    | 'Music'
    | 'Narrator'
    | 'Novel'
    | 'Play'
    | 'Producer'
    | 'Production Design'
    | 'Screenplay'
    | 'Self'
    | 'Showrunner'
    | 'Sound'
    | 'Special Effects'
    | 'Story'
    | 'Visual Effects'
    | 'Voice'
    | 'Voice (Dub)';
  priority: number;
  film_count: number;
};

export type CastMemberId = number;
export type CastMemberSlug = string;

export type CastMember = {
  id: CastMemberId;
  name: string;
  name_upcase: string;
  slug: string;
  canonical_url: string;
  primary_type: string;
  quote: string;
  image_url: string;
  credits: CastCredit[];
  display_credits: string;
};

export type Director = {
  name: string;
  name_upcase: string;
  slug: string;
};

export type CastFilmsSortBy = 'year' | 'popularity_quality_score' | 'title';
export type CastFilmsSortPeriod = '7' | '30' | '365';

const creditToDisplayMap = {
  animation: 'animator',
  cast: 'actor',
  choreography: 'choreographer',
  cinematography: 'cinematographer',
  costume_design: 'costume_designer',
  editing: 'editor',
  music: 'composer',
  novel: 'writer',
  production_design: 'production_designer',
  play: 'playwright',
  screenplay: 'screenwriter',
};

export const mapCreditToDisplayCredit = (credit: CastCredit, t: Translate) => {
  const mappedCreditType = creditToDisplayMap[credit.type];
  if (mappedCreditType) {
    return t(`cast_show:cast_show.credit.${mappedCreditType}`);
  }

  return credit.credit;
};

const getCastMemberUrl = (castMemberId: CastMemberId | CastMemberSlug) =>
  `/cast_members/${castMemberId}`;

const getCastMembersFilmsUrl = ({
  castMemberId,
  pageNum,
  perPage,
  filterByCreditType,
  sortBy,
  sortPeriod,
}: {
  castMemberId: CastMemberId;
  pageNum: number;
  perPage: number;
  filterByCreditType: string;
  sortBy: string;
  sortPeriod: string;
}) => {
  const paginationQs = getPaginationQS(pageNum, perPage);

  let url = `/cast_members/${castMemberId}/films${
    paginationQs ? `?${paginationQs}` : ''
  }`;

  if (filterByCreditType) {
    url = addUrlParam(url, `cast_member_credit=${filterByCreditType}`);
  }

  if (sortBy) {
    url = addUrlParam(url, `sort=${sortBy}`);
  }

  if (sortPeriod) {
    url = addUrlParam(url, `sort_period=${sortPeriod}`);
  }

  return url;
};

export const getCastMember = (
  httpContext: ObjectOfStrings,
  castMemberId: CastMemberId | CastMemberSlug,
): Promise<{
  data: CastMember;
}> => HttpService(httpContext).get(getCastMemberUrl(castMemberId));

export const getCastMembersFilms = ({
  httpContext,
  castMemberId,
  pageNum,
  perPage,
  filterByCreditType,
  sortBy,
  sortPeriod,
}: {
  httpContext: ObjectOfStrings;
  castMemberId: CastMemberId;
  pageNum: number;
  perPage: number;
  filterByCreditType: string;
  sortBy: string;
  sortPeriod: string;
}): Promise<{
  data: {
    films: Film[];
    meta: Meta;
  };
}> =>
  HttpService(httpContext).get(
    getCastMembersFilmsUrl({
      castMemberId,
      pageNum,
      perPage,
      filterByCreditType,
      sortBy,
      sortPeriod,
    }),
  );

export const getCastMemberFilmsNowShowing = ({
  httpContext,
  castMemberIdOrSlug,
  pageNum,
  perPage,
}: {
  httpContext: ObjectOfStrings;
  castMemberIdOrSlug: CastMemberId | CastMemberSlug;
  pageNum: number;
  perPage: number;
}): Promise<{
  data: {
    films: Film[];
    meta: Meta;
  };
}> => {
  const paginationQs = getPaginationQS(pageNum, perPage);

  const url = `/cast_members/${castMemberIdOrSlug}/available_films${
    paginationQs ? `?${paginationQs}` : ''
  }`;

  return HttpService(httpContext).get(url);
};

export const getCastMemberFromStore = (
  castMemberIdOrSlug: number | string,
  castMembersStore: {
    castMembers: { [key: number]: StoredCastMember };
    slugToIdLookup: { [key: string]: number };
  },
) => {
  const castMembers = castMembersStore?.castMembers;

  if (typeof castMemberIdOrSlug === 'string') {
    const castMembersSlugtoIdLookup = castMembersStore?.slugToIdLookup;
    const castMemberId = castMembersSlugtoIdLookup?.[castMemberIdOrSlug];
    return castMembers?.[castMemberId];
  }
  return castMembers?.[castMemberIdOrSlug];
};

export const getSlugFromCanonicalUrl = (castMember: CastMember) =>
  castMember.canonical_url
    .substring(castMember.canonical_url.lastIndexOf('/') + 1)
    .trim();

export const getCastMemberTypeFilterOptionsForFilm = (
  httpContext: ObjectOfStrings,
  filmId: number,
): Promise<{
  data: CastFilterOptionFromApi[];
}> => HttpService(httpContext).get(`/cast_members/types?film_id=${filmId}`);
