import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const OverrideCountryNoticeContainer = ({
  children,
}: {
  children: (props: {
    overrideCountry: string;
    isAdmin: boolean;
  }) => JSX.Element;
}) => {
  const isAdmin = useAppSelector(state => state.user.isAdmin);
  const [overrideCountry, setOverrideCountry] = useState(null);

  useEffect(() => {
    setOverrideCountry(Cookies.get('override_country'));
  }, []);

  return children({ overrideCountry, isAdmin });
};

export default OverrideCountryNoticeContainer;
