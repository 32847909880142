import { ReactNode } from 'react';

import {
  StyledButtonLink,
  StyledSpaButtonLink,
} from '@app/components/buttons/MubiButton/ButtonStyle';

type MubiButtonLinkProps = {
  url?: string;
  children?: ReactNode;
  className?: string;
  isSpaLink?: boolean;
  rel?: string;
  target?: string;
  actionType?: 'primary' | 'secondary' | 'tertiary' | 'blank';
  onClick?: () => void;
};

const MubiButtonLink = ({
  url = null,
  children = null,
  className = '',
  isSpaLink = true,
  rel = '',
  target = '',
  actionType = 'primary',
  onClick,
}: MubiButtonLinkProps) => {
  if (isSpaLink) {
    return (
      <StyledSpaButtonLink
        href={url}
        className={className}
        rel={rel}
        target={target}
        actiontype={actionType}
        onClick={onClick}
      >
        {children}
      </StyledSpaButtonLink>
    );
  }

  return (
    <StyledButtonLink
      href={url}
      className={className}
      rel={rel}
      target={target}
      actiontype={actionType}
      onClick={onClick}
    >
      {children}
    </StyledButtonLink>
  );
};

export default MubiButtonLink;
