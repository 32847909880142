/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import {
  NotebookPost,
  PostPages,
} from '@app/api/resources/notebook/NotebookPost';

import { arrayToObjectKeyedById } from '@app/services/utils';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  notebookPosts: {},
  homepagePostsPages: {
    pages: {},
    currentPage: 0,
  },
  allPostsPages: {
    pages: {},
    currentPage: 0,
  },
  authorPostsPages: {},
  tagPostsPages: {},
};

export type NotebookPostState = {
  notebookPosts: {
    [key: string]: NotebookPost;
  };
  homepagePostsPages: {
    pages: {
      [key: number]: PostPages;
    };
    currentPage: number;
  };
  allPostsPages: {
    pages: {
      [key: number]: PostPages;
    };
    currentPage: number;
  };
  authorPostsPages: {
    [key: number]: {
      pages: {
        [key: number]: PostPages;
      };
      currentPage: number;
    };
  };
  tagPostsPages: {
    [key: string]: {
      pages: {
        [key: number]: PostPages;
      };
      currentPage: number;
    };
  };
};

export const notebookPost = (
  state: NotebookPostState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_NOTEBOOK_POSTS: {
        draft.notebookPosts = arrayToObjectKeyedById(
          action.payload.notebookPosts,
          'slug',
        );
        break;
      }
      case actionTypes.ADD_NOTEBOOK_POSTS: {
        action.payload?.notebookPosts?.forEach(
          (aNotebookPost: NotebookPost) => {
            draft.notebookPosts[aNotebookPost.slug] = aNotebookPost;
          },
        );
        break;
      }
      case actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_HOME: {
        draft.homepagePostsPages.currentPage = action.payload.pageNum;
        draft.homepagePostsPages.pages[action.payload.pageNum] = {
          postIds: action.payload.notebookPostIds,
          pageMeta: action.payload.pageMeta,
        };
        break;
      }
      case actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_ALL_POSTS: {
        draft.allPostsPages.currentPage = action.payload.pageNum;
        draft.allPostsPages.pages[action.payload.pageNum] = {
          postIds: action.payload.notebookPostIds,
          pageMeta: action.payload.pageMeta,
        };
        break;
      }
      case actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_AUTHOR: {
        if (!draft.authorPostsPages[action.payload.authorId]) {
          draft.authorPostsPages[action.payload.authorId] = {
            pages: {},
            currentPage: null,
          };
        }
        draft.authorPostsPages[action.payload.authorId].pages[
          action.payload.pageNum
        ] = {
          postIds: action.payload.notebookPostIds,
          pageMeta: action.payload.pageMeta,
        };
        break;
      }
      case actionTypes.ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_TAG: {
        if (!draft.tagPostsPages[action.payload.tagSlug]) {
          draft.tagPostsPages[action.payload.tagSlug] = {
            pages: {},
            currentPage: null,
          };
        }
        draft.tagPostsPages[action.payload.tagSlug].pages[
          action.payload.pageNum
        ] = {
          postIds: action.payload.notebookPostIds,
          pageMeta: action.payload.pageMeta,
        };
        break;
      }
    }
  });
