import { ReactNode } from 'react';
import styled from '@emotion/styled';

import { BreakpointOrderKeys } from '@app/themes/mubi-theme';

type PageSectionProps = {
  children: ReactNode;
  fromBreakpoint?: BreakpointOrderKeys;
  untilBreakpoint?: BreakpointOrderKeys;
  mobilePadding?: boolean;
  bgColor?: string;
  minHeight?: string;
  fullHeight?: boolean;
  relative?: boolean;
  useLegacyMQ?: boolean;
  useTodayMQs?: boolean;
};

const PageSection = ({
  children,
  fromBreakpoint = 'mobile',
  untilBreakpoint = 'wideLarge',
  mobilePadding = true,
  bgColor = '',
  minHeight = '',
  fullHeight = false,
  relative = false,
  useLegacyMQ = false,
  useTodayMQs = false,
}: PageSectionProps) => (
  <Container bgColor={bgColor} minHeight={minHeight}>
    <ContainerInner
      fromBreakpoint={fromBreakpoint}
      untilBreakpoint={untilBreakpoint}
      mobilePadding={mobilePadding}
      fullHeight={fullHeight}
      relative={relative}
      useLegacyMQ={useLegacyMQ}
      useTodayMQs={useTodayMQs}
    >
      {children}
    </ContainerInner>
  </Container>
);

export default PageSection;

type ContainerProps = {
  bgColor: string;
  minHeight: string;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  ${props =>
    props.bgColor && `background-color: ${props.theme.color[props.bgColor]};`}
  min-height: ${props => props.minHeight};
`;

type ContainerInnerProps = {
  fromBreakpoint: BreakpointOrderKeys;
  untilBreakpoint: BreakpointOrderKeys;
  mobilePadding: boolean;
  fullHeight: boolean;
  relative?: boolean;
  useLegacyMQ?: boolean;
  useTodayMQs?: boolean;
};

const ContainerInner = styled.div<ContainerInnerProps>`
  height: ${props => (props.fullHeight ? '100%' : '')};

  ${props =>
    props.theme.pageSectionContainer(
      props.useLegacyMQ,
      props.mobilePadding,
      props.fromBreakpoint,
      props.untilBreakpoint,
      props.useTodayMQs,
    )}

  ${props => (props.relative ? 'position: relative;' : '')}
`;
