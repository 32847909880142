import { Fragment, ReactNode } from 'react';

import { Translate } from '@app/api/utility-types';

import { ObjectOfAny } from '@app/types/utility-types';

/**
 * TranslateHtml
 *
 * A wrapper for the translate function t()
 * It converts new line characters into break components,
 * and add support for i18n variables that are components
 *
 * Old way:
 * const copyHtml = t(key, { film_title: `<strong>${film.title}</strong>` });
 * <p dangerouslySetInnerHTML={{ __html: copyHtml }} />
 *
 * New way:
 * <TranslateHtml i18nKey={key} i18nVars={{ film_title: <strong>{film.title}</strong> }} />
 */

const renderLine = (line: string, i18nVars: ObjectOfAny) => {
  const placeholderNames = Object.keys(i18nVars);
  if (!placeholderNames.length) {
    return line;
  }
  // at this point, the line of copy still contains the placeholders e.g. 'Hello %{Andy}', so here we use regex to replace those placeholders with the i18nVars passed in
  const placeholders = placeholderNames.map(name => `%{${name}}`);
  const placeholderRegex = new RegExp(`(${placeholders.join('|')})`, 'g');
  const parts = line.split(placeholderRegex);
  return (
    <>
      {parts.map((part, j) => {
        const i = placeholders.indexOf(part);
        const replacePlaceholderWithVar = i !== -1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${part}${j}`}>
            {replacePlaceholderWithVar ? i18nVars[placeholderNames[i]] : part}
          </Fragment>
        );
      })}
    </>
  );
};

const renderBreakNode = (line: string, breakNode: ReactNode) => {
  // When injecting break nodes we add a space as well, for cases where we hide the breaks on certain screen sizes - in those cases you need a space so two adjacent words don't get stuck together - unless the last char of this line was a hyphen e.g. it's a hyphenated word
  const addSpaceAfterBreak = line[line.length - 1] !== '-';
  return (
    <>
      {breakNode}
      {addSpaceAfterBreak && <span> </span>}
    </>
  );
};

// The i18next translate function cannot handle components as variables, so instead we change the interpolation settings so that it will NOT replace the placeholders. Then we can use the placeholders later to know where to inject the components
const getCopyWithPlaceholders = (
  t: Translate,
  i18nKey: string,
  scope: string,
) => {
  const key = scope ? `${scope}.${i18nKey}` : i18nKey;
  return t(key);
};

type TranslateHtmlProps = {
  i18nKey: string;
  t: Translate;
  i18nVars?: ObjectOfAny;
  breakNode?: ReactNode;
  scope?: string;
  className?: string;
};

const TranslateHtml = ({
  t,
  i18nKey,
  i18nVars = {},
  breakNode = <br />,
  scope = null,
  className,
}: TranslateHtmlProps) => {
  const copy = getCopyWithPlaceholders(t, i18nKey, scope);
  const lines = copy.split('\n');
  const lastLineIndex = lines.length - 1;
  return (
    <span className={className}>
      {lines.map((line: string, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${line}${i}`}>
          {renderLine(line, i18nVars)}
          {i < lastLineIndex && renderBreakNode(line, breakNode)}
        </Fragment>
      ))}
    </span>
  );
};

export default TranslateHtml;
