import addSeconds from 'date-fns/addSeconds';
import Cookie from 'js-cookie';

import { OnErrorCallback } from '@app/speedtest';

import { UserId } from '@app/api/resources/User';
import {
  getSpeedTestForFile,
  getSpeedTestForRegion,
  getSpeedTestRegions,
  submitSpeedTestReport,
} from '@app/speedtest/src/api';

import {
  flattenArrayOfObjects,
  isClient,
  sortArrayOfObjectsByProperty,
} from '@app/services/utils';

import { ObjectOfAny } from '@app/types/utility-types';

type Edge = {
  dns: string;
  latency?: number;
  latency_errors?: boolean;
  timings_allowed?: boolean;
  speed_errors?: boolean;
};

type File = {
  id: string;
  size: number;
  path: string;
};

type EdgeReport = Edge & { bandwidth100KB?: number; bandwidth1MB: number };

export type SpeedTestReport = {
  report: EdgeReport[];
  user_id?: UserId;
};

const speedTestCookieName = 'mubi_speedtest';
const PROTOCOL = isClient() ? window.location.protocol : '';

export const getSpeedTestCookie = () => Cookie.get(speedTestCookieName);

const setSpeedTestCookie = (retestEverySeconds: number) => {
  const retestPeriod = addSeconds(new Date(), retestEverySeconds);
  Cookie.set(speedTestCookieName, 'true', { expires: retestPeriod });
};

const runLatencyTestForRegion = async (region: string) => {
  const testReport = {
    dns: region,
  };

  const url = `${PROTOCOL}//${region}/?id=${Date.now()}`;
  const startTime = performance.now();

  try {
    await getSpeedTestForRegion(url);

    const finishedAt = performance.now();

    const navigationEntry =
      performance.getEntriesByName &&
      (performance.getEntriesByName(url)[0] as
        | PerformanceNavigationTiming
        | undefined);

    const timingsAllowed = !!(navigationEntry && navigationEntry?.requestStart); // requestStart is 0 if Timing-Allow-Origin is not set

    testReport['timings_allowed'] = timingsAllowed;
    testReport['latency_errors'] = false;

    if (timingsAllowed) {
      testReport['latency'] = Math.round(
        navigationEntry?.responseStart - navigationEntry?.requestStart,
      );
    } else {
      testReport['latency'] = Math.round(finishedAt - startTime);
    }
  } catch (error) {
    testReport['latency_errors'] = true;
  }

  return testReport;
};

const getLatencyTestReports = (testRegions: string[]) =>
  new Promise(async resolve => {
    const latencyTestReports = [];
    for (let i = 0; i < testRegions.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const latencyTest = await runLatencyTestForRegion(testRegions[i]);
      latencyTestReports.push(latencyTest);
    }
    resolve(latencyTestReports);
  });

const runDummyLatencyTestNTimes = (testRegions: string[], numTimes: number) =>
  Promise.all(
    [...Array(numTimes)].map(() => getLatencyTestReports(testRegions)),
  );

const getReportsForRegionsWithBestLatency = (
  latencyTestReports: Edge[],
  numberOfBestRegionsForSpeedTest: number,
) => {
  const filteredLatencyTestReportsWithoutLatencyErrors =
    latencyTestReports.filter(report => !report.latency_errors);

  const reportsForRegionsWithBestLatency = sortArrayOfObjectsByProperty(
    filteredLatencyTestReportsWithoutLatencyErrors,
    'latency',
  ).slice(0, numberOfBestRegionsForSpeedTest);

  return reportsForRegionsWithBestLatency;
};

const getEdgesForRegionsWithBestLatency = (
  reportsForRegionsWithBestLatency: Edge[],
  regions: ObjectOfAny,
) =>
  reportsForRegionsWithBestLatency
    .map(reportForRegionsWithBestLatency => {
      const edgesForRegion = regions[reportForRegionsWithBestLatency.dns];
      return edgesForRegion.map(edge => ({
        ...reportForRegionsWithBestLatency,
        dns: edge,
      }));
    })
    .flat();

const runBandwidthTestForFile = async (region: string, file: File) => {
  const url = `${PROTOCOL}//${region}${file.path}?id=${Date.now()}`;

  const startTime = performance.now();

  await getSpeedTestForFile(url);

  const finishedAt = performance.now();

  const navigationEntry =
    performance.getEntriesByName &&
    (performance.getEntriesByName(url)[0] as
      | PerformanceNavigationTiming
      | undefined);

  const timingsAllowed = !!(navigationEntry && navigationEntry?.requestStart); // requestStart is 0 if Timing-Allow-Origin is not set

  let roundTripTime = finishedAt - startTime;
  if (timingsAllowed) {
    roundTripTime =
      navigationEntry?.responseEnd - navigationEntry?.requestStart;
  }

  const durationInSeconds = roundTripTime / 1000;
  const bandwidth = file.size / (1024 / 8) / durationInSeconds; // in kbit/s

  return {
    [file.id]: Math.round(bandwidth),
  };
};

const runFileTestsForEdgeSequentially = async (files: File[], edge: Edge) => {
  const edgeTestReports = [];
  for (let i = 0; i < files.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const edgeTest = await runBandwidthTestForFile(edge.dns, files[i]);
    edgeTestReports.push(edgeTest);
  }
  return edgeTestReports;
};

const runBandwidthTestForEdge = async (edge: Edge, files: File[]) => {
  const testReport = {
    ...edge,
  };

  let bandWidthTimings = {};
  try {
    const bandWidthTimingsArray = await runFileTestsForEdgeSequentially(
      files,
      edge,
    );

    testReport['speed_errors'] = false;

    bandWidthTimings = flattenArrayOfObjects(bandWidthTimingsArray);
  } catch (error) {
    testReport['speed_errors'] = true;
  }

  return {
    ...testReport,
    ...bandWidthTimings,
  };
};

const getSpeedTestData = async (onError: OnErrorCallback) => {
  try {
    const speedTestRegionsResponse = await getSpeedTestRegions();
    return speedTestRegionsResponse;
  } catch (error) {
    onError(error);
  }
};

const runSpeedTest = async (userId: UserId, onError: OnErrorCallback) => {
  const speedTestData = await getSpeedTestData(onError);

  if (!speedTestData) {
    return false;
  }

  if (
    !speedTestData.hasOwnProperty('regions') ||
    !speedTestData.hasOwnProperty('config')
  ) {
    return false;
  }

  const {
    regions,
    config: {
      enabled,
      number_of_dummy_runs: numberOfDummyRuns,
      number_of_best_regions_for_speed_test: numberOfBestRegionsForSpeedTest,
      retest_every_seconds: retestEverySeconds,
      test_files: testFiles,
    },
  } = speedTestData;

  if (!enabled) {
    return false;
  }

  setSpeedTestCookie(retestEverySeconds);

  const regionsToTest = Object.keys(regions);

  await runDummyLatencyTestNTimes(regionsToTest, numberOfDummyRuns);

  const latencyTestReports = (await getLatencyTestReports(
    regionsToTest,
  )) as Edge[];

  const reportsForRegionsWithBestLatency = getReportsForRegionsWithBestLatency(
    latencyTestReports,
    numberOfBestRegionsForSpeedTest,
  );

  const edgesForRegionsWithBestLatency = getEdgesForRegionsWithBestLatency(
    reportsForRegionsWithBestLatency,
    regions,
  );

  const bandwidthTestReportsForRegionsWithBestLatency = [];
  for (let i = 0; i < edgesForRegionsWithBestLatency.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const bandWidthTest = await runBandwidthTestForEdge(
      edgesForRegionsWithBestLatency[i],
      testFiles,
    );
    bandwidthTestReportsForRegionsWithBestLatency.push(bandWidthTest);
  }

  const finalReport = {
    report: bandwidthTestReportsForRegionsWithBestLatency,
  };

  if (userId) {
    finalReport['user_id'] = userId;
  }

  try {
    await submitSpeedTestReport(finalReport);
  } catch (error) {
    onError(error, { finalReport });
  }

  return true;
};

export default runSpeedTest;
