import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

const InitialiseIllowBannerLanguage = () => {
  const { lang } = useTranslation();

  useEffect(() => {
    window.illow = window.illow || {};

    window.illow.customization = {
      settings: {
        lng: lang,
      },
    };
  }, []);

  return null;
};

export default InitialiseIllowBannerLanguage;
