/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { FilmGroup, FilmGroupId } from '@app/api/resources/FilmGroup';

import { arrayToObjectKeyedById } from '@app/services/utils';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  filmGroups: {},
  slugToIdLookup: {},
};

type SlugToIdLookup = { [key: string]: FilmGroupId };

export type FilmGroupState = {
  filmGroups: {
    [key: FilmGroupId]: FilmGroup;
  };
  slugToIdLookup: SlugToIdLookup;
};

export const filmGroup = (
  state: FilmGroupState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.ADD_FILM_GROUPS: {
        const { filmGroups }: { filmGroups: FilmGroup[] } = action.payload;

        const filmGroupsWithOrder = filmGroups.map((thisFilmGroup, i) =>
          Object.assign({}, thisFilmGroup, { order: i }),
        );
        const slugToIdLookup: SlugToIdLookup = {};
        filmGroups.forEach(thisFilmGroup => {
          slugToIdLookup[thisFilmGroup.slug] = thisFilmGroup.id;
        });

        draft.filmGroups = {
          ...draft.filmGroups,
          ...arrayToObjectKeyedById(filmGroupsWithOrder),
        };
        draft.slugToIdLookup = {
          ...draft.slugToIdLookup,
          ...slugToIdLookup,
        };

        break;
      }
      case actionTypes.ADD_FILM_GROUP: {
        const currentNumberOfFilmGroups = Object.values(
          state.filmGroups,
        ).length;
        draft.filmGroups[action.payload.filmGroup.id] = Object.assign(
          {},
          action.payload.filmGroup,
          { order: currentNumberOfFilmGroups },
        );
        draft.slugToIdLookup[action.payload.filmGroup.slug] =
          action.payload.filmGroup.id;
        break;
      }
      case actionTypes.CLEAR_ALL_FILM_GROUPS:
        draft.filmGroups = {};
        break;
    }
  });
