import styled from '@emotion/styled';

import ImageBase from '@app/components/ImageBase';

type OverrideCountryNoticeProps = {
  countryCode?: string;
  currentPath: string;
};

const OverrideCountryNotice = ({
  countryCode = null,
  currentPath,
}: OverrideCountryNoticeProps) => {
  const getPath = () => {
    const currentPathWithoutOverrideCountry = currentPath.split('?')[0];
    return `${currentPathWithoutOverrideCountry}?override_country=`;
  };

  return (
    countryCode && (
      <StyledLink href={getPath()}>
        Country: {countryCode}
        <StyledImage
          src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
          alt={countryCode}
        />
      </StyledLink>
    )
  );
};

export default OverrideCountryNotice;

const StyledLink = styled.a`
  position: fixed;
  bottom: 35px;
  right: -90px;
  z-index: 99999999;
  transform: rotate(-45deg);
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.color.yellow};
  color: ${props => props.theme.color.darkText};
  padding: 15px;
  width: 300px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled(ImageBase)`
  margin-left: 10px;
`;
