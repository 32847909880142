import { isClient } from '@app/services/utils';

export const isPointerDevice = (): boolean =>
  isClient()
    ? window.matchMedia('(hover: hover) and (pointer: fine)').matches
    : null;

export const isTouchDevice = (): boolean =>
  isClient()
    ? window.matchMedia('(hover: none), (pointer: coarse), (pointer: none)')
        .matches
    : null;

export const minimumSupportedBrowserVersion = {
  chrome: 107,
  firefox: 111,
  edge: 121,
  safari: 14,
};

export const isBrowserSupported = (browser: {
  version: string;
  name: string;
}): boolean => {
  if (!browser?.name || !browser?.version) {
    return null;
  }

  if (!Object.keys(minimumSupportedBrowserVersion).includes(browser.name)) {
    return null;
  }

  const currentBrowserMajorVersion = +browser.version.split('.')[0];

  if (!currentBrowserMajorVersion) {
    return null;
  }

  return (
    currentBrowserMajorVersion >= minimumSupportedBrowserVersion[browser.name]
  );
};
