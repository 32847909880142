import { useEffect } from 'react';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import { setClarityUserId } from '@app/services/initialisation';

const SetClarityUserId = () => {
  const user = useAppSelector(state => state.user?.user);
  useEffect(() => {
    if (user) {
      setClarityUserId(user);
    }
  }, [user]);
  return null;
};

export default SetClarityUserId;
