import { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { CookieConsent, persistUpdateUser } from '@app/api/resources/User';

import { UpdatedConsent } from '@app/api/services/illow';

import { ObjectOfStrings } from '@app/types/utility-types';

import {
  setLocalIllowCookieConsent,
  updateLoggedInUser,
} from '@app/actions/UserActions';

import useIsIllowInitialised from '@app/hooks/helpers/illow/useIsIllowInitialised';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const useUpdateCookieConsentOnIllowBannerInteraction = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const illowIsInitialised = useIsIllowInitialised();

  const {
    isAuthenticated,
    httpContext,
  }: {
    isAuthenticated: boolean;
    httpContext: ObjectOfStrings;
  } = useAppSelector(
    state => ({
      isAuthenticated: state.user.isAuthenticated,
      httpContext: state.appState.httpContext,
    }),
    shallowEqual,
  );

  const onConsentSet = (event: CustomEvent<UpdatedConsent>) => {
    const updatedConsent = event.detail;

    updateCookiePrefsAfterBannerInteraction({
      analytics: updatedConsent.statistics.value,
      marketing: updatedConsent.marketing.value,
    });
  };

  useEffect(() => {
    if (illowIsInitialised) {
      window.addEventListener('illow:consent_set', onConsentSet);
    }

    return () => {
      window.removeEventListener('illow:consent_set', onConsentSet);
    };
  }, [illowIsInitialised, router?.pathname]);

  const updateCookiePrefsAfterBannerInteraction = async (
    consent: CookieConsent,
  ) => {
    trackSnowplowClickEvent({
      data: {
        clickType: consent.analytics
          ? 'allow_analytics_cookies'
          : 'reject_analytics_cookies',
        element: 'cookie_banner',
      },
    });
    trackSnowplowClickEvent({
      data: {
        clickType: consent.marketing
          ? 'allow_marketing_cookies'
          : 'reject_marketing_cookies',
        element: 'cookie_banner',
      },
    });
    dispatch(setLocalIllowCookieConsent(consent));
    if (isAuthenticated) {
      try {
        const { data: user } = await persistUpdateUser(httpContext, {
          cookie_consent: consent,
        });
        dispatch(
          updateLoggedInUser({
            cookie_consent: user.cookie_consent,
          }),
        );
      } catch (error) {}
    }
  };
};

export default useUpdateCookieConsentOnIllowBannerInteraction;
