import { AnyAction } from 'redux';
import produce from 'immer';

import * as actionTypes from '@app/actionTypes';

export type RetrospectiveState = {
  hasPlayedFullAnimationSequence: boolean;
};

export const initialState: RetrospectiveState = {
  hasPlayedFullAnimationSequence: false,
};

const retrospective = (
  state: RetrospectiveState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_HAS_PLAYED_FULL_ANIMATION_SEQUENCE:
        draft.hasPlayedFullAnimationSequence =
          action.payload.hasPlayedFullAnimationSequence;
        break;
    }
  });

export default retrospective;
