import { browserVersion, isMobileSafari } from 'react-device-detect';

const codecMimeTypes = {
  h265: 'video/mp4;codecs="hvc1.2.4.L90.90"', // First preference
  vp9: 'video/mp4;codecs="vp09.00.30.08.00.01.02.01.00"', // Second preference
  h264: 'video/mp4;codecs="avc1.4D401E"', // Third preference
};

const audioCodecMimeTypes = {
  eac3: 'audio/mp4;codecs="ec-3"', // First preference
  aac: 'audio/mp4;codecs="mp4a.40.2"', // Second preference
};

const isMobileSafariGreaterThanVersion11 =
  isMobileSafari && parseInt(browserVersion, 10);

export const getSupportedVideoCodecHeader = () => {
  if ('MediaSource' in window) {
    const supportedCodecs = [];
    ['h265', 'vp9', 'h264'].forEach(codec => {
      if (MediaSource.isTypeSupported(codecMimeTypes[codec])) {
        supportedCodecs.push(codec);
      }
    });
    return supportedCodecs.join(',');
  }
  if (isMobileSafariGreaterThanVersion11) {
    return 'h265';
  }
  return 'h264';
};

export const getSupportedAudioCodecHeader = () => {
  if ('MediaSource' in window) {
    const supportedCodecs = [];
    ['eac3', 'aac'].forEach(codec => {
      if (MediaSource.isTypeSupported(audioCodecMimeTypes[codec])) {
        supportedCodecs.push(codec);
      }
    });
    return supportedCodecs.join(',');
  }
  return 'aac';
};

export const doesBrowserSupportH265 = () => {
  if (isMobileSafariGreaterThanVersion11) {
    return true;
  }

  if (
    'MediaSource' in window &&
    MediaSource.isTypeSupported(codecMimeTypes['h265'])
  ) {
    return true;
  }

  return false;
};
