import { v4 as uuidv4 } from 'uuid';
import { Card } from '@braze/web-sdk';

import { UserStateBanner } from '@app/api/resources/User';

import * as actionTypes from '@app/actionTypes';

import { BrazeBannerDetail } from '@app/components/banners/BrazeBanner';

export const setBrazeBanner = (
  brazeBannerDetails: BrazeBannerDetail,
  isPreview = false,
  card?: Card,
) => ({
  type: actionTypes.SET_BRAZE_BANNER,
  payload: {
    brazeBannerDetails,
    isPreview,
    card,
  },
});
export const clearBrazeBanner = () => ({
  type: actionTypes.CLEAR_BRAZE_BANNER,
});

export const setUserStateBanners = (userStateBanners: UserStateBanner[]) => ({
  type: actionTypes.SET_USER_STATE_BANNERS,
  payload: {
    userStateBanners,
  },
});

export const clearUserStateBanners = () => ({
  type: actionTypes.SET_USER_STATE_BANNERS,
  payload: {
    userStateBanners: [],
  },
});

export const setUserFeedbackBanner = (
  userFeedbackMessage: string,
  isError?: boolean,
) => ({
  type: actionTypes.SET_USER_FEEDBACK_BANNER,
  payload: {
    userFeedbackBanner: {
      message: userFeedbackMessage,
      isError,
      // So a new banner can reset the timeout even when the message is the same
      id: uuidv4(),
    },
  },
});
