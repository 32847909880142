import { css } from '@emotion/react';
import styled from '@emotion/styled';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import Link from '@app/components/Link';

const actionTypeColors = {
  primary: props => css`
    color: ${props.theme.color.white};
    background-color: ${props.theme.color.mubiBlue};
    border: 1px solid ${props.theme.color.mubiBlue};
    &:hover:not(:disabled) {
      color: ${props.theme.color.white};
      background-color: ${props.theme.color.mubiBlueHover};
      border-color: ${props.theme.color.mubiBlueHover};
      text-decoration: none;
    }
    &:active:not(:disabled) {
      color: ${props.theme.color.lightGray};
    }
    &:visited {
      color: ${props.theme.color.white};
    }
  `,
  secondary: props => css`
    color: ${props.theme.color.white};
    background-color: ${props.theme.color.lightText};
    transition: background-color 0.1s ease-in-out;
    border: 1px solid ${props.theme.color.lightText};
    &:hover:not(:disabled) {
      background-color: ${props.theme.color.darkGray};
      border-color: ${props.theme.color.darkGray};
      text-decoration: none;
      color: ${props.theme.color.white};
    }
    &:active:not(:disabled) {
      color: ${props.theme.color.lightGray};
    }
    &:visited {
      color: ${props.theme.color.white};
    }
  `,
  tertiary: props => css`
    color: ${props.theme.color.lightText};
    border: 1px solid ${props.theme.color.lightText};
    &:hover:not(:disabled) {
      color: ${props.theme.color.darkGray};
      border-color: ${props.theme.color.darkGray};
      text-decoration: none;
    }
    &:active:not(:disabled) {
      color: ${props.theme.color.lightGray};
    }
    &:visited {
      color: ${props.theme.color.lightText};
    }
  `,
  blank: () => {},
};

const sharedButtonStyles = props => css`
  font-family: ${props.theme.font.brand};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  ${actionTypeColors[props.actiontype](props)}
`;

const defaultDisabledStyle = css`
  :disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const StyledButton = styled(UnstyledButton)`
  ${sharedButtonStyles}
  ${props => !props.isLoading && defaultDisabledStyle}
`;

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  actiontype?: 'primary' | 'secondary' | 'tertiary' | 'blank';
};
export const StyledButtonLink = styled.a<AnchorProps>`
  ${sharedButtonStyles}
`;

export const StyledSpaButtonLink = styled(Link)<AnchorProps>`
  ${sharedButtonStyles}
`;

export const LoadingText = styled.div`
  opacity: 0;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
  height: min-content;
`;
