import HttpService from '@app/api/http-service';
import { Film } from '@app/api/resources/Film';
import { ObjectOfStrings } from '@app/api/utility-types';

export type SpecialPromoSplashPage = {
  vanity_path: string;
  partner_name: string;
  header: string;
  button: string;
  learn_more: string;
  logo: string;
  background_image: string;
  body: string;
  body_html: string;
  primary_color: string;
  button_hover_background_color: string;
  button_font_color: string;
  background_average_colour_hex: string;
  light_background: boolean;
  films: Film[];
};

export type Promo = {
  id: number;
  type: 'FixedDiscount' | 'Discount' | 'FreeTrial' | 'Vip' | 'Prepaid';
  campaign: string;
  plan_level: string;
  plan_period: string;
  discount_period?: number;
  disabled: boolean;
  free_trial_period: number;
  plain_text: string;
  special_promo_splash_page?: SpecialPromoSplashPage;
  code?: string;
  amount_saved?: string;
  standard_price?: string;
  discounted_price?: string;
  variant?:
    | 'two_month'
    | 'three_month'
    | 'four_month'
    | 'one_year'
    | 'one_month';
  discount_period_days?: number;
  discounted_total_price?: number | string;
};

export type PromoPromoted = {
  title: string;
  body: string;
  cta: string;
  url: string;
  type: string;
};

export type CancellationOffer = {
  type: 'extend_trial' | 'offer_discount' | 'grandfather';
  special_promo?: Promo;
  price?: string;
};

export const getPromo = (
  httpContext: ObjectOfStrings,
  vanityPath: string,
): Promise<{ data: Promo }> =>
  HttpService(httpContext).get(`/special_promos/${vanityPath}`);

export const getPromoByCampaign = (
  httpContext: ObjectOfStrings,
  campaign: string,
): Promise<{ data: Promo }> =>
  HttpService(httpContext).get(`/special_promos/${campaign}/by_campaign`);

export const getPromotedPromo = (
  httpContext: ObjectOfStrings,
): Promise<{ data: PromoPromoted }> =>
  HttpService(httpContext).get('/special_promos/promoted');

export const validatePromo = (
  httpContext: ObjectOfStrings,
  vanityPath: string,
  promoCode: string,
): Promise<{ status: number }> =>
  HttpService(httpContext).put(`/special_promos/${vanityPath}/validate`, {
    data: {
      code: promoCode,
    },
  });
