import { ImgHTMLAttributes } from 'react';

const ImageBase = ({
  src,
  alt,
  loading = 'lazy',
  ...rest
}: ImgHTMLAttributes<HTMLImageElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading, local-rules/no-styled-img-elements
  <img src={src} loading={loading} alt={alt} {...rest} />
);

export default ImageBase;
