import { useEffect } from 'react';

import { identifyUserInSentry } from '@app/services/sentry';
import { getSnowplowDuid } from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const SentrySetContext = () => {
  const user = useAppSelector(state => state.user?.user);
  useEffect(() => {
    identifyUserInSentry(user, getSnowplowDuid());
  }, []);
  return null;
};

export default SentrySetContext;
