import { SpeedTestReport } from '@app/speedtest/src/services';

const INFRA_API_BASE_URL = 'https://infrastructure.mubicdn.net/api/public/v1';

export const getSpeedTestRegions = async (): Promise<{
  regions: Record<string, string[]>;
  config: {
    enabled: boolean;
    number_of_dummy_runs: number;
    number_of_best_regions_for_speed_test: number;
    retest_every_seconds: number;
    test_files: { path: string; size: number; id: string }[];
  };
}> => {
  const response = await fetch(`${INFRA_API_BASE_URL}/regions`);
  let data = null;
  try {
    data = await response.json();
  } catch (error) {}

  return data;
};

export const getSpeedTestForRegion = (url: string) =>
  fetch(url).then(res => {
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    return res;
  });

export const getSpeedTestForFile = (url: string) =>
  fetch(url).then(res => {
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    return res;
  });

export const submitSpeedTestReport = (finalReport: SpeedTestReport) =>
  fetch(`${INFRA_API_BASE_URL}/speed_test`, {
    method: 'POST',
    body: JSON.stringify(finalReport),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    return res;
  });
