import { useEffect, useRef, useState } from 'react';

const useIsIllowInitialised = () => {
  const [illowIsInitialised, setIllowIsInitialised] = useState(false);
  const timeoutId = useRef(null);

  const waitForIllowToInitialise = () => {
    let illowConsentAccessible = false;
    if (window.illow && window.illow.getConsent) {
      illowConsentAccessible = !!window.illow?.getConsent();
    }

    if (window.illow && illowConsentAccessible) {
      setIllowIsInitialised(true);
    } else {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        waitForIllowToInitialise();
      }, 500);
    }
  };

  useEffect(() => {
    waitForIllowToInitialise();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return illowIsInitialised;
};

export default useIsIllowInitialised;
