import TagManager from 'react-gtm-module';

import { areMarketingCookiesAllowed } from '@app/services/cookie-consent';
import { isClient } from '@app/services/utils';

import { ObjectOfAny, ObjectOfStrings } from '@app/types/utility-types';

export const PAGE_VIEW_DWELL_TIME = 3000;

export const gtmViewContent = (dataToPush: ObjectOfStrings) => {
  if (weCanTrackToGTM()) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_content',
        ...dataToPush,
      },
    });
  }
};

export const gtmVirtualPageView = (
  customPagePath?: string,
  originalLocation?: string,
) => {
  if (weCanTrackToGTM()) {
    const event = { event: 'virtualPageView' } as ObjectOfStrings;
    if (customPagePath) {
      event.customPagePath = customPagePath;
    }
    if (originalLocation) {
      event.originalLocation = originalLocation;
    }

    TagManager.dataLayer({
      dataLayer: event,
    });
  }
};

const weCanTrackToGTM = () => isClient() && areMarketingCookiesAllowed();

export const gtmEvent = (dataToPush: ObjectOfAny, force = false) => {
  if (weCanTrackToGTM() || force) {
    TagManager.dataLayer({
      dataLayer: dataToPush,
    });
  }
};
