import { useEffect } from 'react';

import {
  isCookieLocalStorageSet,
  isLocalStorageEnabled,
  setLocalStorageCookie,
} from '@app/services/local-storage';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

const InitialiseLocalStorage = () => {
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  useEffect(() => {
    if (!isCookieLocalStorageSet()) {
      const isAllowed = isLocalStorageEnabled();
      setLocalStorageCookie(isAllowed);
      trackSnowplowClickEvent({
        data: {
          clickType: 'localStorageAvailable',
          element: isAllowed.toString(),
        },
      });
    }
  }, []);

  return null;
};

export default InitialiseLocalStorage;
