/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Meta } from '@app/api/pagination';
import {
  CastMember,
  CastMemberId,
  CastMemberSlug,
  getSlugFromCanonicalUrl,
} from '@app/api/resources/CastMember';
import { FilmId } from '@app/api/resources/Film';

import { hasOwnProperty } from '@app/services/utils';

import * as actionTypes from '@app/actionTypes';

/**
 * eg shape for store:
 * 
 * castMembers: {
    764674654: {
      castMember: {}, // cast member resource
      nowShowingFilms: {
        filmIds: [],
        totalCount: [],
      },
      relatedFilmIdsByCreditType: {
        actor: {
          pages: {
            1:  {
              filmIds: [1, 2, 3],
              meta: {},
            },
            2: {
              filmIds: [4, 5, 6],
              meta: {},
            },
          },
        },
      },
    },
  },
  slugtoIdLookup: {
    'frances-mcdormand': 764674654,
  },
 * 
 */

export const initialState = {
  castMembers: {},
  slugToIdLookup: {},
};

type CastNowShowingFilms = {
  filmIds: FilmId[];
  totalCount: number;
};
type RelatedFilmIdsByCreditType = {
  [key: string]: {
    pages: {
      [key: number]: {
        filmIds: FilmId[];
        meta: Meta;
      };
    };
  };
};

export type StoredCastMember = {
  castMember: CastMember;
  relatedFilmIdsByCreditType?: RelatedFilmIdsByCreditType;
  nowShowingFilms?: CastNowShowingFilms;
};

export type CastMembersState = {
  castMembers: {
    [key: CastMemberId]: StoredCastMember;
  };
  slugToIdLookup: {
    [key: CastMemberSlug]: CastMemberId;
  };
};

export const castMembers = (
  state: CastMembersState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.ADD_CAST_MEMBER: {
        const { castMember } = action.payload;
        draft.castMembers[castMember.id] = {
          castMember,
          relatedFilmIdsByCreditType: {},
        };
        draft.slugToIdLookup[getSlugFromCanonicalUrl(castMember)] =
          castMember.id;
        break;
      }
      case actionTypes.ADD_CAST_MEMBERS_NOW_SHOWING_FILM_IDS: {
        const { nowShowingFilmIds, castMemberId, totalCount } = action.payload;

        if (!draft.castMembers[castMemberId]?.nowShowingFilms) {
          draft.castMembers[castMemberId].nowShowingFilms =
            {} as CastNowShowingFilms;
        }
        draft.castMembers[castMemberId].nowShowingFilms.filmIds =
          nowShowingFilmIds;
        draft.castMembers[castMemberId].nowShowingFilms.totalCount = totalCount;

        break;
      }
      case actionTypes.ADD_CAST_MEMBERS_FILMS: {
        const { pageOfCastMembersFilmIds, pageMeta, creditType, castMemberId } =
          action.payload;

        const castMembersRelatedFilms =
          draft.castMembers?.[castMemberId]?.relatedFilmIdsByCreditType;

        // Only add film if cast member exists
        if (castMembersRelatedFilms) {
          const pageToAdd = {
            filmIds: pageOfCastMembersFilmIds,
            meta: pageMeta,
          };
          if (hasOwnProperty(castMembersRelatedFilms, creditType)) {
            castMembersRelatedFilms[creditType].pages[
              pageToAdd.meta.current_page
            ] = pageToAdd;
          } else {
            castMembersRelatedFilms[creditType] = {
              pages: {
                [pageToAdd.meta.current_page]: pageToAdd,
              },
            };
          }
        }
        break;
      }
      case actionTypes.CLEAR_ALL_CAST_MEMBERS_FILMS_FOR_CREDIT: {
        const { creditType, castMemberId } = action.payload;

        const castMembersRelatedFilms =
          draft.castMembers?.[castMemberId]?.relatedFilmIdsByCreditType;

        if (castMembersRelatedFilms) {
          delete castMembersRelatedFilms[creditType];
        }
        break;
      }
    }
  });
