import HttpService from '@app/api/http-service';
import { Promo } from '@app/api/resources/Promo';

import { ObjectOfStrings } from '@app/types/utility-types';

export type PromoBanner = {
  banner_text: string;
  banner_prompt: string;
};

type MubiGoConfig = {
  cinema: {
    id: number;
    name: string;
    latitude: string;
    longitude: string;
    time_zone: string;
    address: string;
    url: string;
    city: string;
  };
  date_available: string;
  week_start_day: string;
};

export type AppConfig = {
  country: string;
  country_full_time_zone: string;
  country_acceptable_reel_languages: string[];
  promo: ClientPromo;
  onboarding_offer?: {
    banner_text: string;
    banner_prompt: string;
    ends_on: string;
    offer_ends: string;
    special_promo: Promo;
  };
  activation_url: string;
  mubi_go: MubiGoConfig;
  viewing_tracking_interval: number;
  sentry: {
    dsn: string;
    enabled: boolean;
    sample_rate: number;
  };
  onboarding: SplashAssets;
  content_warning_preference_enabled: boolean;
  promote_premium: boolean;
  trials_enabled: boolean;
  mubi_go_enabled_country: boolean;
};

export type ResponsiveSplashAssetUrls = {
  mobile_url: string;
  tablet_url: string;
  desktop_url: string;
};

export type SplashAssets = {
  video: {
    mobile_url: string;
    tablet_url: string;
    desktop_url: string;
    h264?: {
      mobile_url: string;
      tablet_url: string;
      desktop_url: string;
    };
    h265?: {
      mobile_url: string;
      tablet_url: string;
      desktop_url: string;
    };
  };
  still: {
    mobile_url: string;
    tablet_url: string;
    desktop_url: string;
  };
};

export type AppStartUp = {
  country: string;
  server_time: string;
  fb_app_id: string;
  promo: Promo;
  client_activation_url: string;
  pitch_store_rating: boolean;
};

// Promos related to the user's client
// eg, Sony or Samsung (WebTV and Android clients only)
// @see: ma/app/strategies/client_promotion_strategy.rb
type ClientPromo = {
  id: number;
  plan_period: string;
  plan_level: string;
  code: string;
  disabled: boolean;
  type: string;
  free_trial_period: number;
  plain_text: string;
};

export const getAppConfig = (
  httpContext: ObjectOfStrings,
  serverCtx = null,
): Promise<{
  data: AppConfig;
}> => HttpService(httpContext, serverCtx).get('/app_config');

export type AssetUrls = {
  mobile_url: string;
  tablet_url: string;
  desktop_url: string;
};

export const getSplashAsset = (
  httpContext: ObjectOfStrings,
  splashAssetId: number,
): Promise<{
  data: SplashAssets;
}> => HttpService(httpContext).get(`/splash_asset_sets/${splashAssetId}`);
