import { AnyAction } from 'redux';

import { NotebookCover } from '@app/api/resources/notebook/NotebookCover';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  notebookCover: null,
};

export type NotebookCoverState = {
  notebookCover: NotebookCover;
};

export const notebookCover = (
  state: NotebookCoverState = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case actionTypes.SET_NOTEBOOK_COVER:
      return Object.assign({}, state, {
        notebookCover: action.payload.notebookCover,
      });
    default:
      return state;
  }
};
