/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { FilmId } from '@app/api/resources/Film';
import { FilmViewing } from '@app/api/resources/FilmViewing';

import { arrayToObjectKeyedById } from '@app/services/utils';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  usersFilmViewingsByFilmId: {},
};

export type UsersFilmViewingsState = {
  usersFilmViewingsByFilmId: {
    [key: FilmId]: FilmViewing;
  };
};

const viewings = (
  state: UsersFilmViewingsState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_USERS_FILM_VIEWINGS: {
        const { userFilmViewings } = action.payload;

        draft.usersFilmViewingsByFilmId = arrayToObjectKeyedById(
          userFilmViewings,
          'film_id',
        );
        break;
      }
      case actionTypes.SET_USER_FILM_VIEWING: {
        const { userFilmViewing } = action.payload;

        draft.usersFilmViewingsByFilmId[userFilmViewing?.film_id] =
          userFilmViewing;
        break;
      }
    }
  });

export default viewings;
