import { AnyAction } from 'redux';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';

import { Meta, MetaCursor, PageNumber } from '@app/api/pagination';
import { SmallFanship } from '@app/api/resources/Fanship';
import {
  formatOldListFromResponse,
  List,
  ListFilm,
  ListId,
  OldList,
} from '@app/api/resources/List';

import * as actionTypes from '@app/actionTypes';

export const initialState = {
  usersLists: [],
  lists: {},
  listSlugToId: {},
  pagesOfListFilmsByList: {},
  pagesOfFollowersByList: {},
};

export type ListState = {
  usersLists: OldList[];
  lists: {
    [key: ListId]: List;
  };
  listSlugToId: {
    [key: string]: ListId;
  };
  pagesOfListFilmsByList: {
    [key: ListId]: {
      [key: PageNumber]: {
        listFilms: ListFilm[];
        meta: Meta;
      };
    };
  };
  pagesOfFollowersByList: {
    [key: ListId]: {
      [key: PageNumber]: {
        fanships: SmallFanship[];
        meta: MetaCursor;
      };
    };
  };
};

export const list = (state: ListState = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.ADD_PAGE_OF_LIST_FILMS: {
        const pagesOfListFilms =
          state.pagesOfListFilmsByList[action.payload.listId] || {};
        draft.pagesOfListFilmsByList[action.payload.listId] = {
          ...pagesOfListFilms,
          [action.payload.pageNum]: action.payload.pageOfListFilms,
        };
        break;
      }
      case actionTypes.DELETE_PAGES_OF_LIST_FILMS: {
        delete draft.pagesOfListFilmsByList[action.payload.listId];
        break;
      }
      case actionTypes.ADD_PAGE_OF_LIST_FOLLOWERS: {
        const pagesOfFollowers =
          state.pagesOfFollowersByList[action.payload.listId] || {};
        draft.pagesOfFollowersByList[action.payload.listId] = {
          ...pagesOfFollowers,
          [action.payload.pageNum]: action.payload.pageOfFollowers,
        };
        break;
      }
      case actionTypes.ADD_LIST: {
        draft.lists[action.payload.list.id] = action.payload.list;
        draft.listSlugToId[action.payload.list.slug] = action.payload.list.id;
        break;
      }
      case actionTypes.SET_USERS_LISTS: {
        const formattedUserLists = action.payload.usersLists.map(usersList =>
          formatOldListFromResponse(usersList),
        );
        draft.usersLists = formattedUserLists;
        break;
      }
      case actionTypes.ADD_USERS_LIST: {
        draft.usersLists.push(
          formatOldListFromResponse(action.payload.usersList),
        );
        break;
      }
      case actionTypes.DELETE_USERS_LIST: {
        draft.usersLists = state.usersLists.filter(
          usersList => usersList.id !== action.payload.usersListsId,
        );
        delete draft.lists[action.payload.usersListsId];
        break;
      }
      case actionTypes.ADD_ITEM_TO_USERS_LIST: {
        const { listId, listItemId, filmId } = action.payload;

        const userListExists = !!draft.usersLists.find(
          usersList => usersList.id === listId,
        );

        if (userListExists) {
          draft.usersLists = state.usersLists.map(usersList => {
            const usersListCopy = cloneDeep(usersList);
            if (usersListCopy.id === listId) {
              const listAlreadyHasFilm = usersListCopy.list_films.find(
                listFilm => listFilm.filmId === filmId,
              );

              if (!listAlreadyHasFilm) {
                usersListCopy.list_films.push({
                  filmId,
                  listItemId,
                });
              }
            }
            return usersListCopy;
          });
        }
        break;
      }
      case actionTypes.DELETE_ITEM_FROM_USERS_LISTS: {
        const { listId, filmId } = action.payload;
        const userListExists = !!draft.usersLists.find(
          usersList => usersList.id === listId,
        );
        if (userListExists) {
          draft.usersLists = state.usersLists.map(usersList => {
            const usersListCopy = cloneDeep(usersList);
            if (usersListCopy.id === listId) {
              usersListCopy.list_films = usersList.list_films.filter(
                listFilm => listFilm.filmId !== filmId,
              );
            }
            return usersListCopy;
          });
        }
        break;
      }
      case actionTypes.UPDATE_USERS_LIST: {
        const userListIndex = state.usersLists.findIndex(
          usersList => usersList.id === action.payload.id,
        );
        draft.usersLists[userListIndex] = {
          ...state.usersLists[userListIndex],
          title: action.payload.title,
          // todo: add films eg. list_films: action.payload.list_films
        };
        draft.lists[action.payload.id] = action.payload;
        break;
      }
    }
  });
