import { Store } from 'redux';
import AcceptLanguageParser from 'accept-language-parser';
import { ServerResponse } from 'http';
import isbot from 'isbot';
import nookies, { destroyCookie, parseCookies, setCookie } from 'nookies';
import * as Sentry from '@sentry/nextjs';

import {
  getUpcomingIssueEntitlements,
  getUsersMagazineSubscription,
} from '@app/api/resources/notebook/NotebookMagazine';
import { getPromotedPromo } from '@app/api/resources/Promo';
import {
  getSubscriptionPlans,
  getSubscriptionPlansForUser,
  SubscriptionPlanBase,
  SubscriptionPlans,
} from '@app/api/resources/SubscriptionPlans';
import {
  CurrentUser,
  MessageType,
  userStartUpInit,
} from '@app/api/resources/User';

import { LocalePath, supportedLocalesPaths } from '@app/api/services/language';
import { isValidCountryCode } from '@app/services/countries';
import { ONE_HOUR_IN_SECONDS } from '@app/services/date-utils';
import {
  checkLoginTokenIsPresent,
  checkNoRedirectParamIsPresent,
} from '@app/services/page-initialisation/app';
import {
  checkPathnameArrayIncludesPathname,
  doRedirect,
  doServerRedirect,
  getCountryCodeFromPath,
  getFullRouteUrl,
  removeLanguageAndCountryPrefixFromPath,
  shouldRedirectToAddCountryPrefix,
  shouldRedirectToRemoveCountryPrefix,
  shouldRedirectToSwapCountryPrefix,
} from '@app/services/routeHelpers';
import { checkIsNewStartupSession } from '@app/services/utils';

import { Context, CustomContext } from '@app/types/common';
import { ObjectOfStrings } from '@app/types/utility-types';

import { disableTrials, setPromoPromoted } from '@app/actions/AppStateActions';
import {
  setAcceptedLanguages,
  setMagazineSubStatus,
  setSubscriptionPlans,
  setSubscriptionPlansForUser,
} from '@app/actions/UserActions';
import { RootState } from '@app/reducers';

import { getI18nUrlDataFromState } from '@app/hooks/helpers/useI18nUrlData';

const APP_STARTUP_SESSION = 'app_startup_session';

export const redirectIfUserNotAnAdminInProduction = (
  isServer: boolean,
  res: ServerResponse,
  store: Store<RootState>,
) => {
  if (process.env.MUBI_ENV === 'production') {
    const currentState = store.getState();
    const isCurrentUserAnAdmin = currentState?.user?.isAdmin;
    if (!isCurrentUserAnAdmin) {
      doRedirect(
        isServer,
        getFullRouteUrl({
          url: '/showing',
          i18nUrlData: getI18nUrlDataFromState(currentState),
          includeDomain: false,
        }),
        res,
      );
      return true;
    }
  }
  return false;
};

export const redirectToNowShowing = (
  ctx: Context,
  warning?: string,
  warningType?: MessageType,
) => {
  const { store, query } = ctx;
  const currentState = store.getState();
  const queryParams = { ...query } as Record<string, string>;
  if (queryParams['vanityPath']) {
    delete queryParams['vanityPath'];
  }
  return redirectToPath(
    getFullRouteUrl({
      url: '/showing',
      i18nUrlData: getI18nUrlDataFromState(currentState),
      queryParams,
    }),
    ctx,
    warning,
    warningType,
  );
};

export const redirectToLogin = async (ctx: Context) => {
  const { store } = ctx;
  const currentState = store.getState();

  return redirectToPath(
    getFullRouteUrl({
      url: `/login?return_to=${encodeURIComponent(ctx.asPath)}`,
      i18nUrlData: getI18nUrlDataFromState(currentState),
    }),
    ctx,
  );
};

export const redirectToPath = (
  pathWithI18nPrefix: string,
  ctx: Context,
  warning?: string,
  warningType: MessageType = 'notice',
) => {
  const { isServer, res } = ctx;
  if (warning) {
    setMessageCookie(warning, warningType, ctx);
  }

  doRedirect(isServer, pathWithI18nPrefix, res);
};

export const setMessageCookie = (
  message: string,
  messageType: MessageType = 'feedback',
  ctx?: Context,
) => {
  const cookieValue = JSON.stringify({
    [messageType]: message,
  });
  setCookie(ctx, 'flash_store', cookieValue, {
    maxAge: ONE_HOUR_IN_SECONDS,
    path: '/',
  });
};

export const keySubscriptionPlans = (
  subscriptionPlans: SubscriptionPlanBase[],
) => {
  const plans = {} as SubscriptionPlans;
  subscriptionPlans.forEach(plan => {
    plans[plan.selection_key] = plan;
  });
  return plans;
};

export const initialiseSubscriptionPlans = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
) => {
  const subscriptionPlansResponse = await getSubscriptionPlans(httpContext);
  const subscriptionPlans = keySubscriptionPlans(
    subscriptionPlansResponse.data,
  );

  const allSubscriptionPlansOtherThanStudent =
    subscriptionPlansResponse?.data?.filter(
      subscriptionPlan =>
        !subscriptionPlan?.selection_key?.endsWith('_student'),
    ) || [];

  const doAllMembershipsHaveZeroTrialDays =
    !allSubscriptionPlansOtherThanStudent.find(
      subscriptionPlan => subscriptionPlan.trial_days !== 0,
    );

  if (doAllMembershipsHaveZeroTrialDays) {
    store.dispatch(disableTrials());
  }

  store.dispatch(setSubscriptionPlans(subscriptionPlans));
};

export const initialiseSubscriptionPlansForUser = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
  user: CurrentUser,
) => {
  if (user) {
    const subscriptionPlansForUserResponse = await getSubscriptionPlansForUser(
      httpContext,
    );
    const subscriptionPlansForUser = keySubscriptionPlans(
      subscriptionPlansForUserResponse.data,
    );
    store.dispatch(setSubscriptionPlansForUser(subscriptionPlansForUser));
  }
};

export const initialiseAcceptedLanguages = (
  store: Store<RootState>,
  acceptLanguageHeader: string,
) => {
  store.dispatch(setAcceptedLanguages(acceptLanguageHeader));
};

const isLanguageAcceptedByBrowser = (
  language: string,
  browserAcceptedLanguages: string,
) =>
  !!AcceptLanguageParser.pick([language], browserAcceptedLanguages, {
    loose: true,
  });

export const getMostAcceptableBrowserLanguageString = (
  browserAcceptedLanguages: string,
) =>
  AcceptLanguageParser.pick(supportedLocalesPaths, browserAcceptedLanguages, {
    loose: true,
  });

const shouldRedirectForBrowserLanguageSetting = (
  user: CurrentUser,
  languageFromPath: string,
  browserAcceptedLanguages: string,
  pathname: string,
): string => {
  // If the user isn't logged in and we're on the splash or promo page
  if (
    !user &&
    checkPathnameArrayIncludesPathname(
      ['/', '/promos/[promoVanityPath]', '/[vanityPath]'],
      pathname,
    )
  ) {
    if (
      isLanguageAcceptedByBrowser(languageFromPath, browserAcceptedLanguages)
    ) {
      // Allow any language the browser accepts
      return null;
    }

    const mostAcceptableBrowserLanguageString =
      getMostAcceptableBrowserLanguageString(browserAcceptedLanguages);

    if (mostAcceptableBrowserLanguageString && languageFromPath) {
      if (mostAcceptableBrowserLanguageString !== languageFromPath) {
        return mostAcceptableBrowserLanguageString;
      }
    }
  }
  return null;
};

const shouldDoUserLanguageRedirect = (
  user: CurrentUser,
  languageFromPath: string,
  browserAcceptedLanguages: string,
  pathname: string,
  userAgent: string,
): string => {
  if (!isbot(userAgent)) {
    const redirectToBrowserLanguageSettingUrl =
      shouldRedirectForBrowserLanguageSetting(
        user,
        languageFromPath,
        browserAcceptedLanguages,
        pathname,
      );

    if (redirectToBrowserLanguageSettingUrl) {
      return redirectToBrowserLanguageSettingUrl;
    }
  }

  return null;
};

export const initialiseMagazineSubscriptionStatus = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
) => {
  const loginTokenCookiePresent = httpContext.Authorization;
  if (loginTokenCookiePresent) {
    const [{ data: magazineSubscription }, { data: issueEntitlements }] =
      await Promise.all([
        getUsersMagazineSubscription(httpContext),
        getUpcomingIssueEntitlements(httpContext),
      ]);

    store.dispatch(
      setMagazineSubStatus(
        magazineSubscription?.magazine_subscription_status?.status,
        issueEntitlements,
      ),
    );
  }
};

export const initialisePromotedPromo = async (
  httpContext: ObjectOfStrings,
  store: Store<RootState>,
) => {
  try {
    const promoted = await getPromotedPromo(httpContext);
    if (promoted.data) {
      store.dispatch(setPromoPromoted(promoted.data));
    }
  } catch (error) {
    // Fail silently
  }
};

const registerAppStartupCookieSession = async () => {
  setCookie(null, APP_STARTUP_SESSION, String(new Date()), {
    path: '/',
  });
};

// 120 => :new_version_available_error
// 121 => :new_version_available_warning
// 125 => :unsupported_device_error
// 126 => :device_warnings_error
const appStartupErrors = [120, 121, 125, 126];

const registerAppStartupSession = async (httpContext: ObjectOfStrings) => {
  /*
    Calling this API helps keep various information we have about the user up-to-date in the backend tables,
    including their last IP address, location and last seen at value.
  */
  try {
    await userStartUpInit(httpContext);
  } catch (error) {
    const code = error?.code;
    if (appStartupErrors.includes(code)) {
      // ignore
    } else {
      Sentry.captureException(error);
    }
  }
};

export const checkStartupSession = async (httpContext: ObjectOfStrings) => {
  const cookies = await parseCookies();
  const lastDateCookie = cookies[APP_STARTUP_SESSION];

  if (lastDateCookie) {
    if (checkIsNewStartupSession(lastDateCookie)) {
      await registerAppStartupSession(httpContext);
    }
    registerAppStartupCookieSession();
  } else {
    registerAppStartupCookieSession();
    await registerAppStartupSession(httpContext);
  }
};

export const setClarityUserId = (user: CurrentUser) => {
  if (process.env.MUBI_ENV === 'production' && window.clarity) {
    window.clarity('identify', user.email, user.id, null, user.email);
  }
};

export const getLanguageToRedirect = (
  ctx: CustomContext,
  user: CurrentUser,
) => {
  const mostAcceptableBrowserLanguage =
    getMostAcceptableBrowserLanguageString(
      ctx.req.headers['accept-language'],
    ) || 'en';

  const loginTokenIsPresent = checkLoginTokenIsPresent(ctx);
  const noRedirectParamPresent = checkNoRedirectParamIsPresent(ctx);
  if (noRedirectParamPresent || loginTokenIsPresent) {
    return ctx.locale === 'default'
      ? (mostAcceptableBrowserLanguage as LocalePath)
      : false;
  }

  const languageRedirect = shouldDoUserLanguageRedirect(
    user,
    ctx.locale,
    ctx.req.headers['accept-language'],
    ctx.pathname,
    ctx.req.headers['user-agent'],
  );

  if (languageRedirect) {
    return languageRedirect;
  }

  if (ctx.locale === 'default') {
    return mostAcceptableBrowserLanguage as LocalePath;
  }

  return false;
};

const addRedirectingCountryCookie = (ctx: CustomContext) => {
  setCookie(ctx, 'redirectingCountry', 'true', {
    path: '/',
  });
};

export const getCountryToRedirect = async (
  ctx: CustomContext,
  countryUserLocatedIn: string,
) => {
  const { req, asPath, isServer } = ctx;

  // STEP 3.1: Check override_country is set, if so use this country instead geoIpCountry.
  const cookies = nookies.get(ctx);
  let countryToRedirect: string | boolean = false;

  if (isValidCountryCode(cookies?.override_country)) {
    countryToRedirect = cookies?.override_country?.toLocaleLowerCase();
  }
  // First check if we should redirect to add country prefix or swap country prefix if not, we don't need to check geoIp.
  const shouldCheckIfAddCountryRedirectNeeded =
    shouldRedirectToAddCountryPrefix(req?.url || asPath);
  const shouldCheckIfCountrySwapRedirectNeeded =
    shouldRedirectToSwapCountryPrefix(
      req?.url || asPath,
      cookies?.redirectingCountry,
    );

  // Always clear redirectingCountry cookie.
  destroyCookie(ctx, 'redirectingCountry', {
    path: '/',
  });

  // STEP 3.2: Get country to redirect from geoIp lib - false if no resolved.
  if (
    !countryToRedirect &&
    (shouldCheckIfAddCountryRedirectNeeded ||
      shouldCheckIfCountrySwapRedirectNeeded)
  ) {
    const countryInPath = getCountryCodeFromPath(asPath, isServer);
    if (countryUserLocatedIn && countryUserLocatedIn !== countryInPath) {
      countryToRedirect = countryUserLocatedIn;
    }
  }

  // STEP 3.3: Url doesn't include country but it should have it, redirect to add country
  if (shouldCheckIfAddCountryRedirectNeeded) {
    if (countryToRedirect) {
      addRedirectingCountryCookie(ctx);
      return countryToRedirect;
    }
    const countryFromStore = ctx.store
      .getState()
      .user?.geoLocation?.toLocaleLowerCase();

    if (isValidCountryCode(countryFromStore)) {
      addRedirectingCountryCookie(ctx);
      return countryFromStore;
    }

    return false;
  }

  // STEP 3.4: Url includes country and it should have it, redirect to swap country if the one in the url doesn't match with geoIpCountry
  if (shouldCheckIfCountrySwapRedirectNeeded) {
    if (countryToRedirect) {
      addRedirectingCountryCookie(ctx);
      return countryToRedirect;
    }
    return false;
  }

  return false;
};

export const getPathWithoutCountryPrefixForRedirect = (ctx: CustomContext) => {
  const pathWithCountryPrefix = ctx.asPath.split('?')[0];

  let pathWithoutCountryPrefixForRedirect =
    removeLanguageAndCountryPrefixFromPath(pathWithCountryPrefix);

  if (pathWithoutCountryPrefixForRedirect === '/') {
    const loginTokenIsPresent = checkLoginTokenIsPresent(ctx);
    if (loginTokenIsPresent) {
      // We must redirect to /showing if logged in and on the splash page
      pathWithoutCountryPrefixForRedirect = '/showing';
    }
  }
  return pathWithoutCountryPrefixForRedirect;
};

export const shouldRedirectI18nAlgorithm = async (
  countryUserLocatedIn: string,
  ctx: CustomContext,
  user: CurrentUser,
) => {
  const { req, res, asPath } = ctx;
  const userAgent = req?.headers['user-agent'];
  const isServer = !!req;

  // STEP 1: Get language to redirect - false if no need to redirect.
  const languageRedirect: string | boolean = getLanguageToRedirect(ctx, user);

  const allowCountryRedirect = process.env.CI || !isbot(userAgent);

  if (allowCountryRedirect) {
    // STEP 2: Remove country and redirect if url include country but shouldn't have country.
    if (shouldRedirectToRemoveCountryPrefix(req?.url || asPath)) {
      const pathWithoutCountryPrefixForRedirect =
        getPathWithoutCountryPrefixForRedirect(ctx);

      const queryParams = asPath.split('?')[1];
      doRedirect(
        isServer,
        `${
          languageRedirect ? `/${languageRedirect}` : `/${ctx.locale}`
        }${pathWithoutCountryPrefixForRedirect}${
          queryParams ? `?${queryParams}` : ''
        }`,
        res,
      );
      return true;
    }

    // STEP 3: Get country to redirect - false if no need to redirect.
    const countryRedirect: string | boolean = await getCountryToRedirect(
      ctx,
      countryUserLocatedIn.toLowerCase(),
    );

    // STEP 4: If country returned then redirect including the languageRedirect if exists.
    if (typeof countryRedirect === 'string') {
      const pathWithoutCountryPrefixForRedirect =
        getPathWithoutCountryPrefixForRedirect(ctx);

      const queryParams = asPath.split('?')[1];
      doRedirect(
        isServer,
        `${
          languageRedirect ? `/${languageRedirect}` : `/${ctx.locale}`
        }/${countryRedirect}${pathWithoutCountryPrefixForRedirect}${
          queryParams ? `?${queryParams}` : ''
        }`,
        res,
      );

      return true;
    }
  }

  // STEP 5: No country redirect but redirect if languageRedirect returned.
  if (typeof languageRedirect === 'string') {
    const pathWithoutCountryPrefixForRedirect =
      getPathWithoutCountryPrefixForRedirect(ctx);

    const queryParams = asPath.split('?')[1];
    doServerRedirect(
      res,
      `/${languageRedirect}${pathWithoutCountryPrefixForRedirect}${
        queryParams ? `?${queryParams}` : ''
      }`,
    );
    return true;
  }

  return false;
};
