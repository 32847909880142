import { combineReducers } from 'redux';

import { AppState, appState } from '@app/reducers/AppStateReducer';
import { banners, BannersState } from '@app/reducers/BannerReducer';
import {
  castMembers,
  CastMembersState,
} from '@app/reducers/CastMembersReducer';
import comments, { CommentsState } from '@app/reducers/CommentsReducer';
import {
  criticReviews,
  CriticReviewsState,
} from '@app/reducers/CriticReviewsReducer';
import experiments, {
  ExperimentsState,
} from '@app/reducers/ExperimentsReducer';
import { favorite, FavoriteState } from '@app/reducers/FavoriteReducer';
import {
  filmGroupHighlight,
  FilmGroupHighlightState,
} from '@app/reducers/FilmGroupHighlightsReducer';
import filmGroupSpotlights, {
  FilmGroupSpotlightState,
} from '@app/reducers/FilmGroupSpotlightsReducer';
import { filmGroup, FilmGroupState } from '@app/reducers/FilmGroupsReducer';
import filmHighlight, {
  FilmHighlightState,
} from '@app/reducers/FilmHighlightsReducer';
import { film, FilmState } from '@app/reducers/FilmReducer';
import filmTile, { FilmTileState } from '@app/reducers/FilmTileReducer';
import viewings, {
  UsersFilmViewingsState,
} from '@app/reducers/FilmViewingsReducer';
import { followings, FollowingsState } from '@app/reducers/FollowingsReducer';
import { list, ListState } from '@app/reducers/ListReducer';
import {
  notebookCover,
  NotebookCoverState,
} from '@app/reducers/notebook/NotebookCoverReducer';
import {
  notebookPost,
  NotebookPostState,
} from '@app/reducers/notebook/NotebookPostReducer';
import {
  notification,
  NotificationState,
} from '@app/reducers/NotificationReducer';
import {
  paymentGateway,
  PaymentGatewayState,
} from '@app/reducers/PaymentGatewayReducers';
import {
  playbackLanguages,
  PlaybackLanguagesState,
} from '@app/reducers/PlaybackLanguagesReducer';
import retrospective, {
  RetrospectiveState,
} from '@app/reducers/RetrospectiveReducer';
import todaySections, {
  TodaySectionsState,
} from '@app/reducers/TodaySectionsReducer';
import { user, UserState } from '@app/reducers/UserReducer';

export type RootState = {
  appState: AppState;
  user: UserState;
  filmTile: FilmTileState;
  todaySections: TodaySectionsState;
  list: ListState;
  followings: FollowingsState;
  favorite: FavoriteState;
  film: FilmState;
  playbackLanguages: PlaybackLanguagesState;
  notebookCover: NotebookCoverState;
  notebookPost: NotebookPostState;
  notification: NotificationState;
  filmGroup: FilmGroupState;
  filmGroupHighlight: FilmGroupHighlightState;
  criticReviews: CriticReviewsState;
  castMembers: CastMembersState;
  comments: CommentsState;
  viewings: UsersFilmViewingsState;
  filmHighlight: FilmHighlightState;
  filmGroupSpotlights: FilmGroupSpotlightState;
  banners: BannersState;
  paymentGateway: PaymentGatewayState;
  experiments: ExperimentsState;
  retrospective: RetrospectiveState;
};

export default combineReducers({
  appState,
  user,
  filmTile,
  todaySections,
  list,
  followings,
  favorite,
  film,
  playbackLanguages,
  notebookCover,
  notebookPost,
  notification,
  filmGroup,
  filmGroupHighlight,
  criticReviews,
  castMembers,
  comments,
  viewings,
  filmHighlight,
  filmGroupSpotlights,
  banners,
  paymentGateway,
  experiments,
  retrospective,
});
