import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  isAfter,
  isBefore,
  parseISO,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { formatDate } from '@app/services/date-format';

export const ONE_MINUTE_MS = 60 * 1000;

export const ONE_HOUR_AS_FRACTION_OF_DAY = 1 / 24;
export const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
export const ONE_HOUR_IN_SECONDS = 60 * 60;

export const SIXTY_DAYS_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24 * 60;
export const ONE_YEAR_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24 * 365;

export const THIRTY_DAYS = 30;
export const ONE_YEAR_IN_DAYS = 365;
export const TWO_YEARS_IN_DAYS = 365 * 2;
export const THREE_YEARS_IN_DAYS = 365 * 3;

export const isToday = (date: number | Date) => {
  const daysDiff = differenceInDays(new Date(), date);
  return daysDiff === 0;
};
export const isYesterday = (date: number | Date) => {
  const daysDiff = differenceInDays(new Date(), date);
  return daysDiff === 1;
};

export const fotdIsPastMidnight = (
  fotdAvailableAt: Date,
  countryFullTimeZone: string,
) => {
  const midnightInTimeZone = zonedTimeToUtc(
    new Date().setHours(0, 0, 0, 0),
    countryFullTimeZone,
  );
  return isAfter(fotdAvailableAt, midnightInTimeZone);
};

export const isLessThan30DaysAgo = (date: Date) => {
  const daysDiff = differenceInDays(new Date(), date);
  return daysDiff < 30;
};

export const isMoreThan30DaysFromNow = (date: string) => {
  const daysDiff = differenceInDays(parseISO(date), Date.now());
  return daysDiff > 30;
};

export const isMoreThanXMinuteAgo = (date: Date, numMinutesAgo: number) => {
  const minutesDiff = differenceInMinutes(new Date(), date);
  return minutesDiff > numMinutesAgo;
};

export const isMoreThanXDaysAgo = (date: string, numDays: number) => {
  const oneDayAgo = subDays(new Date(), numDays);
  return isBefore(parseISO(date), oneDayAgo);
};

export const isNowInSpecificTimezoneAfterDate = (
  dateToBeAfter,
  countryFullTimeZone,
) => {
  let dateToBeAfterCopy = dateToBeAfter;
  if (typeof dateToBeAfterCopy === 'string') {
    dateToBeAfterCopy = parseISO(dateToBeAfterCopy);
  }
  const datetimeInTimezone = utcToZonedTime(
    new Date().getTime(),
    countryFullTimeZone,
  );
  return isAfter(datetimeInTimezone, dateToBeAfterCopy);
};

export const isNowInSpecificTimezoneBeforeDate = (
  dateToBeAfter,
  countryFullTimeZone,
) => {
  let dateToBeAfterCopy = dateToBeAfter;
  if (typeof dateToBeAfterCopy === 'string') {
    dateToBeAfterCopy = parseISO(dateToBeAfterCopy);
  }
  const datetimeInTimezone = utcToZonedTime(
    new Date().getTime(),
    countryFullTimeZone,
  );
  return isBefore(datetimeInTimezone, dateToBeAfterCopy);
};

export const isBetweenTwoDatesForTimeZone = (
  countryFullTimeZone,
  dateFrom,
  dateTo,
) =>
  isNowInSpecificTimezoneAfterDate(dateFrom, countryFullTimeZone) &&
  isNowInSpecificTimezoneBeforeDate(dateTo, countryFullTimeZone);

export const numberToZeroLedString = (num: string | number) => {
  const number = parseInt(num as string, 10);
  return number <= 9 ? `0${number}` : number;
};

export const getMonths = (
  lang: string,
): { twoDigitNumber: string; monthName: string } => {
  const { format } = new Intl.DateTimeFormat(lang, {
    month: 'long',
  });
  const monthsArray = [...Array(12).keys()].map(m =>
    format(new Date(Date.UTC(new Date().getFullYear(), m % 12))),
  );
  return monthsArray.reduce(
    (array, month, i) =>
      Object.assign({
        ...array,
        [numberToZeroLedString(i + 1)]: month,
      }),
    {},
  );
};

export const getArrayOfNumbers = (startNumber, endNumber) =>
  Array.from(
    { length: endNumber - startNumber + 1 },
    (_, i) => i + startNumber,
  );

export const getNumDaysInMonth = (month, year) =>
  new Date(year, month, 0).getDate();

export type DateStringObject = {
  month: string;
  day: string;
  year: string;
};

export const isPastDate = date => isAfter(new Date(), parseISO(date));

export const getFormattedDateInFuture = (
  startDate: string,
  days: number,
  lang: string,
) => formatDate(addDays(new Date(startDate), days), 'MMMM d, yyyy', lang);

export const isMoreThanOneYearAgo = (isoDateString: string) =>
  isBefore(parseISO(isoDateString), subYears(new Date(), 1));

export const isMoreThanSixMonthsAgo = (isoDateString: string) =>
  isBefore(parseISO(isoDateString), subMonths(new Date(), 6));
