import queryString from 'query-string';

import { UserFactory } from '@app/api/resources/User';

export const getPaginationQS = (
  page: number = null,
  perPage: number = null,
) => {
  const paginationData: {
    page?: number;
    per_page?: number;
  } = {};

  if (page) {
    paginationData.page = page;
  }

  if (perPage) {
    paginationData.per_page = perPage;
  }

  return queryString.stringify(paginationData);
};

const getQsJoinCharacter = (url: string) => (url.includes('?') ? '&' : '?');

export const addUrlParam = (url: string, param: string) => {
  const qsJoinCharacter = getQsJoinCharacter(url);
  return `${url}${qsJoinCharacter}${param}`;
};

export const truncateOnWord = (s: string, minLength: number) => {
  const length = s.lastIndexOf(' ', minLength);
  return `${s.substring(0, length)}...`;
};

export const getCursorPaginationQS = (
  cursorBefore: number | string,
  perPage: number,
) => {
  if (cursorBefore) {
    return `before=${cursorBefore}&per_page=${perPage}`;
  }
  return `per_page=${perPage}`;
};

export const isEmail = email => /.+@.+\..+/.test(email);

export const commaSeparateStringArray = (stringArray: string[]) => {
  const numStrings = stringArray.length;
  return stringArray.reduce((strings, currentString, i) => {
    // eslint-disable-next-line no-param-reassign
    strings = `${strings}${currentString}`;
    if (numStrings - 1 > i) {
      // eslint-disable-next-line no-param-reassign
      strings = `${strings}, `;
    }
    return strings;
  }, '');
};

const keyMap: { [key: string]: keyof UserFactory } = {
  p: 'plan',
  s: 'status',
  r: 'renewalPeriod',
  c: 'country',
};

export const loginTokenToUserProfileParams = (token: string): UserFactory => {
  const regex = /([psrc]-[^+]+)(?:\+|$)/g;
  const result = [];
  let match = [];

  while ((match = regex.exec(token)) !== null) {
    const group = match[1].split('-');
    const key = group[0];
    const value = group[1];

    if (keyMap[key]) {
      result.push({ [keyMap[key]]: value });
    }
  }

  const matchOpts = token.match(/overrides=(\{.*\})/);

  if (matchOpts !== null) {
    const overrides = JSON.parse(matchOpts[1]);
    result.push({ overrides });
  }

  return result.reduce(
    (prev, current) => ({
      ...prev,
      ...current,
    }),
    {},
  );
};
