import { Translate } from 'next-translate';

import HttpService from '@app/api/http-service';
import { Country } from '@app/api/resources/Countries';
import { ResponseWithError } from '@app/api/resources/Responses';
import { ObjectOfStrings } from '@app/api/utility-types';

import {
  DeliveryAddress,
  DeliveryFormValues,
} from '@app/onboarding/services/delivery-address-helpers';
import { formatDate } from '@app/services/date-format';

export type Issue = {
  id: number;
  number: number;
  name: string;
  description: string;
  description_html: string;
  ship_on: string;
  word_count: number;
  image_count: number;
  page_count: number;
  allocate_on: string;
  shipment_completion_date: string;
  cover_image: {
    url: string;
  };
  spread_images: {
    url: string;
  }[];
  supply_state:
    | 'sold_out'
    | 'sold_out_online'
    | 'low_stock'
    | 'available'
    | 'coming_soon';
  started_shipping: boolean;
};

export type MagazineSubscriptionPlan = {
  price: string;
};

export type IssuesIncludedWithSubscription = [
  {
    number: number;
    allocate_on: string;
    ship_on: string;
  },
  {
    number: number;
    allocate_on: string;
    ship_on: string;
  },
];

export type IssueEntitlement = {
  id: number;
  allocated: boolean;
  issue: Issue;
  shipment_status: 'pending' | 'shipping' | 'recently_shipped' | 'shipped';
  delivery_address: DeliveryAddress;
  shipped_at: string;
};

export type MagazineSubscriptionNextCharge = {
  number: number;
  ship_on: string;
};

export type MagazineSubscriptionStatus =
  | 'paid'
  | 'marked_for_cancellation'
  | 'cancelled'
  | 'failed';

export type MagazineSubscription = {
  id: number;
  status: MagazineSubscriptionStatus;
  price: string;
  cancelled_on?: string;
};

export type MagazineSubscriptionResponse = {
  magazine_subscription_status: MagazineSubscription;
};

export const getPreviousMagazineIssues = (
  httpContext: ObjectOfStrings,
): ResponseWithError<Issue[]> =>
  HttpService(httpContext).get('/magazine/issues/previous');

export const getMagazineSubscriptionPlan = (
  httpContext: ObjectOfStrings,
): ResponseWithError<MagazineSubscriptionPlan> =>
  HttpService(httpContext).get('/magazine/subscription_plan');

export const getMagazineFeaturedIssue = (
  httpContext: ObjectOfStrings,
): ResponseWithError<Issue> =>
  HttpService(httpContext).get('/magazine/issues/featured');

export const getMagazineSubscriptionNextCharge = (
  httpContext: ObjectOfStrings,
): ResponseWithError<MagazineSubscriptionNextCharge> =>
  HttpService(httpContext).get('/magazine/issues/next_charge');

export const subscribeToNotebookMag = (
  httpContext: ObjectOfStrings,
): ResponseWithError<{
  magazine_subscription_status: MagazineSubscription;
}> => HttpService(httpContext).post('/magazine/subscription');

export const getUsersMagazineSubscription = (
  httpContext: ObjectOfStrings,
): ResponseWithError<{
  magazine_subscription_status: MagazineSubscription;
}> => HttpService(httpContext).get('/magazine/subscription');

export const cancelUsersNotebookSubscription = (
  httpContext: ObjectOfStrings,
): ResponseWithError<{
  magazine_subscription_status: MagazineSubscription;
}> => HttpService(httpContext).put('/magazine/subscription/cancel');

export const getUsersDeliveryAddress = (
  httpContext: ObjectOfStrings,
): ResponseWithError<DeliveryAddress> =>
  HttpService(httpContext).get('/magazine/delivery_address');

export type UpdatedDeliveryAddress = Omit<DeliveryAddress, 'id' | 'country'> & {
  country_id: number;
};

const setUsersDeliveryAddress = (
  httpContext: ObjectOfStrings,
  data: UpdatedDeliveryAddress,
): ResponseWithError<DeliveryAddress> =>
  HttpService(httpContext).put('/magazine/delivery_address', { data });

export const getUpcomingIssueEntitlements = (
  httpContext: ObjectOfStrings,
): ResponseWithError<IssueEntitlement[]> =>
  HttpService(httpContext).get('/magazine/issue_entitlements/upcoming');

export const getLatestIssueEntitlement = (
  httpContext: ObjectOfStrings,
): ResponseWithError<IssueEntitlement> =>
  HttpService(httpContext).get('/magazine/issue_entitlements/latest');

export const saveDeliveryAddress = async (
  httpContext: ObjectOfStrings,
  deliveryFormValues: DeliveryFormValues,
  isFormValid: () => boolean,
) => {
  const {
    firstName,
    lastName,
    company,
    country,
    addressOne,
    addressTwo,
    addressThree,
    city,
    county,
    postcode,
    phoneNumberFormatted,
  } = deliveryFormValues;

  if (isFormValid()) {
    const countryObj = country as unknown as Country;
    const res = await setUsersDeliveryAddress(httpContext, {
      first_name: firstName,
      last_name: lastName,
      company,
      line_1: addressOne,
      line_2: addressTwo,
      line_3: addressThree,
      city,
      post_code: postcode,
      state: county,
      msisdn: phoneNumberFormatted,
      country_id: countryObj.id,
    });

    return res.data;
  }
  return false;
};

export const transformDeliveryAddressToDeliveryAddressFormValues = (
  deliveryAddress = {} as DeliveryAddress,
): DeliveryFormValues => {
  const {
    first_name: firstName,
    last_name: lastName,
    line_1: addressOne,
    line_2: addressTwo,
    line_3: addressThree,
    company,
    city,
    state: county,
    post_code: postcode,
    country,
    msisdn: phoneNumber,
  } = deliveryAddress;

  return {
    firstName,
    lastName,
    addressOne,
    addressTwo,
    addressThree,
    company,
    city,
    county,
    postcode,
    country,
    phoneNumber,
  };
};

export const isIssueShippedEndOfMonth = (date: string, lang: string) => {
  const isEndOfMonth = parseInt(formatDate(date, 'd', lang)) >= 15;
  return isEndOfMonth;
};

export const getNextIssueNumber = (featuredIssue: Issue) => {
  const isSoldOut = ['sold_out', 'sold_out_online'].includes(
    featuredIssue?.supply_state,
  );
  const issueNo = isSoldOut ? featuredIssue?.number + 1 : featuredIssue?.number;

  return issueNo;
};

export const getNextIssueDate = (featuredIssue: Issue) => {
  const isSoldOut = ['sold_out', 'sold_out_online'].includes(
    featuredIssue?.supply_state,
  );
  let issueDate = new Date(featuredIssue?.ship_on);
  const currentMonthFeaturedIssue = issueDate.getMonth() + 1;

  if (isSoldOut) {
    issueDate = new Date(
      issueDate.setMonth(currentMonthFeaturedIssue >= 6 ? 11 : 5),
    );
  }

  return issueDate.toDateString();
};

export const getShippingMonth = (date: string, lang: string) => {
  return formatDate(date, 'MMMM', lang);
};

export const getIssueEntitlementShipmentStatus = (
  issueEntitlement: IssueEntitlement,
  t: Translate,
  lang: string,
) => {
  const issueEntitlementShipped =
    issueEntitlement.shipment_status === 'shipped' ||
    issueEntitlement.shipment_status === 'recently_shipped';
  const shippedDate =
    issueEntitlement.shipped_at || issueEntitlement.issue.ship_on;

  const isIssueShippedEndOfMonth =
    parseInt(formatDate(shippedDate, 'd', lang)) >= 15;

  if (isIssueShippedEndOfMonth) {
    return issueEntitlementShipped
      ? t(
          'subscription:subscription.notebook.issue_entitlement__end_of_shipped',
        )
      : t(
          'subscription:subscription.notebook.issue_entitlement__end_of_pending',
        );
  }

  return issueEntitlementShipped
    ? t('subscription:subscription.notebook.issue_entitlement_shipped')
    : t('subscription:subscription.notebook.issue_entitlement_pending');
};
