import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/nextjs';

import { setBrazeBanner } from '@app/actions/BannerActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import { BrazeBannerDetail } from '@app/components/banners/BrazeBanner';

const isProd = process.env.MUBI_ENV === 'production';

const BrazeInit = () => {
  const brazeUserId = useAppSelector(state => state.user.user?.braze_user_id);
  const userId = useAppSelector(state => state.user?.user?.id);
  const isAdmin = useAppSelector(state => state.user?.user?.is_admin);

  const dispatch = useDispatch();

  const handleBroadcastMessage = event => {
    console.log('Received from push notification: ', event.data);
    if (event?.data?.ab_cd?.e) {
      dispatch(setBrazeBanner(event?.data?.ab_cd?.e, true));
    }
  };

  useEffect(() => {
    let channel: BroadcastChannel;

    try {
      if (isAdmin && brazeUserId) {
        channel = new BroadcastChannel('braze-messages');

        if (isProd) {
          navigator.serviceWorker
            .register('https://mubi.com/brazeServiceWorker.js')
            .then(registration =>
              console.log(
                'Service Worker registration successful with scope: ',
                registration.scope,
              ),
            )
            .catch(err =>
              console.log('Service Worker registration failed: ', err),
            );
        }

        import(
          /* webpackExports: ["getCachedContentCards", "initialize", "openSession", "changeUser", "requestContentCardsRefresh", "requestPushPermission"] */
          '@braze/web-sdk'
        ).then(
          async ({
            initialize,
            openSession,
            changeUser,
            getCachedContentCards,
            requestContentCardsRefresh,
            requestPushPermission,
          }) => {
            initialize(process.env.BRAZE_API_KEY, {
              baseUrl: process.env.BRAZE_BASE_URL,
              enableLogging: true,
              allowUserSuppliedJavascript: true,
              manageServiceWorkerExternally: true,
              serviceWorkerLocation: '/brazeServiceWorker.js',
            });
            if (isProd) {
              channel.addEventListener('message', handleBroadcastMessage);

              requestPushPermission();
            }

            changeUser(String(brazeUserId));

            openSession();

            try {
              requestContentCardsRefresh(() => {
                const feed = getCachedContentCards();
                if (Array.isArray(feed?.cards) && feed.cards.length > 0) {
                  if (feed.cards[0].extras) {
                    dispatch(
                      setBrazeBanner(
                        feed.cards[0].extras as BrazeBannerDetail,
                        false,
                        feed.cards[0],
                      ),
                    );
                  }
                }
                console.log('feed', feed);
              });
            } catch (error) {
              console.log('getCachedFeed error.', error);
            }
          },
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    return () => {
      if (channel) {
        channel.removeEventListener('message', handleBroadcastMessage);
      }
    };
  }, [brazeUserId, userId]);

  return null;
};

export default BrazeInit;
