import AcceptLanguageParser from 'accept-language-parser';

export const supportedLocales = [
  {
    path: 'en',
    locale: 'en-US',
  },
  {
    path: 'de',
    locale: 'de-DE',
  },
  {
    path: 'es',
    locale: 'es-419',
  },
  {
    path: 'fr',
    locale: 'fr-FR',
  },
  {
    path: 'it',
    locale: 'it-IT',
  },
  {
    path: 'nl',
    locale: 'nl-NL',
  },
  {
    path: 'pt',
    locale: 'pt-BR',
  },
  {
    path: 'tr',
    locale: 'tr-TR',
  },
] as const;

export type LocalePath = typeof supportedLocales[number]['path'];
export type Locale = typeof supportedLocales[number]['locale'] | '';

export const supportedLocalesPaths = supportedLocales.map(
  supportedLocale => supportedLocale.path,
);

const pathToLocaleLookup = (localePath: LocalePath) => {
  const locale = supportedLocales.find(
    supportedLocale => supportedLocale.path === localePath,
  );
  if (locale) {
    return locale.locale;
  }
  return '';
};

const localeToPathLookup = (localeToLookup: Locale) => {
  const locale = supportedLocales.find(
    supportedLocale => supportedLocale.locale === localeToLookup,
  );
  if (locale) {
    return locale.path;
  }
  return '';
};

export const getLongLocaleFromPathLocale = (path: LocalePath) => {
  const locale = pathToLocaleLookup(path);

  return locale;
};
export const getPathFromLocale = (locale: Locale) => localeToPathLookup(locale);

export const isValidLocale = (locale: string) =>
  !!supportedLocales.find(
    supportedLocale =>
      supportedLocale.locale.toUpperCase() === locale.toUpperCase(),
  );

export const isValidLocalePath = (localePath: string) =>
  !!supportedLocales.find(
    supportedLocale =>
      supportedLocale.path.toUpperCase() === localePath.toUpperCase(),
  );

const getMostAcceptableLanguage = (acceptedLanguages: string): string => {
  const mostAcceptableLanguageString = AcceptLanguageParser.pick(
    supportedLocalesPaths,
    acceptedLanguages,
    { loose: true },
  );

  return mostAcceptableLanguageString;
};

const checkLanguageMismatchesAcceptedLanguages = (
  currentLanguage: string,
  acceptedLanguages: string,
) => {
  if (currentLanguage && acceptedLanguages) {
    const matchingLanguageExists: boolean = AcceptLanguageParser.pick(
      [currentLanguage],
      acceptedLanguages,
      {
        loose: true,
      },
    );
    if (!matchingLanguageExists) {
      return true;
    }
  }
  return false;
};

export const getAcceptableLanguageToRecommend = (
  lang: string,
  browserAcceptedLanguages: string,
  userSelectedLocale: Locale,
): LocalePath => {
  // Display the banner if:
  //   Current language is not accepted by the browser or,
  //   if the language selected is different from the one selected from the account settings
  const doesLanguageMismatchBrowserPreference =
    checkLanguageMismatchesAcceptedLanguages(lang, browserAcceptedLanguages);

  const langFromUserSelectedLocale = getPathFromLocale(userSelectedLocale);
  const languagePathAndUserLocaleMismatch =
    langFromUserSelectedLocale && langFromUserSelectedLocale !== lang;

  if (doesLanguageMismatchBrowserPreference && !langFromUserSelectedLocale) {
    const acceptableLanguage = getMostAcceptableLanguage(
      browserAcceptedLanguages,
    ) as LocalePath;
    return acceptableLanguage;
  }

  if (languagePathAndUserLocaleMismatch) {
    const acceptableLanguage = langFromUserSelectedLocale;
    if (doesLanguageMismatchBrowserPreference) {
      return acceptableLanguage;
    } else {
      return;
    }
  }
};

export const getLanguageName = (code: LocalePath | '') => {
  const language = new Intl.DisplayNames([code], { type: 'language' });
  return language.of(code);
};
