import cloneDeep from 'lodash/cloneDeep';

import HttpService from '@app/api/http-service';
import { Meta, MetaCursor } from '@app/api/pagination';
import { Comment } from '@app/api/resources/Comment';
import { SmallFanship } from '@app/api/resources/Fanship';
import { Film, FilmId } from '@app/api/resources/Film';
import { ResponseWithError } from '@app/api/resources/Responses';
import { User, UserId } from '@app/api/resources/User';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getCursorPaginationQS, getPaginationQS } from '@app/api/utils';

declare const grecaptcha: any;

export type OldListRaw = {
  id: number;
  title: string;
  title_locale: string;
  created_at: number;
  updated_at: number;
  canonical_url: string;
  user_id: number;
  // The services end points return list_films in this shape, however, we reformat before storing in the redux store for use. See OldList below.
  list_films: {
    [key: FilmId]: number;
  };
};

export type OldList = {
  id: number;
  title: string;
  title_locale: string;
  created_at: number;
  updated_at: number;
  canonical_url: string;
  user_id: number;
  // objects from the list_films DB table, where each key is a film ID, and each value is the list_films ID, which is used when you want to remove a film from a list - you send a DELETE with that list_film ID
  list_films: {
    filmId: number;
    listItemId: number;
  }[];
};

export type ListId = number;
type ListSlug = string;

export type List = {
  id: ListId;
  title: string;
  slug: ListSlug;
  created_at: string;
  updated_at: string;
  description: string;
  description_html: string;
  author:
    | {
        id: number;
        name: string;
        avatar_url: string;
      }
    | User;
  canonical_url: string;
  image_url: string;
  average_colour_hex: string;
  thumbnails: {
    src: string;
    average_colour_hex: string;
  }[];
  comment_count: number;
  film_count: number;
  fan_count: number;
  film_ids: number[];
  number_of_films?: number;
  number_of_followers?: number;
  seo_indexable: boolean;
};

type ListsPitchFilm = {
  is_related: boolean;
  film: Film;
};

export type ListFilm = {
  film: Film;
  film_id: number;
  position: number;
};

export type ListFilmResponse = ListFilm & {
  list_id: number;
  id: number;
};

export type ListTab = 'films' | 'comments' | 'followers';

export const getRelatedListsForFilm = (
  httpContext: ObjectOfStrings,
  filmId: number,
): ResponseWithError<List[]> =>
  HttpService(httpContext).get(`/films/${filmId}/related_lists`);

export const getList = (
  httpContext: ObjectOfStrings,
  listSlug: string,
): ResponseWithError<List> =>
  HttpService(httpContext).get(`/lists/${listSlug}`);

export const deleteList = (
  httpContext: ObjectOfStrings,
  listId: ListId,
): Promise<{ status: number }> =>
  HttpService(httpContext).deleteRequest(`/lists/${listId}`);

export const setList = (
  httpContext: ObjectOfStrings,
  listId: ListId,
  { title, description, film_ids },
): ResponseWithError<List> =>
  HttpService(httpContext).put(`/lists/${listId}`, {
    data: { title, description, film_ids },
  });

export const createNewList = (
  httpContext: ObjectOfStrings,
  {
    title,
    description,
    initial_film_ids,
  }: { title: string; description: string; initial_film_ids: number[] },
): ResponseWithError<List> =>
  new Promise((resolve, reject) => {
    if (typeof grecaptcha !== 'undefined') {
      try {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(
            process.env.recaptchaSiteKey,
            {
              action: 'create_list',
            },
          );

          const response = await HttpService(httpContext).post('/lists', {
            data: {
              title,
              description,
              initial_film_ids,
              recaptcha_token: token,
              recaptcha_action: 'create_list',
            },
          });

          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    } else {
      reject(new Error('grecaptcha not initialised'));
    }
  });

export const setListImage = (
  httpContext: ObjectOfStrings,
  listId: ListId,
  formData: any,
): ResponseWithError<List> =>
  HttpService(httpContext).put(`/lists/${listId}`, {
    data: formData,
  });

export const getListFilms = ({
  httpContext,
  listIdOrSlug,
  page,
  perPage,
  updatedAt,
}: {
  httpContext: ObjectOfStrings;
  listIdOrSlug: ListId | ListSlug;
  page: number;
  perPage?: number;
  updatedAt?: string;
}): ResponseWithError<{
  list_films: ListFilm[];
  meta: Meta;
}> =>
  HttpService(httpContext).get(
    `/lists/${listIdOrSlug}/list_films?${getPaginationQS(page, perPage)}${
      updatedAt ? `&updated_at=${encodeURIComponent(updatedAt)}` : ''
    }`,
  );

export const getListFollowers = ({
  httpContext,
  listId,
  currentCursor,
  perPage,
}: {
  httpContext: ObjectOfStrings;
  listId: number;
  currentCursor: number;
  perPage?: number;
}): ResponseWithError<{
  fanships: SmallFanship[];
  meta: MetaCursor;
}> =>
  HttpService(httpContext).get(
    `/lists/${listId}/fanships?${getCursorPaginationQS(
      currentCursor,
      perPage,
    )}`,
  );

export const getListComments = (
  httpContext: ObjectOfStrings,
  listId: number,
): ResponseWithError<Comment[]> =>
  HttpService(httpContext).get(`/lists/${listId}/comments`);

export const createListComment = (
  httpContext: ObjectOfStrings,
  listId: number,
  body: any,
): ResponseWithError<Comment> =>
  new Promise((resolve, reject) => {
    if (typeof grecaptcha !== 'undefined') {
      try {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(
            process.env.recaptchaSiteKey,
            {
              action: 'comment',
            },
          );

          const response = await HttpService(httpContext).post(
            `/lists/${listId}/comments`,
            {
              data: {
                body,
                recaptcha_token: token,
                recaptcha_action: 'comment',
              },
            },
          );

          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    } else {
      reject(new Error('grecaptcha not initialised'));
    }
  });

export const getPopularLists = (
  httpContext: ObjectOfStrings,
): ResponseWithError<List[]> => HttpService(httpContext).get('/lists/popular');

export const getListsPitchFilm = (
  httpContext: ObjectOfStrings,
  listId: ListId,
): ResponseWithError<ListsPitchFilm> =>
  HttpService(httpContext).get(`/lists/${listId}/pitch_film`);

export const getListReport = (
  httpContext: ObjectOfStrings,
  listId: ListId,
): ResponseWithError<{
  id: number;
}> => HttpService(httpContext).get(`/lists/${listId}/flagging`);

export const createListReport = (
  httpContext: ObjectOfStrings,
  listId: ListId,
): ResponseWithError<{
  id: number;
}> => HttpService(httpContext).post(`/lists/${listId}/flagging`);

export const getListsByCreatorId = (
  httpContext: ObjectOfStrings,
  userId: UserId,
  page: number,
  perPage?: number,
): ResponseWithError<{
  lists: List[];
  meta: Meta;
}> =>
  HttpService(httpContext).get(
    `/users/${userId}/lists?${getPaginationQS(page, perPage)}`,
  );

export const getListFanshipsOfUser = ({
  httpContext,
  userId,
  currentCursor,
  perPage,
}: {
  httpContext: ObjectOfStrings;
  userId: UserId;
  currentCursor: string;
  perPage?: number;
}): ResponseWithError<{
  fanships: {
    id: number;
    created_at: string;
    fannable: List;
  }[];
  meta: Meta;
}> =>
  HttpService(httpContext).get(
    `/users/${userId}/fanships/lists?${getCursorPaginationQS(
      currentCursor,
      perPage,
    )}`,
  );

export const formatOldListFromResponse = (usersList: OldListRaw): OldList => {
  const formattedUserList = cloneDeep(usersList) as any;
  const listFilms = Object.keys(usersList.list_films).map(filmId => ({
    filmId: parseInt(filmId, 10),
    listItemId: usersList.list_films[parseInt(filmId, 10)],
  }));
  formattedUserList.list_films = listFilms;
  return formattedUserList;
};
