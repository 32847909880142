import { Experiment } from '@app/api/services/experiments';

import * as actionTypes from '@app/actionTypes';

export const setExperiment = (experiment: Experiment) => ({
  type: actionTypes.SET_EXPERIMENT,
  payload: {
    experiment,
  },
});
export const resetExperiments = () => ({
  type: actionTypes.RESET_EXPERIMENTS,
});
