/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { FilmGroup } from '@app/api/resources/FilmGroup';

import { arrayToObjectKeyedById } from '@app/services/utils';

import * as actionTypes from '@app/actionTypes';

export type FilmGroupHighlightState = {
  filmGroupHighlights: { [key: number]: FilmGroup };
};

export const initialState: FilmGroupHighlightState = {
  filmGroupHighlights: {},
};

export const filmGroupHighlight = (state = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_FILM_GROUP_HIGHLIGHTS: {
        const { filmGroupHighlights }: { filmGroupHighlights: FilmGroup[] } =
          action.payload;

        const filmGroupHighlightsWithOrder = filmGroupHighlights.map(
          (thisFilmGroupHighlight, i) =>
            Object.assign({}, thisFilmGroupHighlight, { order: i }),
        );

        draft.filmGroupHighlights = arrayToObjectKeyedById(
          filmGroupHighlightsWithOrder,
        );
        break;
      }
      case actionTypes.ADD_FILM_GROUP_HIGHLIGHT: {
        const { filmGroupHighlight: filmGroupHighlightToAdd } = action.payload;
        const currentNumberOfFilmGroupHighlights = Object.values(
          state.filmGroupHighlights,
        ).length;
        draft.filmGroupHighlights[filmGroupHighlightToAdd.id] = Object.assign(
          {},
          filmGroupHighlightToAdd,
          {
            order: currentNumberOfFilmGroupHighlights,
          },
        );
        break;
      }
      case actionTypes.CLEAR_ALL_FILM_GROUP_HIGHLIGHTS:
        draft.filmGroupHighlights = {};
        break;
    }
  });
