import { isValidLocale, isValidLocalePath } from '@app/api/services/language';

export const getTwoCharLocaleFromLocaleString = (locale: string) => {
  if (locale) {
    if (isValidLocalePath(locale)) {
      return locale;
    }
    if (isValidLocale(locale)) {
      return locale.substring(0, 2);
    }
  }
  return null;
};

export { isValidLocalePath };
