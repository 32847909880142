import { useEffect } from 'react';

import useCookiePrefs from '@app/hooks/helpers/useCookiePrefs';

const initialiseFB = () => {
  window.fbAsyncInit = () => {
    FB.init({
      appId: process.env.facebookAppId,
      xfbml: true,
      version: 'v14.0',
      status: true,
    });
  };
};

const addFacebookScriptTag = () => {
  const fjs = document.getElementsByTagName('script')[0];
  if (document.getElementById('facebook-jssdk')) {
    return;
  }
  const js = document.createElement('script');
  js.id = 'facebook-jssdk';
  js.src = '//connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
  setTimeout(initialiseFB, 0);
};

const InitialiseFacebookSDKContainer = () => {
  const { hasConsentedToMarketingCookie } = useCookiePrefs();

  useEffect(() => {
    if (hasConsentedToMarketingCookie) {
      addFacebookScriptTag();
    }
  }, [hasConsentedToMarketingCookie]);

  return null;
};

export default InitialiseFacebookSDKContainer;
