/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { IssueEntitlement } from '@app/api/resources/notebook/NotebookMagazine';
import { PaymentMethod } from '@app/api/resources/PaymentMethod';
import { Rating } from '@app/api/resources/Ratings';
import {
  SubscriptionPlanKeys,
  SubscriptionPlans,
} from '@app/api/resources/SubscriptionPlans';
import { CookieConsent } from '@app/api/resources/User';
import { CurrentUser } from '@app/api/resources/User';

import { LocalePath } from '@app/api/services/language';

import { ObjectOfStrings } from '@app/types/utility-types';

import * as actionTypes from '@app/actionTypes';

export type UserState = {
  userIsInitialisedOnClient: boolean;
  geoLocation: string;
  countryFullTimeZone: string;
  acceptedLanguages: string;
  currentLanguage: LocalePath;
  useUsEnglish: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  activeSubscriber: boolean;
  activePremiumSubscriber: boolean;
  user: CurrentUser;
  usersFilmRatings: {
    [key: number]: Rating;
  };
  watchList: any;
  subscriptionPlans: SubscriptionPlans;
  subscriptionPlansForUser: SubscriptionPlans;
  dismissedBanners: ObjectOfStrings;
  magazineSubStatus: string;
  magazineIssueEntitlements: IssueEntitlement[];
  paymentMethod: PaymentMethod;
  localIllowCookieConsent: CookieConsent;
};

export const initialState = {
  userIsInitialisedOnClient: false,
  geoLocation: 'GB',
  countryFullTimeZone: '',
  acceptedLanguages: '',
  currentLanguage: 'en',
  useUsEnglish: false,
  isAuthenticated: false,
  isAdmin: false,
  activeSubscriber: false,
  activePremiumSubscriber: false,
  user: null,
  usersFilmRatings: {},
  watchList: {},
  subscriptionPlans: {},
  subscriptionPlansForUser: {},
  experiments: [],
  dismissedBanners: {},
  magazineSubStatus: '',
  magazineIssueEntitlements: [] as IssueEntitlement[],
  paymentMethod: null,
  localIllowCookieConsent: null,
} as const;

const userHasPremiumSubscription = (
  currentPlan: SubscriptionPlanKeys,
  geolocation: string,
) => {
  if (geolocation === 'US') {
    if (currentPlan === 'month' || currentPlan === 'year') {
      return true;
    }
    return false;
  }
  if (currentPlan === 'month_premium' || currentPlan === 'year_premium') {
    return true;
  }
  return false;
};

export const user = (state: UserState = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_USER_INITIALISED_ON_PAGE_LOAD:
        draft.userIsInitialisedOnClient = true;
        break;
      case actionTypes.SET_USER_GEO_LOCATION:
        draft.geoLocation = action.payload.geoLocation;
        break;
      case actionTypes.SET_COUNTRY_FULL_TIME_ZONE:
        draft.countryFullTimeZone = action.payload.countryFullTimeZone;
        break;
      case actionTypes.SET_ACCEPTED_LANGUAGES:
        draft.acceptedLanguages = action.payload.acceptedLanguages;
        break;
      case actionTypes.SET_CURRENT_LANGUAGE:
        draft.currentLanguage = action.payload.currentLanguage;
        break;
      case actionTypes.LOG_USER_IN:
        draft.user = action.payload.user;
        draft.isAuthenticated = true;
        draft.isAdmin = action?.payload?.user?.is_admin ?? false;
        draft.activeSubscriber =
          action?.payload?.user?.active_subscriber ?? false;
        {
          const currentPlan = action?.payload?.user?.current_plan;
          draft.activePremiumSubscriber = userHasPremiumSubscription(
            currentPlan,
            state.geoLocation,
          );
        }
        break;
      case actionTypes.LOG_USER_OUT:
        draft.user = null;
        draft.isAuthenticated = false;
        draft.isAdmin = false;
        draft.activeSubscriber = false;
        draft.activePremiumSubscriber = false;
        draft.subscriptionPlansForUser = {};
        draft.usersFilmRatings = {};
        draft.watchList = {};
        draft.magazineSubStatus = '';
        draft.paymentMethod = null;

        break;
      case actionTypes.UPDATE_LOGGED_IN_USER:
        draft.user = { ...state.user, ...action.payload.updatedUserProperties };
        break;
      case actionTypes.SET_PARENTAL_CONTROL_FOR_USER:
        const { contentRatingId, pinCode } = action.payload;
        draft.user = {
          ...state.user,
          content_rating_id: contentRatingId,
        };
        if (pinCode) {
          draft.user.parental_pin = pinCode;
        }
        break;
      case actionTypes.SET_WATCH_LIST: {
        const watchList = action.payload.watchList.reduce(
          (acc, currWatchListItem) => {
            const newItem = {};
            newItem[currWatchListItem.film.id] = {
              wishId: currWatchListItem.id,
            };
            return { ...acc, ...newItem };
          },
          {},
        );

        draft.watchList = { ...draft.watchList, ...watchList };
        break;
      }
      case actionTypes.ADD_ITEMS_TO_WATCH_LIST: {
        action.payload.watchListItems.forEach(watchListItem => {
          draft.watchList[watchListItem.film.id] = {
            isLoading: watchListItem.isLoading,
            wishId: watchListItem.id,
          };
        });

        break;
      }
      case actionTypes.ADD_TO_WATCH_LIST: {
        draft.watchList[action.payload.watchListItem.film.id] = {
          isLoading: action.payload.watchListItem.isLoading || false,
          wishId: action.payload.watchListItem.id,
        };

        break;
      }
      case actionTypes.REMOVE_FROM_WATCH_LIST:
        draft.watchList[action.payload.filmIdToRemove].wishId = null;
        break;
      case actionTypes.SET_SUBSCRIPTION_PLANS:
        draft.subscriptionPlans = action.payload.subscriptionPlans;
        break;
      case actionTypes.SET_SUBSCRIPTION_PLANS_FOR_USER:
        draft.subscriptionPlansForUser =
          action.payload.subscriptionPlansForUser;
        break;
      case actionTypes.SET_USER_RATINGS:
        draft.usersFilmRatings = {
          ...state.usersFilmRatings,
          ...action.payload.ratingsByFilmId,
        };
        break;
      case actionTypes.REMOVE_USER_RATING_FOR_FILM:
        draft.usersFilmRatings[action.payload.filmId] = null;
        break;
      case actionTypes.SET_USE_US_ENGLISH:
        draft.useUsEnglish = action.payload.useUsEnglish;
        break;
      case actionTypes.SET_DISMISSED_BANNERS:
        draft.dismissedBanners = action.payload.dismissedBanners;
        break;
      case actionTypes.DISMISS_A_BANNER:
        draft.dismissedBanners[action.payload.dismissedBanner] =
          action.payload.dismissedBannerTime;
        break;
      case actionTypes.SET_MAGAZINE_SUBSCRIPTION:
        draft.magazineSubStatus = action.payload.magazineSubStatus || '';
        draft.magazineIssueEntitlements =
          action.payload.issueEntitlements || [];
        break;
      case actionTypes.SET_PAYMENT_METHOD_FOR_USER:
        draft.paymentMethod = action.payload.paymentMethod;
        break;
      case actionTypes.SET_LOCAL_ILLOW_COOKIE_CONSENT:
        draft.localIllowCookieConsent = action.payload.cookieConsent;
        break;
    }
  });
