/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { PlayerConfig } from '@app/services/clpp';

import * as actionTypes from '@app/actionTypes';

type StreamType =
  | 'application/dash+xml'
  | 'application/x-mpegurl'
  | 'application/vnd.ms-sstr+xml'
  | 'video/mp4';

type PlaybackSource = {
  url: string;
  type: StreamType;
};

export type PreviewClipPlayerStatus = 'error' | 'success' | 'ended';

export type PreviewClipPlayer = {
  videoElementId: string;
  previewClipSrcUrl: PlaybackSource[];
  status: PreviewClipPlayerStatus;
  isMuted: boolean;
  hasSubs: boolean;
  previewClipId: number;
  filmId: number | string;
  duration: number;
  overrideConfig: PlayerConfig;
};

export type FilmTileState = {
  previewClipPlayer: PreviewClipPlayer;
};

// eslint-disable-next-line import/no-unused-modules
export const initialState: FilmTileState = {
  previewClipPlayer: {
    videoElementId: null,
    previewClipSrcUrl: null,
    status: null,
    isMuted: true,
    hasSubs: false,
    previewClipId: null,
    filmId: null,
    duration: 0,
    overrideConfig: null,
  },
};

const filmTile = (state = initialState, action: AnyAction) =>
  produce(state, (draft: FilmTileState) => {
    switch (action.type) {
      case actionTypes.SET_PREVIEW_CLIP_PLAYER:
        draft.previewClipPlayer.previewClipSrcUrl =
          action.payload.previewClipSrcUrl;
        draft.previewClipPlayer.previewClipId = action.payload.previewClipId;
        draft.previewClipPlayer.filmId = action.payload.filmId;
        draft.previewClipPlayer.videoElementId = action.payload.videoElementId;
        draft.previewClipPlayer.overrideConfig = action.payload.overrideConfig;
        draft.previewClipPlayer.duration = 0;
        break;
      case actionTypes.SET_PREVIEW_CLIP_PLAYER_STATUS:
        draft.previewClipPlayer.status = action.payload.status;
        break;
      case actionTypes.SET_PREVIEW_CLIP_PLAYER_MUTED:
        draft.previewClipPlayer.isMuted = action.payload.muted;
        break;
      case actionTypes.CLEAR_PREVIEW_CLIP_PLAYER:
        draft.previewClipPlayer.previewClipSrcUrl = null;
        draft.previewClipPlayer.videoElementId = null;
        draft.previewClipPlayer.status = null;
        draft.previewClipPlayer.previewClipId = null;
        draft.previewClipPlayer.filmId = null;
        draft.previewClipPlayer.overrideConfig = null;
        break;
      case actionTypes.SET_PREVIEW_CLIP_PLAYER_SUBS:
        draft.previewClipPlayer.hasSubs = action.payload.hasSubs;
        break;
      case actionTypes.SET_PREVIEW_CLIP_PLAYER_DURATION:
        draft.previewClipPlayer.duration = action.payload.duration;
        break;
    }
  });

export default filmTile;
