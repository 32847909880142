import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  initSnowplow,
  updateSnowplowDuidIfNecessary,
} from '@app/services/snowplow';

import { setSnowplowInitialised } from '@app/actions/AppStateActions';

import useSnowplowPageViewTracking from '@app/hooks/snowplow/useSnowplowPageViewTracking';
import useAppSelector from '@app/hooks/utils/useAppSelector';

const InitialiseSnowplowContainer = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(state => state.user.user);
  useSnowplowPageViewTracking();

  useEffect(() => {
    initSnowplow(user?.id);
    updateSnowplowDuidIfNecessary();
    dispatch(setSnowplowInitialised());
  }, []);

  return null;
};

export default InitialiseSnowplowContainer;
