import { NextRouter } from 'next/router';

import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { CastMember, Director } from '@app/api/resources/CastMember';
import { Collection } from '@app/api/resources/Collections';
import { Consumable } from '@app/api/resources/Consumables';
import {
  FilmHighlightAward,
  FilmHighlightPressQuote,
  FilmHighlightStarRating,
} from '@app/api/resources/FilmHighlight';
import { FilmViewing } from '@app/api/resources/FilmViewing';
import {
  IndustryEventEntry,
  IndustryEventFilmDetails,
} from '@app/api/resources/IndustryEvent';
import { FilmNotebookPost } from '@app/api/resources/notebook/NotebookPost';
import { Rating } from '@app/api/resources/Ratings';
import { TextTrackUrl } from '@app/api/resources/Reel';
import { ObjectOfStrings } from '@app/api/utility-types';
import {
  addUrlParam,
  commaSeparateStringArray,
  truncateOnWord,
} from '@app/api/utils';

import { checkPathnamesAreEqual } from '@app/services/routeHelpers';
import { isClient } from '@app/services/utils';

const validContentRatingLabels = [
  'adult',
  'mature',
  'caution',
  'general',
  'not_rated',
];

export type ContentRating = {
  rating_code: string;
  description: string;
  label: 'adult' | 'caution' | 'general' | 'not_rated' | 'mature';
  label_hex_color: string;
  icon_url?: string;
};

export type ContentWarnings = {
  id: number;
  name: string;
  key: string;
};

type FilmArtwork = {
  format: string;
  locale: string;
  image_url: string;
};

export type Episode = {
  film_group_id: number; // The series id
  number: number;
  season_number: number;
  season_title: string;
  series_title: string;
  label: string;
  fotd_show_episode: boolean;
  episode_label_color: string;
  episode_title: string;
};

export type FilmId = number;
export type FilmSlug = string;

export type UpNext = {
  film_id: number;
  // Note: This is just a text, not a url
  cta: string;
};

export type OptimisedTrailer = {
  url: string;
  profile: '240p' | '480p' | '720p' | '1080p';
};

export type FocalPoint = { x: number; y: number };

export type Film = {
  id: FilmId;
  slug: FilmSlug;
  average_colour_hex: string;
  average_rating: number;
  average_rating_out_of_ten: number;
  consumable: Consumable;
  content_rating: ContentRating;
  content_warnings: ContentWarnings[];
  artworks: FilmArtwork[];
  critic_review_rating: number;
  default_editorial: string;
  default_editorial_html: string;
  directors: Director[];
  duration: number;
  genres: string[];
  hd: boolean;
  historic_countries: string[];
  mubi_go_highlighted: boolean;
  mubi_release: boolean;
  number_of_ratings: number;
  optimised_trailers: OptimisedTrailer[];
  original_title: string;
  popularity: number;
  portrait_image: string;
  short_synopsis: string;
  short_synopsis_html: string;
  should_use_safe_still: boolean;
  still_focal_point: FocalPoint;
  still_url: string;
  stills: {
    medium: string;
    retina: string;
    small: string;
    standard: string;
    square?: string;
    small_overlaid?: string;
    large_overlaid?: string;
  };
  experiment_stills?: ObjectOfStrings;
  title: string;
  title_locale: string;
  title_treatment_url: string;
  title_upcase: string;
  trailer_id: number;
  trailer_url: string;
  web_url: string;
  year: number;
  episode: Episode | null;
  highlighted_industry_event_entry: IndustryEventEntry;
  press_quote: FilmHighlightPressQuote;
  star_rating: FilmHighlightStarRating;
  award: FilmHighlightAward;
  cast_members_count: number;
  industry_events_count: number;
  comments_count: number;
};

export type MuxData = {
  video_title: string;
  video_id: number;
  video_variant_id: string;
  video_encoding_variant: string;
  video_content_type?: string;
  view_session_id: string;
  video_stream_type: string;
  viewer_user_id: number;
  video_cdn: string;
  custom_1: string; // type
  custom_2: string; // video quality, eg "960"
  custom_3: string; // video quality type, eg "FHD"
};

export type SecureVideoUrl = {
  url: string;
  signature: string;
  fallback_urls: string[];
  urls: { src: string; content_type: string }[];
  text_track_urls: TextTrackUrl[];
  drm: {
    asset_id: string;
    variant_id: string;
  };
  video_codec: string;
  stream_start_index: number;
  mux: MuxData;
};

export const getFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId | FilmSlug,
): Promise<{
  data: Film;
}> =>
  HttpService(httpContext).get(`/films/${filmId}`, {
    isPublic: true,
  });

export const getFilms = (
  httpContext: ObjectOfStrings,
  filmIds: FilmId[],
): Promise<{
  data: { films: Film[] };
}> =>
  HttpService(httpContext).get(`/films?ids[]=${filmIds.join('&ids[]=')}`, {
    isPublic: true,
  });

export const getCollectionMarqueeFilm = (
  httpContext: ObjectOfStrings,
): { data: Collection } => HttpService(httpContext).get('/collections/marquee');

export const createFilmViewing = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
  pincode?: string,
): Promise<{
  data: FilmViewing;
}> => {
  let url = `/films/${filmId}/viewing`;

  url = addUrlParam(url, 'parental_lock_enabled=true');

  if (pincode) {
    url = addUrlParam(url, `pin_code=${pincode}`);
  }

  return HttpService(httpContext).post(url);
};

export const getViewingByFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId | FilmSlug,
): Promise<{
  data: FilmViewing;
}> => HttpService(httpContext).get(`/films/${filmId}/viewing`);

export const getUpNextByFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{ data: UpNext }> =>
  HttpService(httpContext).get(`/films/${filmId}/viewing/up_next`);

export const getSecureVideoUrl = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{ data: SecureVideoUrl }> =>
  HttpService(httpContext).get(`/films/${filmId}/viewing/secure_url`);

export const getFilmRatings = (
  httpContext: ObjectOfStrings,
  {
    filmId,
    page = 1,
    per_page = 8,
    filter = '',
    sort = '',
  }: {
    filmId: FilmId;
    page: number;
    per_page: number;
    filter: string;
    sort: string;
  },
): Promise<{ data: { ratings: Rating[]; meta: Meta } }> => {
  const params = new URLSearchParams({
    page: String(page),
    per_page: String(per_page),
    filter: String(filter),
    sort: String(sort),
  });
  return HttpService(httpContext).get(`/films/${filmId}/ratings?${params}`);
};

export const putWatchingFilmHeartbeat = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
  lastTimecode: string,
): Promise<{
  data: null;
  status: number;
}> =>
  HttpService(httpContext).put(
    `/films/${filmId}/viewing/watching?last_time_code=${parseInt(
      lastTimecode,
      10,
    )}`,
  );

export const putVerifyFilmViewingHeartbeat = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: {
    ttl: number;
  };
  status: number;
}> => HttpService(httpContext).put(`/films/${filmId}/viewing/verify`);

export const getRelatedFilms = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: Film[];
}> => HttpService(httpContext).get(`/films/${filmId}/related_films`);

export const getIsWatchable = (film: Film) => {
  if (film) {
    return film?.consumable?.availability === 'live';
  }
  return null;
};

export const getIsExclusive = (film: Film) => {
  if (film) {
    return film?.consumable?.exclusive;
  }
  return null;
};

// eslint-disable-next-line import/no-unused-modules
export const getSpotlightedCastMembersForFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: CastMember[];
}> => HttpService(httpContext).get(`/films/${filmId}/spotlighted_cast_members`);

// eslint-disable-next-line import/no-unused-modules
export const getNotebookPostsForFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: FilmNotebookPost[];
}> => HttpService(httpContext).get(`/films/${filmId}/notebook_posts`);

// eslint-disable-next-line import/no-unused-modules
export const getIndustryEventsForFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: IndustryEventFilmDetails[];
}> => HttpService(httpContext).get(`/films/${filmId}/industry_events`);

// eslint-disable-next-line import/no-unused-modules
export const getIndustryEventEntriesForFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
): Promise<{
  data: IndustryEventEntry[];
}> => HttpService(httpContext).get(`/films/${filmId}/industry_event_entries`);

export const getCastMembersForFilm = ({
  httpContext,
  filmId,
  sortBy = 'relevence',
  sortByPeriod = null,
  castMemberType = null,
  pageNum = 1,
  perPage = 48,
}: {
  httpContext: ObjectOfStrings;
  filmId: FilmId;
  sortBy: string;
  sortByPeriod: string;
  castMemberType: string;
  pageNum: number;
  perPage: number;
}): Promise<{
  data: {
    cast_members: CastMember[];
    meta: Meta;
  };
}> => {
  let url = `/films/${filmId}/cast_members`;

  if (sortBy) {
    url = addUrlParam(url, `sort=${sortBy}`);
  }

  if (sortByPeriod) {
    url = addUrlParam(url, `sort_period=${sortByPeriod}`);
  }

  if (castMemberType) {
    url = addUrlParam(url, `type=${castMemberType}`);
  }

  if (pageNum) {
    url = addUrlParam(url, `page=${pageNum}`);
  }

  if (perPage) {
    url = addUrlParam(url, `per_page=${perPage}`);
  }

  return HttpService(httpContext).get(url);
};

export const getStillImageBackgroundPosition = (focalPoint: FocalPoint) => {
  const xPos = focalPoint?.x ?? 0.5;
  const yPos = focalPoint?.y ?? 0.5;
  return `${(xPos * 100).toFixed(3)}% ${(yPos * 100).toFixed(3)}%`;
};

export const getSecondaryTitle = (film: Film) =>
  film.title?.toLowerCase() === film.original_title?.toLowerCase()
    ? ''
    : film.original_title;

export const getStillFocalPoint = (film: Film) => {
  if (film?.still_focal_point?.x && film?.still_focal_point?.y) {
    const x = film?.still_focal_point?.x;
    const y = film?.still_focal_point?.y;

    const focalPointX = (x * 100).toFixed(5);
    const focalPointY = (y * 100).toFixed(5);

    return `${focalPointX}% ${focalPointY}%`;
  }

  return 'center';
};

export const getCountryForFilm = (film: Film) =>
  Array.isArray(film?.historic_countries) && film.historic_countries.length > 0
    ? film.historic_countries[0]
    : '';

export const getCountryYearStringForFilm = (film: Film) => {
  const countryForFilm = getCountryForFilm(film);
  const year = film?.year;

  let countryYearString = '';

  if (countryForFilm) {
    countryYearString = countryForFilm;
  }

  if (countryForFilm && year) {
    countryYearString = `${countryYearString}, `;
  }

  if (year) {
    countryYearString = `${countryYearString}${year}`;
  }

  return countryYearString;
};

export const getDirectorsStringForFilm = (film: Film, maxDirectors = 3) =>
  `${film.directors.reduce((acc, curr, index) => {
    if (index < maxDirectors) {
      if (acc === '') {
        return curr.name;
      }
      return `${acc}, ${curr.name}`;
    }
    return acc;
  }, '')}${film.directors.length > maxDirectors ? '...' : ''}`;

export const checkIsValidContentRatingLabel = (contentRatingLabel: string) =>
  validContentRatingLabels.includes(contentRatingLabel);

export const getDisplayFilmTitle = (film: Film, isTurkishLanguage: boolean) => {
  const MAX_TURKISH_TITLE_LENGTH = 40;

  const isSeries = !!film.episode;

  let displayTitle = isSeries
    ? film?.episode.season_title.toLocaleUpperCase()
    : film?.title_upcase;
  if (isTurkishLanguage && displayTitle.length > MAX_TURKISH_TITLE_LENGTH) {
    displayTitle = truncateOnWord(film?.title_upcase, MAX_TURKISH_TITLE_LENGTH);
  }

  return displayTitle;
};

export const getFilmLink = (
  film: Film,
  router: NextRouter,
  isAuthenticated: boolean,
) => {
  const useJoinLink =
    (checkPathnamesAreEqual(router.pathname, '/') ||
      checkPathnamesAreEqual(router.pathname, '/about')) &&
    !isAuthenticated;
  return useJoinLink ? `/join?filmId=${film.id}` : `/films/${film.slug}`;
};

export const giftFilm = (
  httpContext: ObjectOfStrings,
  filmId: FilmId,
  recipients = [{ name: '', email: '' }],
  senderName = '',
): Promise<{ data: null }> =>
  HttpService(httpContext).post(`/films/${filmId}/gift_film`, {
    data: { recipients, sender_name: senderName },
  });

export const getUpcomingFilms = (
  httpContext: ObjectOfStrings,
): Promise<{
  data: Film[];
}> => HttpService(httpContext).get('/films/upcoming');

export const getResponsiveTrailerUrl = (
  film: Film,
  forceHighQualityTrailer = false,
) => {
  const trailerUrl240p = film?.optimised_trailers?.find(
    trailer => trailer.profile === '240p',
  )?.url;
  const trailerUrl720p = film?.optimised_trailers?.find(
    trailer => trailer.profile === '720p',
  )?.url;

  let trailerUrl = trailerUrl720p || trailerUrl240p;

  const trailerUrl1080p = film?.optimised_trailers?.find(
    trailer => trailer.profile === '1080p',
  )?.url;

  if (isClient() && window.innerWidth >= 1920) {
    trailerUrl = trailerUrl1080p || trailerUrl720p || trailerUrl240p;
  }

  if (forceHighQualityTrailer) {
    trailerUrl = trailerUrl1080p || trailerUrl720p || trailerUrl240p;
  }

  return trailerUrl;
};

export const getDirectorAndYearString = (film: Film, maxDirectors = 3) => {
  if (!film.directors || film.directors?.length === 0) {
    return film.year?.toString() ?? '';
  }
  if (!film.year) {
    return getDirectorsStringForFilm(film, maxDirectors);
  }
  if (film.directors?.length > maxDirectors) {
    return `${getDirectorsStringForFilm(film, maxDirectors)} ${film.year}`;
  }
  return `${getDirectorsStringForFilm(film, maxDirectors)}, ${film.year}`;
};

export const getGenres = (film: Film, maxNumber?: number) => {
  let genresString = '';
  if (film?.genres && film?.genres.length > 0) {
    genresString = maxNumber
      ? commaSeparateStringArray(film.genres.slice(0, maxNumber))
      : commaSeparateStringArray(film.genres);
  }

  return genresString;
};

export const getCountryAndYear = (film: Film) => {
  const countries = film?.historic_countries;
  const hasCountry = countries?.length > 0;
  const hasSecondCountry = countries?.length > 1;
  const hasYear = film?.year;
  const hasCountryAndYear = hasCountry && hasYear;

  if (hasCountry || hasYear) {
    return `${hasCountry ? countries[0] : ''}${
      hasSecondCountry ? `, ${countries[1]}` : ''
    }${hasCountryAndYear ? ', ' : ''}${hasYear && film.year}`;
  }
  return null;
};

export const getFilmTitle = (film: Film) => {
  const { episode } = film;
  const isSeries = !!episode;

  return isSeries ? episode.season_title : film.title_upcase;
};
