import { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import { shallowEqual, useDispatch } from 'react-redux';

import { setShowOnboardingModal } from '@app/actions/AppStateActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';
import useIsMounted from '@app/hooks/utils/useIsMounted';

const Onboarding = lazy(
  () =>
    import(
      /* webpackChunkName: "onboarding" */
      '@app/onboarding'
    ),
);

const OnboardingContainer = () => {
  const isMounted = useIsMounted();

  const { showOnboardingModal, onboardingContext } = useAppSelector(
    state => ({
      showOnboardingModal: state.appState.showOnboardingModal,
      onboardingContext: state.appState.onboardingContext,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setShowOnboardingModal(false));
  };

  const onboardingApp = (
    <Onboarding
      currentFlow={onboardingContext.flow}
      showModal={showOnboardingModal}
      closeModal={closeModal}
    />
  );

  if (isMounted) {
    if (onboardingContext.container) {
      return (
        <Suspense fallback={<div />}>
          {createPortal(onboardingApp, onboardingContext.container)}
        </Suspense>
      );
    }
    return <Suspense fallback={<div />}>{onboardingApp}</Suspense>;
  }

  return null;
};

export default OnboardingContainer;
