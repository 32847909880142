/* eslint-disable import/no-unused-modules */
import * as actionTypes from '@app/actionTypes';
import {
  CreditCardPaymentGateway,
  PaypalPaymentGateway,
} from '@app/reducers/PaymentGatewayReducers';

export const setCreditCardPaymentGateway = (
  creditCardPaymentGatewayDetails: CreditCardPaymentGateway,
) => ({
  type: actionTypes.SET_CREDIT_CARD_PAYMENT_GATEWAY,
  payload: {
    creditCardPaymentGatewayDetails,
  },
});

export const setPaypalConfig = (
  paypalPaymentGatewayDetails: PaypalPaymentGateway,
) => ({
  type: actionTypes.SET_PAYPAL_CONFIG,
  payload: {
    paypalPaymentGatewayDetails,
  },
});
