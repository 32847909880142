import { doesStringContainDigits } from '@app/services/utils';
import { sortArrayOfObjectsByProperty } from '@app/services/utils';

export const countries = {
  a1: {
    id: 1,
    code: 'A1',
    name: 'Anonymous Proxy',
  },
  a2: {
    id: 2,
    code: 'A2',
    name: 'Satellite Provider',
  },
  ad: {
    id: 3,
    code: 'AD',
    name: 'Andorra',
  },
  ae: {
    id: 4,
    code: 'AE',
    name: 'United Arab Emirates',
  },
  af: {
    id: 5,
    code: 'AF',
    name: 'Afghanistan',
  },
  ag: {
    id: 6,
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  ai: {
    id: 7,
    code: 'AI',
    name: 'Anguilla',
  },
  al: {
    id: 8,
    code: 'AL',
    name: 'Albania',
  },
  am: {
    id: 9,
    code: 'AM',
    name: 'Armenia',
  },
  an: {
    id: 10,
    code: 'AN',
    name: 'Netherlands Antilles',
  },
  ao: {
    id: 11,
    code: 'AO',
    name: 'Angola',
  },
  ap: {
    id: 12,
    code: 'AP',
    name: 'Asia/Pacific Region',
  },
  aq: {
    id: 13,
    code: 'AQ',
    name: 'Antarctica',
  },
  ar: {
    id: 14,
    code: 'AR',
    name: 'Argentina',
  },
  as: {
    id: 15,
    code: 'AS',
    name: 'American Samoa',
  },
  at: {
    id: 16,
    code: 'AT',
    name: 'Austria',
    phone_code: 43,
    is_eu: true,
  },
  au: {
    id: 17,
    code: 'AU',
    name: 'Australia',
  },
  aw: {
    id: 18,
    code: 'AW',
    name: 'Aruba',
  },
  ax: {
    id: 19,
    code: 'AX',
    name: 'Aland Islands',
  },
  az: {
    id: 20,
    code: 'AZ',
    name: 'Azerbaijan',
  },
  ba: {
    id: 21,
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  bb: {
    id: 22,
    code: 'BB',
    name: 'Barbados',
  },
  bd: {
    id: 23,
    code: 'BD',
    name: 'Bangladesh',
  },
  be: {
    id: 24,
    code: 'BE',
    name: 'Belgium',
    is_eu: true,
  },
  bf: {
    id: 25,
    code: 'BF',
    name: 'Burkina Faso',
  },
  bg: {
    id: 26,
    code: 'BG',
    name: 'Bulgaria',
    is_eu: true,
  },
  bh: {
    id: 27,
    code: 'BH',
    name: 'Bahrain',
  },
  bi: {
    id: 28,
    code: 'BI',
    name: 'Burundi',
  },
  bj: {
    id: 29,
    code: 'BJ',
    name: 'Benin',
  },
  bm: {
    id: 30,
    code: 'BM',
    name: 'Bermuda',
  },
  bn: {
    id: 31,
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  bo: {
    id: 32,
    code: 'BO',
    name: 'Bolivia',
  },
  br: {
    id: 33,
    code: 'BR',
    name: 'Brazil',
    currency: 'BRL',
  },
  bs: {
    id: 34,
    code: 'BS',
    name: 'Bahamas',
  },
  bt: {
    id: 35,
    code: 'BT',
    name: 'Bhutan',
  },
  bv: {
    id: 36,
    code: 'BV',
    name: 'Bouvet Island',
  },
  bw: {
    id: 37,
    code: 'BW',
    name: 'Botswana',
  },
  by: {
    id: 38,
    code: 'BY',
    name: 'Belarus',
  },
  bz: {
    id: 39,
    code: 'BZ',
    name: 'Belize',
  },
  ca: {
    id: 40,
    code: 'CA',
    name: 'Canada',
  },
  cc: {
    id: 41,
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  cd: {
    id: 42,
    code: 'CD',
    name: 'DR Congo',
  },
  cf: {
    id: 43,
    code: 'CF',
    name: 'Central African Republic',
  },
  cg: {
    id: 44,
    code: 'CG',
    name: 'Congo',
  },
  ch: {
    id: 45,
    code: 'CH',
    name: 'Switzerland',
  },
  ci: {
    id: 46,
    code: 'CI',
    name: "Cote d'Ivoire",
  },
  ck: {
    id: 47,
    code: 'CK',
    name: 'Cook Islands',
  },
  cl: {
    id: 48,
    code: 'CL',
    name: 'Chile',
  },
  cm: {
    id: 49,
    code: 'CM',
    name: 'Cameroon',
  },
  cn: {
    id: 50,
    code: 'CN',
    name: 'China',
  },
  co: {
    id: 51,
    code: 'CO',
    name: 'Colombia',
  },
  cr: {
    id: 52,
    code: 'CR',
    name: 'Costa Rica',
  },
  cu: {
    id: 53,
    code: 'CU',
    name: 'Cuba',
  },
  cv: {
    id: 54,
    code: 'CV',
    name: 'Cape Verde',
  },
  cx: {
    id: 55,
    code: 'CX',
    name: 'Christmas Island',
  },
  cy: {
    id: 56,
    code: 'CY',
    name: 'Cyprus',
    is_eu: true,
  },
  cz: {
    id: 57,
    code: 'CZ',
    name: 'Czech Republic',
    is_eu: true,
  },
  de: {
    id: 58,
    code: 'DE',
    name: 'Germany',
    currency: 'EUR',
    is_eu: true,
  },
  dj: {
    id: 59,
    code: 'DJ',
    name: 'Djibouti',
  },
  dk: {
    id: 60,
    code: 'DK',
    name: 'Denmark',
    is_eu: true,
  },
  dm: {
    id: 61,
    code: 'DM',
    name: 'Dominica',
  },
  do: {
    id: 62,
    code: 'DO',
    name: 'Dominican Republic',
  },
  dz: {
    id: 63,
    code: 'DZ',
    name: 'Algeria',
  },
  ec: {
    id: 64,
    code: 'EC',
    name: 'Ecuador',
  },
  ee: {
    id: 65,
    code: 'EE',
    name: 'Estonia',
    is_eu: true,
  },
  eg: {
    id: 66,
    code: 'EG',
    name: 'Egypt',
  },
  eh: {
    id: 67,
    code: 'EH',
    name: 'Western Sahara',
  },
  er: {
    id: 68,
    code: 'ER',
    name: 'Eritrea',
  },
  es: {
    id: 69,
    code: 'ES',
    name: 'Spain',
    is_eu: true,
  },
  et: {
    id: 70,
    code: 'ET',
    name: 'Ethiopia',
  },
  eu: {
    id: 71,
    code: 'EU',
    name: 'Europe',
  },
  fi: {
    id: 72,
    code: 'FI',
    name: 'Finland',
    is_eu: true,
  },
  fj: {
    id: 73,
    code: 'FJ',
    name: 'Fiji',
  },
  fk: {
    id: 74,
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  fm: {
    id: 75,
    code: 'FM',
    name: 'Micronesia, Federated States of',
  },
  fo: {
    id: 76,
    code: 'FO',
    name: 'Faroe Islands',
  },
  fr: {
    id: 77,
    code: 'FR',
    name: 'France',
    currency: 'EUR',
    is_eu: true,
  },
  ga: {
    id: 78,
    code: 'GA',
    name: 'Gabon',
  },
  gb: {
    id: 79,
    code: 'GB',
    name: 'United Kingdom',
    currency: 'GBP',
    phone_code: 44,
    is_eu: true,
  },
  gd: {
    id: 80,
    code: 'GD',
    name: 'Grenada',
  },
  ge: {
    id: 81,
    code: 'GE',
    name: 'Georgia',
  },
  gf: {
    id: 82,
    code: 'GF',
    name: 'French Guiana',
  },
  gg: {
    id: 83,
    code: 'GG',
    name: 'Guernsey',
  },
  gh: {
    id: 84,
    code: 'GH',
    name: 'Ghana',
  },
  gi: {
    id: 85,
    code: 'GI',
    name: 'Gibraltar',
  },
  gl: {
    id: 86,
    code: 'GL',
    name: 'Greenland',
  },
  gm: {
    id: 87,
    code: 'GM',
    name: 'Gambia',
  },
  gn: {
    id: 88,
    code: 'GN',
    name: 'Guinea',
  },
  gp: {
    id: 89,
    code: 'GP',
    name: 'Guadeloupe',
  },
  gq: {
    id: 90,
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  gr: {
    id: 91,
    code: 'GR',
    name: 'Greece',
    is_eu: true,
  },
  gs: {
    id: 92,
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  gt: {
    id: 93,
    code: 'GT',
    name: 'Guatemala',
  },
  gu: {
    id: 94,
    code: 'GU',
    name: 'Guam',
  },
  gw: {
    id: 95,
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  gy: {
    id: 96,
    code: 'GY',
    name: 'Guyana',
  },
  hk: {
    id: 97,
    code: 'HK',
    name: 'Hong Kong',
  },
  hm: {
    id: 98,
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  hn: {
    id: 99,
    code: 'HN',
    name: 'Honduras',
  },
  hr: {
    id: 100,
    code: 'HR',
    name: 'Croatia',
    is_eu: true,
  },
  ht: {
    id: 101,
    code: 'HT',
    name: 'Haiti',
  },
  hu: {
    id: 102,
    code: 'HU',
    name: 'Hungary',
    is_eu: true,
  },
  id: {
    id: 103,
    code: 'ID',
    name: 'Indonesia',
  },
  ie: {
    id: 104,
    code: 'IE',
    name: 'Ireland',
    is_eu: true,
    currency: 'EUR',
  },
  il: {
    id: 105,
    code: 'IL',
    name: 'Israel',
  },
  im: {
    id: 106,
    code: 'IM',
    name: 'Isle of Man',
  },
  in: {
    id: 107,
    code: 'IN',
    name: 'India',
    currency: 'INR',
  },
  io: {
    id: 108,
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  iq: {
    id: 109,
    code: 'IQ',
    name: 'Iraq',
  },
  ir: {
    id: 110,
    code: 'IR',
    name: 'Iran',
  },
  is: {
    id: 111,
    code: 'IS',
    name: 'Iceland',
  },
  it: {
    id: 112,
    code: 'IT',
    name: 'Italy',
    is_eu: true,
  },
  je: {
    id: 113,
    code: 'JE',
    name: 'Jersey',
  },
  jm: {
    id: 114,
    code: 'JM',
    name: 'Jamaica',
  },
  jo: {
    id: 115,
    code: 'JO',
    name: 'Jordan',
  },
  jp: {
    id: 116,
    code: 'JP',
    name: 'Japan',
    currency: 'USD',
  },
  ke: {
    id: 117,
    code: 'KE',
    name: 'Kenya',
  },
  kg: {
    id: 118,
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  kh: {
    id: 119,
    code: 'KH',
    name: 'Cambodia',
  },
  ki: {
    id: 120,
    code: 'KI',
    name: 'Kiribati',
  },
  km: {
    id: 121,
    code: 'KM',
    name: 'Comoros',
  },
  kn: {
    id: 122,
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  kp: {
    id: 123,
    code: 'KP',
    name: 'North Korea',
  },
  kr: {
    id: 124,
    code: 'KR',
    name: 'South Korea',
  },
  kw: {
    id: 125,
    code: 'KW',
    name: 'Kuwait',
  },
  ky: {
    id: 126,
    code: 'KY',
    name: 'Cayman Islands',
  },
  kz: {
    id: 127,
    code: 'KZ',
    name: 'Kazakhstan',
  },
  la: {
    id: 128,
    code: 'LA',
    name: 'Laos',
  },
  lb: {
    id: 129,
    code: 'LB',
    name: 'Lebanon',
  },
  lc: {
    id: 130,
    code: 'LC',
    name: 'Saint Lucia',
  },
  li: {
    id: 131,
    code: 'LI',
    name: 'Liechtenstein',
  },
  lk: {
    id: 132,
    code: 'LK',
    name: 'Sri Lanka',
  },
  lr: {
    id: 133,
    code: 'LR',
    name: 'Liberia',
  },
  ls: {
    id: 134,
    code: 'LS',
    name: 'Lesotho',
  },
  lt: {
    id: 135,
    code: 'LT',
    name: 'Lithuania',
    is_eu: true,
  },
  lu: {
    id: 136,
    code: 'LU',
    name: 'Luxembourg',
    is_eu: true,
  },
  lv: {
    id: 137,
    code: 'LV',
    name: 'Latvia',
    is_eu: true,
  },
  ly: {
    id: 138,
    code: 'LY',
    name: 'Libya',
  },
  ma: {
    id: 139,
    code: 'MA',
    name: 'Morocco',
  },
  mc: {
    id: 140,
    code: 'MC',
    name: 'Monaco',
  },
  md: {
    id: 141,
    code: 'MD',
    name: 'Moldova',
  },
  me: {
    id: 142,
    code: 'ME',
    name: 'Montenegro',
  },
  mg: {
    id: 143,
    code: 'MG',
    name: 'Madagascar',
  },
  mh: {
    id: 144,
    code: 'MH',
    name: 'Marshall Islands',
  },
  mk: {
    id: 145,
    code: 'MK',
    name: 'Macedonia',
  },
  ml: {
    id: 146,
    code: 'ML',
    name: 'Mali',
  },
  mm: {
    id: 147,
    code: 'MM',
    name: 'Myanmar',
  },
  mn: {
    id: 148,
    code: 'MN',
    name: 'Mongolia',
  },
  mo: {
    id: 149,
    code: 'MO',
    name: 'Macao',
  },
  mp: {
    id: 150,
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  mq: {
    id: 151,
    code: 'MQ',
    name: 'Martinique',
  },
  mr: {
    id: 152,
    code: 'MR',
    name: 'Mauritania',
  },
  ms: {
    id: 153,
    code: 'MS',
    name: 'Montserrat',
  },
  mt: {
    id: 154,
    code: 'MT',
    name: 'Malta',
    is_eu: true,
  },
  mu: {
    id: 155,
    code: 'MU',
    name: 'Mauritius',
  },
  mv: {
    id: 156,
    code: 'MV',
    name: 'Maldives',
  },
  mw: {
    id: 157,
    code: 'MW',
    name: 'Malawi',
  },
  mx: {
    id: 158,
    code: 'MX',
    name: 'Mexico',
  },
  my: {
    id: 159,
    code: 'MY',
    name: 'Malaysia',
    currency: 'MYR',
    phone_code: 60,
  },
  mz: {
    id: 160,
    code: 'MZ',
    name: 'Mozambique',
  },
  na: {
    id: 161,
    code: 'NA',
    name: 'Namibia',
  },
  nc: {
    id: 162,
    code: 'NC',
    name: 'New Caledonia',
  },
  ne: {
    id: 163,
    code: 'NE',
    name: 'Niger',
  },
  nf: {
    id: 164,
    code: 'NF',
    name: 'Norfolk Island',
  },
  ng: {
    id: 165,
    code: 'NG',
    name: 'Nigeria',
  },
  ni: {
    id: 166,
    code: 'NI',
    name: 'Nicaragua',
  },
  nl: {
    id: 167,
    code: 'NL',
    name: 'Netherlands',
    is_eu: true,
  },
  no: {
    id: 168,
    code: 'NO',
    name: 'Norway',
  },
  np: {
    id: 169,
    code: 'NP',
    name: 'Nepal',
  },
  nr: {
    id: 170,
    code: 'NR',
    name: 'Nauru',
  },
  nu: {
    id: 171,
    code: 'NU',
    name: 'Niue',
  },
  nz: {
    id: 172,
    code: 'NZ',
    name: 'New Zealand',
  },
  om: {
    id: 173,
    code: 'OM',
    name: 'Oman',
  },
  pa: {
    id: 174,
    code: 'PA',
    name: 'Panama',
  },
  pe: {
    id: 175,
    code: 'PE',
    name: 'Peru',
  },
  pf: {
    id: 176,
    code: 'PF',
    name: 'French Polynesia',
  },
  pg: {
    id: 177,
    code: 'PG',
    name: 'Papua New Guinea',
  },
  ph: {
    id: 178,
    code: 'PH',
    name: 'Philippines',
  },
  pk: {
    id: 179,
    code: 'PK',
    name: 'Pakistan',
  },
  pl: {
    id: 180,
    code: 'PL',
    name: 'Poland',
    is_eu: true,
  },
  pm: {
    id: 181,
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  pn: {
    id: 182,
    code: 'PN',
    name: 'Pitcairn',
  },
  pr: {
    id: 183,
    code: 'PR',
    name: 'Puerto Rico',
  },
  ps: {
    id: 184,
    code: 'PS',
    name: 'Palestinian Territory',
  },
  pt: {
    id: 185,
    code: 'PT',
    name: 'Portugal',
    is_eu: true,
  },
  pw: {
    id: 186,
    code: 'PW',
    name: 'Palau',
  },
  py: {
    id: 187,
    code: 'PY',
    name: 'Paraguay',
  },
  qa: {
    id: 188,
    code: 'QA',
    name: 'Qatar',
  },
  re: {
    id: 189,
    code: 'RE',
    name: 'Reunion',
  },
  ro: {
    id: 190,
    code: 'RO',
    name: 'Romania',
    is_eu: true,
  },
  rs: {
    id: 191,
    code: 'RS',
    name: 'Serbia',
  },
  ru: {
    id: 192,
    code: 'RU',
    name: 'Russia',
  },
  rw: {
    id: 193,
    code: 'RW',
    name: 'Rwanda',
  },
  sa: {
    id: 194,
    code: 'SA',
    name: 'Saudi Arabia',
  },
  sb: {
    id: 195,
    code: 'SB',
    name: 'Solomon Islands',
  },
  sc: {
    id: 196,
    code: 'SC',
    name: 'Seychelles',
  },
  sd: {
    id: 197,
    code: 'SD',
    name: 'Sudan',
  },
  se: {
    id: 198,
    code: 'SE',
    name: 'Sweden',
    is_eu: true,
  },
  sg: {
    id: 199,
    code: 'SG',
    name: 'Singapore',
  },
  sh: {
    id: 200,
    code: 'SH',
    name: 'Saint Helena',
  },
  si: {
    id: 201,
    code: 'SI',
    name: 'Slovenia',
    is_eu: true,
  },
  sj: {
    id: 202,
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  sk: {
    id: 203,
    code: 'SK',
    name: 'Slovakia',
    is_eu: true,
  },
  sl: {
    id: 204,
    code: 'SL',
    name: 'Sierra Leone',
  },
  sm: {
    id: 205,
    code: 'SM',
    name: 'San Marino',
  },
  sn: {
    id: 206,
    code: 'SN',
    name: 'Senegal',
  },
  so: {
    id: 207,
    code: 'SO',
    name: 'Somalia',
  },
  sr: {
    id: 208,
    code: 'SR',
    name: 'Suriname',
  },
  st: {
    id: 209,
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  sv: {
    id: 210,
    code: 'SV',
    name: 'El Salvador',
  },
  sy: {
    id: 211,
    code: 'SY',
    name: 'Syria',
  },
  sz: {
    id: 212,
    code: 'SZ',
    name: 'Swaziland',
  },
  tc: {
    id: 213,
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  td: {
    id: 214,
    code: 'TD',
    name: 'Chad',
  },
  tf: {
    id: 215,
    code: 'TF',
    name: 'French Southern Territories',
  },
  tg: {
    id: 216,
    code: 'TG',
    name: 'Togo',
  },
  th: {
    id: 217,
    code: 'TH',
    name: 'Thailand',
  },
  tj: {
    id: 218,
    code: 'TJ',
    name: 'Tajikistan',
  },
  tk: {
    id: 219,
    code: 'TK',
    name: 'Tokelau',
  },
  tl: {
    id: 220,
    code: 'TL',
    name: 'Timor-Leste',
  },
  tm: {
    id: 221,
    code: 'TM',
    name: 'Turkmenistan',
  },
  tn: {
    id: 222,
    code: 'TN',
    name: 'Tunisia',
  },
  to: {
    id: 223,
    code: 'TO',
    name: 'Tonga',
  },
  tr: {
    id: 224,
    code: 'TR',
    name: 'Turkey',
    currency: 'TRY',
  },
  tt: {
    id: 225,
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  tv: {
    id: 226,
    code: 'TV',
    name: 'Tuvalu',
  },
  tw: {
    id: 227,
    code: 'TW',
    name: 'Taiwan',
  },
  tz: {
    id: 228,
    code: 'TZ',
    name: 'Tanzania',
  },
  ua: {
    id: 229,
    code: 'UA',
    name: 'Ukraine',
  },
  ug: {
    id: 230,
    code: 'UG',
    name: 'Uganda',
  },
  um: {
    id: 231,
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  us: {
    id: 232,
    code: 'US',
    name: 'United States',
    special: true,
    currency: 'USD',
  },
  uy: {
    id: 233,
    code: 'UY',
    name: 'Uruguay',
  },
  uz: {
    id: 234,
    code: 'UZ',
    name: 'Uzbekistan',
  },
  va: {
    id: 235,
    code: 'VA',
    name: 'Holy See (Vatican City State)',
  },
  vc: {
    id: 236,
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  ve: {
    id: 237,
    code: 'VE',
    name: 'Venezuela',
  },
  vg: {
    id: 238,
    code: 'VG',
    name: 'Virgin Islands, British',
  },
  vi: {
    id: 239,
    code: 'VI',
    name: 'Virgin Islands, U.S.',
  },
  vn: {
    id: 240,
    code: 'VN',
    name: 'Vietnam',
  },
  vu: {
    id: 241,
    code: 'VU',
    name: 'Vanuatu',
  },
  wf: {
    id: 242,
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  ws: {
    id: 243,
    code: 'WS',
    name: 'Samoa',
  },
  ye: {
    id: 244,
    code: 'YE',
    name: 'Yemen',
  },
  yt: {
    id: 245,
    code: 'YT',
    name: 'Mayotte',
  },
  za: {
    id: 246,
    code: 'ZA',
    name: 'South Africa',
  },
  zm: {
    id: 247,
    code: 'ZM',
    name: 'Zambia',
  },
  zw: {
    id: 248,
    code: 'ZW',
    name: 'Zimbabwe',
  },
  ss: {
    id: 249,
    code: 'SS',
    name: 'South Sudan',
  },
  mf: {
    id: 250,
    code: 'MF',
    name: 'Saint Martin',
  },
  sx: {
    id: 251,
    code: 'SX',
    name: 'Sint Maarten',
  },
  bq: {
    id: 252,
    code: 'BQ',
    name: 'Bonaire, Saint Eustatius and Saba',
  },
  bl: {
    id: 253,
    code: 'BL',
    name: 'Saint Bartelemey',
  },
  cw: {
    id: 254,
    code: 'CW',
    name: 'Curacao',
  },
  o1: {
    id: 255,
    code: 'O1',
    name: 'Other Country',
  },
  a3: {
    id: 256,
    code: 'A3',
    name: 'Demo Country',
  },
};

export const isValidCountryCode = (countryCode: string) => {
  const countryCodes = Object.keys(countries).map(countryKey =>
    countries[countryKey].code.toLowerCase(),
  );
  return countryCodes.includes(countryCode?.toLowerCase());
};

export const countryList = sortArrayOfObjectsByProperty(
  Object.values(Object.values(countries)),
  'name',
).filter(
  ctry => !doesStringContainDigits(ctry.code), // Filter out country codes with numbers to get only real countries
);

export const mubiGoUSCities = [
  'go.cities.atlanta',
  'go.cities.boston',
  'go.cities.chicago',
  'go.cities.dallas',
  'go.cities.denver',
  'go.cities.los_angeles',
  'go.cities.new_york_city',
  'go.cities.portland',
  'go.cities.san_diego',
  'go.cities.san_francisco',
  'go.cities.seattle',
  'go.cities.washington_dc',
];

export const mubiGoDECities = [
  'go.cities.berlin',
  'go.cities.frankfurt',
  'go.cities.hamburg',
  'go.cities.munich',
  'go.cities.cologne',
];

export const latamCountryCodes = [
  'ar',
  'bo',
  'cl',
  'co',
  'cr',
  'cu',
  'do',
  'ec',
  'gt',
  'hn',
  'mx',
  'ni',
  'pa',
  'pe',
  'pr',
  'py',
  'sv',
  'uy',
  've',
];
