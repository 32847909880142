/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { AnyAction } from 'redux';
import produce from 'immer';

import { Following } from '@app/api/resources/Following';

import * as actionTypes from '@app/actionTypes';

export type FollowingsState = {
  followings: { [key: number]: Following };
  followeeIdToFollowingIdLookup: { [key: number]: number };
};

export const initialState: FollowingsState = {
  followings: {},
  // where the followingId is null, it means the user is not following the followee
  followeeIdToFollowingIdLookup: {},
};

export const followings = (state = initialState, action: AnyAction) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.ADD_FOLLOWINGS: {
        const { followings: usersFollowings } = action.payload;
        // eslint-disable-next-line array-callback-return
        usersFollowings.map(following => {
          const {
            id,
            followee: { id: followeeId },
          } = following;

          draft.followings[id] = following;
          draft.followeeIdToFollowingIdLookup[followeeId] = id;
        });
        break;
      }
      case actionTypes.ADD_USERS_NOT_FOLLOWED: {
        const { notFollowedUsersIds }: { notFollowedUsersIds: number[] } =
          action.payload;
        notFollowedUsersIds.map(
          // eslint-disable-next-line no-return-assign
          notFollowedUserId =>
            (draft.followeeIdToFollowingIdLookup[notFollowedUserId] = null),
        );
        break;
      }
      case actionTypes.ADD_FOLLOWING: {
        const {
          id,
          followee: { id: followeeId },
        } = action.payload.following;

        draft.followings[id] = action.payload.following;
        draft.followeeIdToFollowingIdLookup[followeeId] = id;
        break;
      }
      case actionTypes.REMOVE_FOLLOWING: {
        const { followingId } = action.payload;
        const followeeId = draft.followings[followingId].followee.id;

        draft.followeeIdToFollowingIdLookup[followeeId] = null;
        delete draft.followings[followingId];
        break;
      }
    }
  });
