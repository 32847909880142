import { IllowCookieConsent } from '@app/api/services/illow';

export const areMarketingCookiesAllowed = (): boolean => {
  if (window.illow && window.illow.getConsent) {
    const illowConsent = window.illow.getConsent() as IllowCookieConsent;
    return illowConsent?.marketing?.value;
  }
  return false;
};

export const areAnalyticsCookiesAllowed = (): boolean => {
  if (window.illow && window.illow.getConsent) {
    const illowConsent = window.illow.getConsent() as IllowCookieConsent;
    return illowConsent?.statistics?.value;
  }
  return false;
};
