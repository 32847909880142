import { AnyAction } from 'redux';
import produce from 'immer';
import { Card } from '@braze/web-sdk';

import { UserStateBanner } from '@app/api/resources/User';

import * as actionTypes from '@app/actionTypes';

import { BrazeBannerDetail } from '@app/components/banners/BrazeBanner';

const initialState = {
  brazeBanner: {
    detail: null,
    isPreview: false,
    card: null,
    updatedAt: null,
  },
  userStateBanners: null,
  userFeedbackBanner: { message: null, isError: null },
};

type BrazeBannerState = {
  detail: BrazeBannerDetail;
  isPreview: boolean;
  card: Card;
  updatedAt: string;
};

export type BannersState = {
  brazeBanner: BrazeBannerState;
  userStateBanners: UserStateBanner[];
  userFeedbackBanner: {
    message: string;
    isError?: boolean;
    id?: number;
  };
};

export const banners = (
  state: BannersState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_BRAZE_BANNER: {
        const { brazeBannerDetails, isPreview, card } = action.payload;
        const dateTimeNow = new Date().toString();

        draft.brazeBanner.detail = brazeBannerDetails;
        draft.brazeBanner.isPreview = isPreview;
        draft.brazeBanner.card = card;
        draft.brazeBanner.updatedAt = dateTimeNow;
        break;
      }
      case actionTypes.CLEAR_BRAZE_BANNER: {
        draft.brazeBanner.detail = null;
        draft.brazeBanner.isPreview = false;
        draft.brazeBanner.card = null;
        draft.brazeBanner.updatedAt = null;
        break;
      }
      case actionTypes.SET_USER_STATE_BANNERS:
        draft.userStateBanners = action.payload.userStateBanners;
        break;
      case actionTypes.SET_USER_FEEDBACK_BANNER:
        draft.userFeedbackBanner.message =
          action.payload.userFeedbackBanner.message;
        draft.userFeedbackBanner.isError =
          action.payload.userFeedbackBanner.isError;
        draft.userFeedbackBanner.id = action.payload.userFeedbackBanner.id;
        break;
    }
  });
