import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { NextPageContext } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import withRedux from 'next-redux-wrapper';
import appWithI18n from 'next-translate/appWithI18n';
import { ThemeProvider } from '@emotion/react';
import * as Sentry from '@sentry/nextjs';

import SpeedTestRunner from '@app/speedtest';

import { initialiseHttpService } from '@app/api/http-service';
import { CurrentUser } from '@app/api/resources/User';

import { sentryIgnoreErrorsFromUnsupportedBrowsers } from '@app/services/sentry';
import { isGoogleLighthouseUserAgent, isProduction } from '@app/services/utils';

import { CustomContext } from '@app/types/common';
import theme from '@app/themes/mubi-theme';
import i18nConfig from '@app/i18n';

import { initializeStore } from '@app/store';
import { RootState } from '@app/reducers';

import { PreviousPagePathProvider } from '@app/contexts/PreviousPagePathContext';
import InitialiseFacebookSDKContainer from '@app/initialisation/InitialiseFacebookSDKContainer';
import InitialiseIllowBannerLanguage from '@app/initialisation/InitialiseIllowBannerLanguage';
import InitialiseLocalBrowserIllowCookieConsentState from '@app/initialisation/InitialiseLocalBrowserIllowCookieConsentState';
import InitialiseLocalStorage from '@app/initialisation/InitialiseLocalStorage';
import InitialiseSnowplowContainer from '@app/initialisation/InitialiseSnowplowContainer';
import InitialiseSwapLocaleForUsersLanguageSetting from '@app/initialisation/InitialiseSwapLocaleForUsersLanguageSetting';
import InitialiseTagManagerContainer from '@app/initialisation/InitialiseTagManagerContainer';
import InitialiseUserOnPageLoad from '@app/initialisation/InitialiseUserOnPageLoad';
import OverrideCountryNoticeContainer from '@app/initialisation/OverrideCountryNoticeContainer';
import SentrySetContext from '@app/initialisation/SentrySetContext';
import SetClarityUserId from '@app/initialisation/SetClarityUserId';

import BrazeInit from '@app/components/braze/BrazeInit';
import ErrorGeneric from '@app/components/errors/ErrorGeneric';
import MonitorIllowCookieConsentChanges from '@app/components/illow/MonitorIllowCookieConsentChanges';
import TrackIllowCookieBannerViews from '@app/components/illow/TrackIllowCookieBannerViews';
import OnboardingContainer from '@app/components/onboarding/OnboardingContainer';
import OverrideCountryNotice from '@app/components/OverrideCountryNotice';
import OnUserFirstInteractionContainer from '@app/components/visibility-containers/OnUserFirstInteractionContainer';

import '@splidejs/react-splide/css/core';
import '@app/onboarding/components/inputs/input-global.css';
import '@app/onboarding/components/inputs/IntlTelephoneInput.css';

if (process.env.ENABLE_MOCK_SERVICE_WORKER) {
  // eslint-disable-next-line global-require
  require('@app/api/msw');
}

const AgreeToMarketingMailModalContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "AgreeToMarketingMailModalContainer" */
      '@app/initialisation/AgreeToMarketingMailModalContainer'
    ),
  { ssr: false },
);

const RatingModalContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "RatingModalContainer" */
      '@app/components/film-actions/RatingModalContainer'
    ),
  { ssr: false },
);

const FindUsersModalContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "FindUsersModalContainer" */
      '@app/components/find-users/FindUsersModal'
    ),
  { ssr: false },
);

const ManageListModalContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "ManageListModalContainer" */
      '@app/components/film-actions/ManageListModalContainer'
    ),
  { ssr: false },
);

const BrazeBannerContainer = dynamic(
  () =>
    import(
      /* webpackChunkName: "BrazeBannerContainer" */
      '@app/components/braze/BrazeBannerContainer'
    ),
  { ssr: false },
);

const base_url = process.env.apiBaseUrl;

initialiseHttpService({
  base_url,
  client_name: 'web',
  debug: !isProduction,
});

type MyAppProps = AppProps & {
  userAgent: string;
  store: Store<RootState>;
  user: CurrentUser;
  err: NextPageContext['err'];
  errorStatusCode: 404 | 500;
  errorMessage: string;
  isRedirecting?: boolean;
};

const MyApp = ({
  Component,
  pageProps,
  router,
  userAgent,
  store,
  user,
  err,
  errorStatusCode,
  errorMessage,
  isRedirecting = false,
}: MyAppProps) => {
  useEffect(() => {
    if (window.Cypress) {
      window.store = store;
    }
  }, [store]);

  if (isRedirecting) {
    return null;
  }

  const currentState = store.getState();
  const { geoLocation } = currentState.user;

  if (errorStatusCode || errorMessage) {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ErrorGeneric
            statusCode={errorStatusCode}
            errorMessage={errorMessage}
          />
        </Provider>
      </ThemeProvider>
    );
  }

  const onErrorHandle = (error, errorInfo) => {
    Sentry.captureException(error, { extra: { errorInfo } });
  };

  const isPlayerPage = router.pathname === '/films/[filmSlug]/player';

  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ErrorBoundary
            FallbackComponent={() => <ErrorGeneric statusCode={500} />}
            onError={onErrorHandle}
          >
            <>
              {!isGoogleLighthouseUserAgent(userAgent) && (
                <>
                  <InitialiseFacebookSDKContainer />
                  {!isPlayerPage && <InitialiseTagManagerContainer />}
                  <InitialiseSnowplowContainer />
                </>
              )}
              <SetClarityUserId />
              <SentrySetContext />
              <BrazeBannerContainer />
              <InitialiseUserOnPageLoad />
              <InitialiseLocalStorage />
              <InitialiseIllowBannerLanguage />
              <InitialiseLocalBrowserIllowCookieConsentState />
              <MonitorIllowCookieConsentChanges />
              <TrackIllowCookieBannerViews />
              <InitialiseSwapLocaleForUsersLanguageSetting />
              <OnUserFirstInteractionContainer />
              <BrazeInit />
              <OverrideCountryNoticeContainer>
                {({ overrideCountry, isAdmin }) => {
                  if (isAdmin) {
                    return (
                      <OverrideCountryNotice
                        countryCode={overrideCountry}
                        currentPath={router.asPath}
                      />
                    );
                  }
                  return null;
                }}
              </OverrideCountryNoticeContainer>
              <OnboardingContainer />
              <FindUsersModalContainer />
              <ManageListModalContainer />
              <RatingModalContainer />
              {geoLocation === 'TR' && <AgreeToMarketingMailModalContainer />}
              {isProduction && (
                <SpeedTestRunner
                  userId={user?.id}
                  onError={(error, extra = {}) => {
                    if (error?.status === 403) {
                      // Ignore when the infra api returns a forbidden status code
                      return;
                    }
                    Sentry.captureException(error, {
                      extra: {
                        ...extra,
                        status: error?.status,
                        response: error?.data,
                        message: error?.message,
                        error,
                      },
                    });
                  }}
                />
              )}
            </>

            <PreviousPagePathProvider>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...pageProps} err={err} />
            </PreviousPagePathProvider>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </>
  );
};

type GetInitialProps = AppContext & {
  ctx: CustomContext;
};

MyApp.getInitialProps = async (appContext: GetInitialProps) => {
  const { ctx } = appContext;
  const { err, req, store } = ctx;
  const initProps = {
    userAgent: '',
    user: null,
    err,
    isRedirecting: false,
  };

  const isServer = !!req;

  if (isServer) {
    const { appInitialization } = await import(
      '@app/services/page-initialisation/app'
    );
    const returnedProps = await appInitialization(ctx, initProps);
    if (returnedProps) {
      return returnedProps;
    }
  } else {
    const currentState = store.getState();
    sentryIgnoreErrorsFromUnsupportedBrowsers(currentState);
  }

  let pageProps;
  if (App.getInitialProps) {
    try {
      pageProps = await App.getInitialProps(appContext);
    } catch (error) {
      Sentry.captureException(error);
      return { errorStatusCode: 500 };
    }
  }

  const errorStatusCode = pageProps?.pageProps?.errorStatusCode;
  const errorMessage = pageProps?.pageProps?.errorMessage;

  if (errorStatusCode && ctx.res) {
    ctx.res.statusCode = errorStatusCode;
  }

  return {
    ...pageProps,
    errorMessage,
    errorStatusCode,
    ...initProps,
  };
};

let release = '';

if (
  process.env.MUBI_ENV !== 'development' &&
  process.env.MUBI_ENV !== 'test' &&
  process.env.BUILD_ID
) {
  release = `${process.env.BUILD_ID}/`;
}

export default appWithI18n(withRedux(initializeStore)(MyApp) as any, {
  ...i18nConfig,
  loadLocaleFrom: (lang, ns) =>
    import(`../public/static/${release}locales/${lang}/${ns}.json`).then(
      m => m.default,
    ),
});
